import React, { useEffect, useState } from 'react'
import './Home.css'
import { Icon } from '@iconify/react';
import MeetingCard from '../../Components/Dashboard/MeetingCard/MeetingCard';
import { useMeetingStore } from './../../Store/MeetingStore/MeetingStore';
import { useNavigate } from 'react-router-dom';
import { Path } from '../../Routes/Path';
import ScheduleMeeting from '../../Components/ScheduleMeeting/ScheduleMeeting';
import { useUserStore } from '../../Store/AccountStore/UserStore/UserStore';
import Upcoming from '../../Components/Meeting/Upcoming';
import Previous from '../../Components/Meeting/Previous';
import JoinMeeting from '../../Components/Meeting/JoinMeeting';

const Home = () => {
    const { createMeeting, setMeetingCode, } = useMeetingStore()
    const { subUsers, fetchSubUsers, createSubUsers, setSubUsers } = useUserStore()
    const [s_choice, setS_choice] = useState('U')
    const navigate = useNavigate();
    const handleInstantMeeting = () => {
        createMeeting(null)
            .then(res => {
                setMeetingCode(res.data)
                window.open(Path.MEETING + '/' + res.data, '_blank');
            })
            .catch(err => {
                console.log(err);
            })
    }

    const fetchData = () => {
        fetchSubUsers()
            .then(res => {
                setSubUsers(res.data)

            })
            .catch(err => {
                console.log(err.response.data);
            })
    }
    useEffect(() => {
        fetchData()
    }, [])



    return (
        <>
            <div className="home_wrapper">
                {/* <div className="dashboard_button_wrapper  myrow align_center">
                    <div className="dashboard_button_block dropdown">
                        <button style={{ padding: 0, border: 0, backgroundColor: 'transparent' }} id='new' data-bs-toggle="dropdown" aria-expanded="false"><Icon icon={'ph:video-camera-fill'} className='dashboardIconButton' /></button>
                        <label htmlFor='new' style={{ cursor: 'pointer' }} className='dashboardIconButtonTitle color_dark ps-3'>New</label>
                        <ul className="dropdown-menu dropdown_menu">
                            <li className='d-flex align_center' onClick={handleInstantMeeting}><Icon icon={'simple-line-icons:plus'} /> &nbsp; Start an instant meeting </li>
                            <li className='d-flex align_center'><Icon icon={'mi:link'} /> &nbsp; Schedule a meeting for later </li>
                            <li className='d-flex align_center'><Icon icon={'solar:calendar-linear'} /> &nbsp; Schedule in calendar </li>
                        </ul>
                    </div>

                    <div className="dashboard_button_block dropdown">
                        <button data-bs-toggle="modal" data-bs-target="#joinMeeting" style={{ padding: 0, border: 0, backgroundColor: 'transparent' }}><Icon icon={'ph:plus-fill'} className='dashboardIconButton' /></button>
                        <label data-bs-toggle="modal" data-bs-target="#joinMeeting" style={{ cursor: 'pointer' }} className='dashboardIconButtonTitle color_dark ps-3'>Join</label>

                    </div>

                    <div className="dashboard_button_block">
                        <div data-bs-toggle="modal" data-bs-target="#scheduleMeetingModal"><Icon icon={'solar:calendar-bold'} className='dashboardIconButton' /></div>
                        <div data-bs-toggle="modal" data-bs-target="#scheduleMeetingModal" type={'button'} className='dashboardIconButtonTitle color_dark ps-3'>Schedule</div>
                    </div>

                </div> */}

                <div className='pb-3 pt-5 myrow align_center' style={{ justifyContent: 'flex-end' }}>
                    <div>
                        <button className="my_button bg_blue color_white me-2" data-bs-toggle="dropdown" aria-expanded="false"><Icon icon={'majesticons:video'} fontSize={18} />&nbsp; Meeting</button>
                        <ul className="dropdown-menu dropdown_menu">
                            <li className='d-flex align_center' onClick={handleInstantMeeting}><Icon fontSize={18} icon={'mingcute:add-circle-line'} /> &nbsp; Start an instant meeting </li>
                            <li className='d-flex align_center' data-bs-toggle="modal" data-bs-target="#joinMeeting" ><Icon icon={'mi:link'} fontSize={18} /> &nbsp; Join Now </li>

                        </ul>
                    </div>
                    {/* <button className="my_button bg_blue color_white me-2">Join</button> */}
                    <button className="my_button bg_blue color_white me-2" data-bs-toggle="modal" data-bs-target="#scheduleMeetingModal"><Icon icon={'solar:calendar-bold'} fontSize={16} />&nbsp; Schedule</button>
                </div>

                <div className="dashboard_meetings_title mt-3">
                    <div className="d-flex">
                        <div className={s_choice === 'U' ? 'home_active_tab' : 'home_inactive_tab'} onClick={() => setS_choice('U')}>Upcoming</div>
                        <div className={s_choice === 'P' ? 'home_active_tab' : 'home_inactive_tab'} onClick={() => setS_choice('P')}>Previous</div>
                    </div>
                </div>
                <hr style={{ margin: 0 }} />

                <div className="meeting_card_wrapper myrow mt-5">
                    {s_choice === 'U' ? <Upcoming subUsers={subUsers} /> : <Previous subUsers={subUsers} />}
                </div>
            </div>

            {/* ////////////////////Schedule Modal////////////////////////// */}

            <div className="modal fade" id="scheduleMeetingModal" tabIndex="-1" aria-labelledby="scheduleMeetingModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content schedule_modal">
                        <div className="schedule_modal_header myrow justify_space_between">
                            <span className="color_blue">Schedule meeting</span>
                            <span className="color_blue" type="button" data-bs-dismiss="modal"><Icon icon={"ep:close-bold"} fontSize={25} /></span>
                        </div>
                        <ScheduleMeeting bg={false} />
                    </div>
                </div>
            </div>

            {/* //////////////////////Join Meeting ///////////////////////// */}
            <div className="modal fade" id="joinMeeting" tabIndex="-1" aria-labelledby="joinMeetingLabel" aria-hidden="true">
                <JoinMeeting />
            </div>

        </>
    )
}

export default Home