import React from 'react'
import './Footer.css'
import { Icon } from '@iconify/react'
import logo from '../../Images/logo.png'
import { Link } from 'react-router-dom'
import moment from 'moment'

const Footer = () => {
  return (
    <>
      {/* <div className='bg_navy'>
        <div className="footer">
          <div className=' myrow justify_space_between'>
            <div className="footer_b1">
              <img src={logo} width={'150px'} className='' alt="" />
              <div className="footer_content mt-3">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Neque, fugit laudantium.
              </div>
            </div>
            <div className="footer_b2">
              <div className="footer_title color_blue">
                Products
              </div>
              <div className='mt-2'>
                <Link to={'/'} className='nav_link'>Officie Meet</Link>
              </div>
              <div className='mt-2'>
                <Link to={'/'} className='nav_link'>Pricing</Link>
              </div>
              <div className='mt-2'>
                <Link to={'/'} className='nav_link'>Solution</Link>
              </div>

            </div>
            <div className="footer_b2">
              <div className="footer_title color_blue">
                Company
              </div>
              <div className='mt-2'>
                <Link to={'/'} className='nav_link'>About</Link>
              </div>
              <div className='mt-2'>
                <Link to={'/'} className='nav_link'>Contact</Link>
              </div>
              <div className='mt-2'>
                <Link to={'/'} className='nav_link'>Careers</Link>
              </div>
              <div className='mt-2'>
                <Link to={'/'} className='nav_link'>Blog</Link>
              </div>

            </div>
            <div className="footer_b2">
              <div className="footer_title color_blue">
                Support
              </div>
              <div className='mt-2'>
                <Link to={'/'} className='nav_link'>Contact us</Link>
              </div>
              <div className='mt-2'>
                <Link to={'/'} className='nav_link'>Support Policy</Link>
              </div>
              <div className='mt-2'>
                <Link to={'/'} className='nav_link'>Talk to Sales</Link>
              </div>

            </div>
            <div className="footer_b1">

              <div className="footer_title color_blue">
                Subscribe to our newsletter
              </div>
              <div className="footer_content mt-2">
                Want to stay up to date with news and updates about our product? <br /> Subscribe.
              </div>

              <div className="footer_input_wrapper mt-3">
                <input type="text" className='footer_input' placeholder='email@provider.com' />
                <Icon icon={'cil:arrow-right'} type='button' fontSize={28} style={{ padding: '5' }} />
              </div>

              <div className="footer_content mt-3" style={{ fontSize: '13px', lineHeight: '20px' }}>
                By subscribing to our newsletter you agree to our privacy policy and will get commercial communication.
              </div>

            </div>
          </div>

          <hr style={{ borderColor: '#CFCFD1' }} />

          <div className="footer_bottom py-2 justify_space_between">

            <div className='myrow mt-2'>
              <div className="footer_content mt-2 me-5">
                © {moment().format('YYYY')} Officiel Meet
              </div>

              <div className='mt-2'>
                <Link to={'/'} className='nav_link'>Privacy Policy</Link>
              </div>
              <div className='mt-2'>
                <Link to={'/'} className='nav_link'>Terms of Service</Link>
              </div>
              <div className='mt-2'>
                <Link to={'/'} className='nav_link'>Cookie Settings</Link>
              </div>

            </div>

            <div className='myrow mt-2'>
              <div className="footer_input_wrapper me-3">
                <div className="footer_content px-3" style={{fontSize:'13px'}}>English(US)</div>
                <Icon icon={'iconamoon:arrow-down-2-thin'} className='me-2' fontSize={20} />
              </div>

              <div className='mt-2'>
                <Link to={'/'} className='nav_link'><Icon icon={'eva:facebook-fill'} fontSize={18} /></Link>
              </div>
              <div className='mt-2'>
                <Link to={'/'} className='nav_link'><Icon icon={'eva:twitter-fill'} fontSize={18} /></Link>
              </div>
              <div className='mt-2'>
                <Link to={'/'} className='nav_link'><Icon icon={'eva:linkedin-fill'} fontSize={18} /></Link>
              </div>

            </div>

          </div>


        </div>
      </div> */}

      <div className="bg_navy color_white text-center p-4">
        Copyright © {moment().format('YYYY')} Officiel Meet
      </div>

    </>
  )
}

export default Footer