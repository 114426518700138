import React from 'react'
import './Header.css'
import logo from '../../Images/logo.png'
import { Icon } from '@iconify/react'
import { Path } from '../../Routes/Path'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <>
      <div className="header bg_dark">
        <div className="myrow justify_space_between align_center">
          <div>
            {/* <Icon icon='mingcute:menu-line' className='color_white mobile_view me-2' fontSize={23} /> */}
            <img src={logo} className='logo' alt="" />
          </div>

          {/* <div className='nav_item'>
            <div className="nav_link_active">Home</div>
            <div className="nav_link">About</div>
            <div className="nav_link">Pricing</div>
            <div className="nav_link">Career</div>
            <div className="nav_link">Blog</div>
          </div> */}

          <div className='myrow align_center'>
            <Link to={Path.LOGIN}> <div className="nav_link me-3">Login</div></Link>
            <div>
              <Link to={Path.REGISTER}><button className="my_button bg_blue color_white">Sign up</button></Link>
            </div>
          </div>

          {/* <div className='color_white mobile_view'> <button className='my_button color_blue' style={{ backgroundColor: '#fff' }}><Icon icon="mingcute:add-fill" />&nbsp; Join Meeting</button></div> */}

        </div>
      </div>
    </>
  )
}

export default Header