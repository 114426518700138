import React, { useEffect } from 'react'
import { baseUrl } from '../../../Export'
import logo from '../../../Images/logo2.png'

const MeetingEnd = (props) => {
    const {state} = props
    useEffect(() => {
      if(state){
        state.close()
      }
    }, [])
    
    return (
        <div className="forgot_password_wrapper bg_blue">
            <div className="forgot_password_box">
                <div className='landing_logo'><img src={logo} className='logo' alt="" /></div>
                <h5 className='mt-2 landing_logo'>Meeting End</h5>
                <p className="login_label">
                    Meeting ended by host for all users. Thank you for joining this meeting.
                </p>
                <div className="mt-2">
                    <button onClick={() => window.location = baseUrl} className="my_button bg_blue color_white w-100">
                        Go to home
                    </button>
                </div>

            </div>
        </div>
    )
}

export default MeetingEnd