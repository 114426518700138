import React from 'react'
import './NoNetwork.css';
import logo from '../../Images/logo2.png'

const NoNetwork = () => {
    return (
        <>

            <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }}>
                <div className="otp_block text-center">
                    <h2 className='login_title text-center'><img src={logo} className='logo' alt="" /></h2>
                    <p className='login_label'>We are encountering network error. please click on retry to connect again.</p>

                    <button onClick={()=>document.location.reload()} className="my_button mt-3 bg_blue color_white w-100">
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                        Retry
                    </button>
                </div>
            </div>
        </>
    )
}

export default NoNetwork