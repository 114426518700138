import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../../Export';

export const useUserStore = create((set) => ({
    subUsers: [], currentUser: null, license: null,
    fetchLicense: async () => {
        let token = await JSON.parse(localStorage.getItem('officiel-user'))
        const response = await axios.get(url + 'auth/fetch-license/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        set({license:response.data})
    },
    fetchSubUsers: async () => {
        let token = await JSON.parse(localStorage.getItem('officiel-user'))
        const response = await axios.get(url + 'auth/fetch-sub-users/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    createSubUsers: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('officiel-user'))
        const response = await axios.post(url + 'auth/create-sub-user/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    updateSubUsers: async (payload, id) => {
        let token = await JSON.parse(localStorage.getItem('officiel-user'))
        const response = await axios.put(url + 'auth/update-sub-user/' + id + '/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    deleteSubUsers: async (id) => {
        let token = await JSON.parse(localStorage.getItem('officiel-user'))
        const response = await axios.delete(url + 'auth/delete-sub-user/' + id + '/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    setSubUsers: async (payload) => {
        set({ subUsers: payload });
    },
    setCurrentUser: async (payload) => {
        set({ currentUser: payload });
    },

}));