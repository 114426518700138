import React from 'react'
import SmallScreenList from './SmallScreenList'
import MainScreen from './MainScreen'

const SmallScreen = (props) => {
    const { participant, remoteAudio, remoteStreams, remoteFeeds, localStream, username, audioEnabled, videoEnabled,localAudioStream } = props
    if (participant.length === 1) {
        return (
            <>
                <div className="tilesWrapper scroll align_center">
                    <div className="tilesWrapperCardAuto" style={{ height: '100%' }}>
                        <SmallScreenList d={participant[0]} remoteStreams={remoteStreams} remoteAudio={remoteAudio} remoteFeeds={remoteFeeds} />
                    </div>
                    <div className="tilesWrapperCardAuto" style={{ height: '100%' }}>
                        <MainScreen localAudioStream={localAudioStream} small={true} localStream={localStream} username={username} audioEnabled={audioEnabled} videoEnabled={videoEnabled} />
                    </div>
                </div>
            </>
        )
    } else if (participant.length > 1 && participant.length < 4) {
        return (
            <>
                <div className="tilesWrapper scroll align_center">
                    {participant.map((d, key) => {
                        return <div className="tilesWrapperCardAuto" style={{ height: '49%' }} key={d.id}>
                            <SmallScreenList d={d} count={key} remoteStreams={remoteStreams} remoteAudio={remoteAudio} remoteFeeds={remoteFeeds} />
                        </div>
                    })}
                    <div className="tilesWrapperCardAuto" style={{ height: '49%' }}>
                        <MainScreen localAudioStream={localAudioStream} small={true} localStream={localStream} username={username} audioEnabled={audioEnabled} videoEnabled={videoEnabled} />
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="tilesWrapper scroll align_center">
                    {participant.map((d, key) => {
                        return <div className="tilesWrapperCard3" key={d.id}>
                            <SmallScreenList d={d} count={key} remoteStreams={remoteStreams} remoteAudio={remoteAudio} remoteFeeds={remoteFeeds} />
                        </div>
                    })}
                    <div className="tilesWrapperCard3">
                        <MainScreen localAudioStream={localAudioStream} small={true} localStream={localStream} username={username} audioEnabled={audioEnabled} videoEnabled={videoEnabled} />
                    </div>
                </div>
            </>
        )
    }
}

export default SmallScreen