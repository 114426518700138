import React, { useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { useUserStore } from '../../Store/AccountStore/UserStore/UserStore';
import { useToasts } from 'react-toast-notifications';
import { useMeetingStore } from '../../Store/MeetingStore/MeetingStore';
import Loading from './../../Loading';

const ScheduleMeeting = (props) => {
    const { addToast } = useToasts()
    let token = JSON.parse(localStorage.getItem('officiel-user'))
    const { bg } = props
    const { subUsers } = useUserStore()
    const { scheduleMeeting, fetchScheduleMeeting, setScheduleMeetings } = useMeetingStore()
    const [participantOptions, setParticipantOptions] = useState([])
    const [hostOption, setHostOption] = useState([])
    const [usePassowrd, setUsePassowrd] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        let l = []
        for (let i = 0; i < subUsers.length; i++) {
            const e = subUsers[i];
            l.push({
                'value': e.email, 'label': e.email
            })
        }
        setParticipantOptions(l)
    }, [subUsers])

    useEffect(() => {
        let l = []
        for (let i = 0; i < subUsers.length; i++) {
            const e = subUsers[i];
            l.push({
                'value': e.id, 'label': e.email
            })
        }
        setHostOption(l)
    }, [subUsers])

    const [payload, setPayload] = useState({
        agenda: '', password: '', host: { value: Number(token.id), label: token.email }, desc: '', schedule_time: '', duration: '30', recurring: false, snooze: false, screen_sharing: true, participants: []
    })

    const handleSchedule = () => {
        if (payload.agenda.length > 0 && payload.desc.length > 0 && payload.schedule_time.length > 0 && payload.duration.length > 0 && payload.host) {
            setIsLoading(true)
            scheduleMeeting(payload)
                .then(res => {
                    !bg && document.getElementById("scheduleDismiss").click()
                    addToast('Success !! Meeting scheduled.', {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                    setPayload({
                        ...payload, agenda: '', password: '', host: { value: token.id, label: token.email }, desc: '', schedule_time: '', duration: '30', participants: []
                    })
                    fetchScheduleMeeting()
                        .then(res => {
                            setIsLoading(false)
                            setScheduleMeetings(res.data)
                        })
                        .catch(err => {
                            setIsLoading(false)
                            console.log(err);
                        })
                })
                .catch(err => {
                    setIsLoading(false)
                    console.log(err);
                    addToast('Error !! Something went wrong...', {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                })
        } else {
            addToast('Error !! All fields required...', {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    }

    useEffect(() => {
        if (!usePassowrd) {
            setPayload({ ...payload, password: '' })
        }
    }, [usePassowrd])



    return (
        <>
            <div className={bg ? "schedule_modal_body bg_secondary" : "schedule_modal_body"}>

                <div className="modal_input_wrapper">
                    <div className="modal_input_left_block">
                        Meeting Agenda <span className="text-danger">*</span>
                    </div>
                    <div className="modal_input_right_block">
                        <input value={payload.agenda} onChange={(e) => setPayload({ ...payload, agenda: e.target.value })} type="text" className='schedule_modal_input' />
                    </div>
                </div>
                <div className="modal_input_wrapper ">
                    <div className="modal_input_left_block">
                    </div>
                    <div className='text-start myrow align_center' style={{ flexGrow: 0.77 }}>
                        <input value={usePassowrd} onChange={(e) => setUsePassowrd(e.target.checked)} className="" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                        <label className="login_label " type={'button'} htmlFor="flexSwitchCheckDefault">&nbsp; Use Password</label>
                    </div>
                </div>
                {usePassowrd &&
                    <div className="modal_input_wrapper">
                        <div className="modal_input_left_block">
                            Password
                        </div>
                        <div className="modal_input_right_block">
                            <input type="text" value={payload.password} onChange={(e) => setPayload({ ...payload, password: e.target.value })} className='schedule_modal_input' />
                        </div>
                    </div>
                }
                <div className="modal_input_wrapper ">
                    <div className="modal_input_left_block">
                        Host <span className="text-danger">*</span>
                    </div>
                    <div className="modal_input_right_block">
                        <Select className='react_select_input' value={payload.host} onChange={(e) => setPayload({ ...payload, host: e })} options={hostOption} />
                    </div>
                </div>
                <div className="modal_input_wrapper ">
                    <div className="modal_input_left_block">
                        Add Participant
                    </div>
                    <div className="modal_input_right_block">
                        <CreatableSelect value={payload.participants} closeMenuOnSelect={false} onChange={(e) => setPayload({ ...payload, participants: e })} className='react_select_input' isMulti options={participantOptions} />
                    </div>
                </div>
                <div className="modal_input_wrapper ">
                    <div className="modal_input_left_block">
                        Description <span className="text-danger">*</span>
                    </div>
                    <div className="modal_input_right_block">
                        <textarea type="text" value={payload.desc} onChange={(e) => setPayload({ ...payload, desc: e.target.value })} rows={3} cols={22} maxLength="200" className='schedule_modal_input'></textarea>
                    </div>
                </div>

                <hr />

                <div className="modal_input_wrapper ">
                    <div className="modal_input_left_block">
                        Date & Time <span className="text-danger">*</span>
                    </div>
                    <div className="modal_input_right_block">
                        <input type="datetime-local" value={payload.schedule_time} onChange={(e) => setPayload({ ...payload, schedule_time: e.target.value })} className='schedule_modal_input' />
                    </div>
                </div>
                <div className="modal_input_wrapper ">
                    <div className="modal_input_left_block">
                        Duration <span className="text-danger">*</span>
                    </div>
                    <div className="modal_input_right_block">
                        <select className='schedule_modal_input' value={payload.duration} onChange={(e) => setPayload({ ...payload, duration: e.target.value })}>
                            <option value="15">15 minutes</option>
                            <option value="30">30 minutes</option>
                            <option value="45">45 minutes</option>
                            <option value="60">60 minutes</option>
                        </select>
                    </div>
                </div>

                <div className="modal_input_wrapper ">
                    <div className="modal_input_left_block">
                    </div>
                    <div className='text-start myrow align_center' style={{ flexGrow: 0.70 }}>
                        <div className="myrow align_center me-3">
                            <input checked={payload.screen_sharing} onChange={(e) => setPayload({ ...payload, screen_sharing: e.target.checked })} className="" type="checkbox" role="switch" id="flexSwitchCheckDefaultRecurring" />
                            <label className="login_label " type={'button'} htmlFor="flexSwitchCheckDefaultRecurring">&nbsp;Enable Screen Sharing</label>
                        </div>
                        <div className="myrow align_center me-3">
                            <input checked={payload.recurring} onChange={(e) => setPayload({ ...payload, recurring: e.target.checked })} className="" type="checkbox" role="switch" id="flexSwitchCheckDefaultRecurring" />
                            <label className="login_label " type={'button'} htmlFor="flexSwitchCheckDefaultRecurring">&nbsp;Recurring meeting</label>
                        </div>
                        <div className="myrow align_center">
                            <input checked={payload.snooze} onChange={(e) => setPayload({ ...payload, snooze: e.target.checked })} className="" type="checkbox" role="switch" id="flexSwitchCheckDefaultSnooze" />
                            <label className="login_label " type={'button'} htmlFor="flexSwitchCheckDefaultSnooze">&nbsp;Snooze</label>
                        </div>
                    </div>
                </div>

                <div className="modal_input_wrapper " style={{ justifyContent: 'end' }}>
                    {!bg && <button className="my_button bg_dark color_white mx-1" id='scheduleDismiss' data-bs-dismiss="modal">Cancel</button>}
                    <button className="my_button bg_blue color_white mx-1" onClick={handleSchedule}>Save</button>
                </div>

            </div>

            {isLoading && <Loading />}
        </>
    )
}

export default ScheduleMeeting