import React, { useEffect } from 'react'
import { useRegisterStore } from '../../Store/AccountStore/RegisterStore/RegisterStore'
import { useToasts } from 'react-toast-notifications';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import { Path } from './../../Routes/Path';
import logo from '../../Images/logo2.png'

const RegisterOtp = (props) => {
    const navigate = useNavigate();
    const { addToast } = useToasts()
    const { payload, setPayload, isLoading, setIsLoading } = props
    const { sendEmailOtp, sendPhoneOtp, createUsers } = useRegisterStore()

    useEffect(() => {
        emailOtpResend()
    }, [])


    const emailOtpResend = () => {
        setIsLoading(true)
        sendEmailOtp(payload.email)
            .then(res => {
                setIsLoading(false)
                addToast('OTP successfully sent to your email...!', {
                    appearance: 'success',
                    autoDismiss: true,
                })
            })
            .catch(err => {
                setIsLoading(false)
                addToast('Oops ! Something went wrong.', {
                    appearance: 'error',
                    autoDismiss: true,
                })
            })
    }

    const handleCreateAccount = () => {
        setIsLoading(true)
        createUsers(payload)
            .then(res => {
                setIsLoading(false)
                addToast('Success !! login to continue.', {
                    appearance: 'success',
                    autoDismiss: true,
                })
                navigate(Path.LOGIN);
            })
            .catch(err => {
                setIsLoading(false)
                err.response.status === 400 ?
                    addToast(err.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                    // Swal.fire('Error', err.response.data, 'error')
                    :
                    addToast('Oops ! Something went wrong.', {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                // Swal.fire('Error', 'Oops! Something went wrong.', 'error')
            })
    }


    return (<>
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }}>
            <div className="otp_block text-center">
                <h2 className='login_title text-center'><img src={logo} className='logo' alt="" /></h2>
                <h5>Verify Your Email</h5>
                <p className='login_label'>We emailed you the otp code to {payload.email} <br />Enter the code below to confirm your email address</p>
                <input type="text" className='login_input' onChange={(e) => setPayload({ ...payload, email_otp: e.target.value })} placeholder='Enter otp sent to email...' />
                <button onClick={handleCreateAccount} className="my_button bg_blue color_white" style={{ opacity: payload.email_otp.length === 6 ? 1 : 0.4 }} disabled={payload.email_otp.length === 6 ? false : true}>
                    {isLoading ?
                        <>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                            Please wait...
                        </>
                        :
                        "Verify"}</button>
                <div className="login_label mt-2">
                    If you didn't receive a code !! <span type="button" className='color_blue' onClick={emailOtpResend}>RESEND</span>
                </div>
            </div>
        </div>
    </>)
}

export default RegisterOtp