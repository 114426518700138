import React from 'react'
import './Sidebar.css'
import { Icon } from '@iconify/react'
import { Avatar } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { Path } from './../../../Routes/Path';
import { useUserStore } from './../../../Store/AccountStore/UserStore/UserStore';
import { mediaUrl } from '../../../Export'

const Sidebar = () => {
    const {currentUser} = useUserStore()
    const location = useLocation();
    let l = location.pathname.split('/')[1]
    const handleLogout = () => {
        localStorage.removeItem("officiel-user")
        document.location.href = "/";
    }

    return (
        <div className='dashboard_fixed_sidebar'>
            <div className="dashboard_fixed_sidebar_block">
                <div className="dashboard_fixed_sidebar_card bg_secondary">
                    <div className="sidebar_link m-0 color_blue" style={{ cursor: 'default' }}>
                    <Avatar style={{ margin: '10px auto', backgroundColor: '#029AFF', fontSize: '20px' }} src={currentUser?mediaUrl+currentUser.proimg:''} sx={{ width: 35, height: 35 }}>{currentUser.name[0]}</Avatar>
                    </div>
                    <div>
                        <Link to={Path.HOME} >
                            <div className={location.pathname === Path.HOME ? 'sidebar_link_active' : 'sidebar_link'}>
                                <Icon icon={'solar:home-smile-outline'} fontSize={21} />
                            </div>
                        </Link>
                        <Link to={Path.USERS}>
                            <div className={location.pathname === Path.USERS || l === 'meeting' ? 'sidebar_link_active' : 'sidebar_link'}>
                                <Icon icon={'solar:user-linear'} fontSize={21} />
                            </div>
                        </Link>
                        <Link to={Path.SCHEDULE}>
                            <div className={location.pathname === Path.SCHEDULE ? 'sidebar_link_active' : 'sidebar_link'}>
                                <Icon icon={'solar:calendar-linear'} fontSize={21} />
                            </div>
                        </Link>
                        <Link to={Path.SETTINGS}>
                            <div className={location.pathname === Path.SETTINGS ? 'sidebar_link_active' : 'sidebar_link'}>
                                <Icon icon={'solar:settings-linear'} fontSize={22} />
                            </div>
                        </Link>
                    </div>
                    <div className='color_red sidebar_link m-0 ' onClick={handleLogout}>
                        <Icon icon={'prime:power-off'} color='#EB5757' fontSize={24} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar