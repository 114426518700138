import { Icon } from '@iconify/react'
import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { useRegisterStore } from '../../Store/AccountStore/RegisterStore/RegisterStore'
import { useUserStore } from '../../Store/AccountStore/UserStore/UserStore'

const UserList = (props) => {
    const { addToast } = useToasts()
    const { data, count, setIsLoading, isLoading, fetchData, currentUser } = props

    const [payload, setPayload] = useState({
        name: data.name, email: data.email, phone: data.contact ? data.contact : ''
    })
    const [error, setError] = useState({
        name: '', email: '', phone: ''
    })

    const { validateUsers } = useRegisterStore()
    const { subUsers, updateSubUsers, deleteSubUsers } = useUserStore()

    const handleValidation = () => {
        let formIsValid = true;
        let errors = { ...error }
        if (payload.email.length > 0) {
            if (!payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                formIsValid = false;
                errors.email = 'Please Enter valid Email ID...!'
            } else {
                errors.email = ''
            }
        } else {
            formIsValid = false;
            errors.email = "Email field can't be empty."
        }

        if (payload.name.length > 0) {
            errors.name = ''
        }
        else {
            formIsValid = false;
            errors.name = "Name field can't be empty."
        }

        if (payload.phone.length > 0) {
            errors.phone = ''
        }
        else {
            formIsValid = false;
            errors.phone = "Contact field can't be empty."
        }
        setError(errors)
        return formIsValid
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (handleValidation()) {
            let d = { name: payload.name, contact: payload.phone, email: payload.email }
            setIsLoading(true)
            updateSubUsers(d, data.id)
                .then(res => {
                    document.getElementById('userCreateModalDismiss' + data.id).click()
                    addToast('Success...!', {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                    fetchData()
                })
                .catch(err => {
                    setIsLoading(false)
                    err.response.status === 400 ?
                        addToast(err.response.data, {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                        :
                        addToast('Oops ! Something went wrong.', {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                })
        }

    }

    const handleDelete = () => {
        deleteSubUsers(data.id)
            .then(res => {
                document.getElementById('userDeleteModalDismiss' + data.id).click()
                addToast('Success...!', {
                    appearance: 'success',
                    autoDismiss: true,
                })
                fetchData()
            })
            .catch(err => {
                setIsLoading(false)
                err.response.status === 400 ?
                    addToast(err.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                    :
                    addToast('Oops ! Something went wrong.', {
                        appearance: 'error',
                        autoDismiss: true,
                    })
            })
    }
    return (
        <>
            <tr>
                <td scope="row">{count}</td>
                <td>{data.name}</td>
                <td>{data.email}</td>
                <td>{data.contact ? data.contact : '-'}</td>
                <td>{data.user_type === '0' ? 'ADMIN' : 'USER'}</td>
                {currentUser.user_type === "0" &&
                    <td>
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                                <span type="button" data-bs-toggle="modal" data-bs-target={"#createUserModal" + data.id} className="px-2 color_blue"><Icon icon="akar-icons:edit" fontSize={18} /></span>
                            </li>
                            <li className="list-inline-item">
                                <span type={'button'} data-bs-toggle="modal" data-bs-target={"#deleteUserModal" + data.id} className="px-2 color_red"><Icon icon="ep:delete" fontSize={17} /></span>
                            </li>
                        </ul>
                    </td>
                }
            </tr>

            {/* ////////////////////User Update Modal////////////////////////// */}

            <div className="modal fade" id={"createUserModal" + data.id} tabIndex="-1" aria-labelledby="createUserModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content schedule_modal">
                        <div className="schedule_modal_header myrow justify_space_between">
                            <span className="color_blue">Create User</span>
                            <span className="color_blue" type="button" data-bs-dismiss="modal"><Icon icon={"ep:close-bold"} fontSize={22} /></span>
                        </div>
                        <form autoComplete="off" onSubmit={handleSubmit} className="schedule_modal_body">

                            <div className="modal_input_wrapper align_center">
                                <div className="modal_input_left_block">
                                    Name <span className="text-danger">*</span>
                                </div>
                                <div className="modal_input_right_block">
                                    <input type="text" value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })} className='schedule_modal_input' />
                                    {error.name.length > 0 && <div className="text-danger pt-1 " style={{ fontSize: '12px' }}>{error.name}</div>}
                                </div>
                            </div>

                            <div className="modal_input_wrapper align_center">
                                <div className="modal_input_left_block">
                                    Email <span className="text-danger">*</span>
                                </div>
                                <div className="modal_input_right_block">
                                    <input type="text" value={payload.email} onChange={(e) => setPayload({ ...payload, email: e.target.value })} className='schedule_modal_input' />
                                    {error.email.length > 0 && <div className="text-danger pt-1 " style={{ fontSize: '12px' }}>{error.email}</div>}
                                </div>
                            </div>
                            <div className="modal_input_wrapper align_center">
                                <div className="modal_input_left_block">
                                    Phone Number <span className="text-danger">*</span>
                                </div>
                                <div className="modal_input_right_block">
                                    <input type="text" value={payload.phone} onChange={(e) => setPayload({ ...payload, phone: e.target.value })} className='schedule_modal_input' />
                                    {error.phone.length > 0 && <div className="text-danger pt-1 " style={{ fontSize: '12px' }}>{error.phone}</div>}
                                </div>
                            </div>

                            <div className="modal_input_wrapper align_center" style={{ justifyContent: 'end' }}>
                                <span className="my_button bg_dark color_white mx-1" id={'userCreateModalDismiss' + data.id} data-bs-dismiss="modal">Cancel</span>
                                <button className="my_button bg_blue color_white mx-1" onClick={handleSubmit}>{isLoading ?
                                    <>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                        Please wait...
                                    </> : " Update "}
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            {/* ////////////////////User Delete Modal////////////////////////// */}

            <div className="modal fade" id={"deleteUserModal" + data.id} tabIndex="-1" aria-labelledby="deleteUserModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content schedule_modal">
                        <div className="schedule_modal_header myrow justify_space_between">
                            <span className="color_blue">Are you sure?</span>
                            <span className="color_blue" type="button" data-bs-dismiss="modal"><Icon icon={"ep:close-bold"} fontSize={22} /></span>
                        </div>
                        <div className="schedule_modal_body">
                            <div className='pb-3' style={{ fontSize: '15px' }}>Do you really want to delete ( {data.name} ) user ? You would'nt be able to retrieve this.</div>

                            <div className="modal_input_wrapper align_center" style={{ justifyContent: 'end' }}>
                                <span className="my_button bg_dark color_white mx-1" id={'userDeleteModalDismiss' + data.id} data-bs-dismiss="modal">Cancel</span>
                                <button className="my_button bg_red color_white mx-1" onClick={handleDelete}>{isLoading ?
                                    <>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                        Please wait...
                                    </> : " Delete "}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserList