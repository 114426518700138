import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../../Export';

export const useLoginStore = create((set) => ({
    user: [],
    loginUser: async (payload) => {
        const response = await axios.post(url + 'auth/email-login/', payload);
        return response
    },
    googleLogin: async (payload) => {
        const response = await axios.post(url + 'auth/create-google-user/', payload);
        return response
    },
    sendForgotPasswordOTP : async (payload) =>{
        const response = await axios.post(url + 'auth/send-forgot-password-otp/', payload);
        return response
    },
    otpVerify : async (payload) =>{
        const response = await axios.post(url + 'auth/email-otp-verify/', payload);
        return response
    },
    changePassword : async (payload,id) =>{
        const response = await axios.put(url + 'auth/change-password/'+id+'/', payload);
        return response
    },
    updatePassword : async (payload) =>{
        let token = await JSON.parse(localStorage.getItem('officiel-user'))
        const response = await axios.put(url + 'auth/update-password/', payload,{
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    changeProfilePic : async (payload) =>{
        let token = await JSON.parse(localStorage.getItem('officiel-user'))
        const response = await axios.put(url + 'auth/change-profile-pic/', payload,{
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
}));