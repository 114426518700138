import { Icon } from '@iconify/react'
import { Avatar, AvatarGroup } from '@mui/material'
import moment from 'moment'
import React from 'react'
import logo from '../../../Images/logo2.png'

const MainHeader = (props) => {
    const { code, user,meetingDetails } = props
    const handleLinkCopy = () => navigator.clipboard.writeText(code)
    return (
        <>
            <div className="mainHeader myrow justify_space_between align_center">
                <span style={{fontFamily:"inter semibold"}}>
                <img src={logo} className='logo' alt="" />
                </span>
                <div className="mainHeaderBlock1">
                    <div className="meetingTitle color_dark px-3">
                        {meetingDetails.agenda}
                    </div>

                </div>

            </div>
        </>
    )
}

export default MainHeader

{/* <div className="mainHeaderBlock2 ">
                    <div className="myrow justify_space_between align_center">
                        <AvatarGroup max={4}>
                            <Avatar sx={{ width: 35, height: 35 }} alt="Remy Sharp" src="https://mui.com//static/images/avatar/1.jpg" />
                            <Avatar sx={{ width: 35, height: 35 }} alt="Travis Howard" src="https://mui.com//static/images/avatar/2.jpg" />
                            <Avatar sx={{ width: 35, height: 35 }} alt="Cindy Baker" src="https://mui.com//static/images/avatar/3.jpg" />
                            <Avatar sx={{ width: 35, height: 35 }} alt="Agnes Walker" src="https://mui.com//static/images/avatar/4.jpg" />
                            <Avatar sx={{ width: 35, height: 35 }} alt="Trevor Henderson" src="https://mui.com//static/images/avatar/5.jpg" />
                        </AvatarGroup>
                        <div className="meetingInfo color_dark pt-2 px-3 main_mobile_hide">
                            {moment().format('MMMM Do YYYY, h:mm A')}
                        </div>

                        <div>
                            <div className="linkWrapper" onClick={handleLinkCopy}>
                                <Icon icon={'iconamoon:link-light'} /> &nbsp; | &nbsp;{code}
                            </div>
                        </div>
                        <div className='main_mobile_hide'>
                            <div className="mainProfileWrapper d-flex align_center justify_space_between">
                                <div className="d-flex">
                                    <div className='me-2' style={{ marginTop: '2px' }}>
                                        <Avatar sx={{ width: 35, height: 35 }} alt="Remy Sharp" src="https://mui.com//static/images/avatar/1.jpg" />
                                    </div>
                                    <div>
                                        <div className="meetingTitle textWrap color_dark" style={{ fontSize: '13px' }}>
                                            {user.name}
                                        </div>
                                        <div className="meetingInfo textWrap color_secondary">
                                            {user.email}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <Icon icon={'mingcute:more-2-fill'} type='button' fontSize={16} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div> */}