import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import FallbackComponent from './../FallbackComponent';
import { Icon } from '@iconify/react';
import { Avatar } from '@mui/material';

const MainScreen = (props) => {
    const { localStream, audioEnabled, username, videoEnabled, small,localAudioStream } = props
    let user = JSON.parse(username)

    const [isSpeaking, setIsSpeaking] = useState(false);
    const [analyser, setAnalyser] = useState(null);

    useEffect(() => {
        const audioContext = new AudioContext();
        if (localAudioStream) {
            const trackAnalyser = audioContext.createAnalyser();
            const bufferLength = trackAnalyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);

            trackAnalyser.fftSize = 256;

            localAudioStream.onended = () => {
                setIsSpeaking(false);
                trackAnalyser.disconnect();
            };

            const source = audioContext.createMediaStreamSource(localAudioStream);
            source.connect(trackAnalyser);

            const interval = setInterval(() => {
                trackAnalyser.getByteFrequencyData(dataArray);
                const volume = dataArray.reduce((acc, val) => acc + val, 0) / bufferLength;
                setIsSpeaking(volume > 5); // Adjust the threshold as needed
            }, 100); // Check every 100ms

            setAnalyser(trackAnalyser);

            // Cleanup
            return () => {
                clearInterval(interval);
                trackAnalyser.disconnect();
                source.disconnect();
            };
        }

        // Cleanup audio context when component unmounts
        return () => {
            audioContext.close();
        };
    }, [localAudioStream]);

    return (
        <>
            <div className="mainFrame bg_navy" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative', boxShadow: isSpeaking && audioEnabled ? "0 0 0 3px #A4FBA6" : 'none', transition: '0.1s' }}>
                <div style={{ flexGrow: 1, alignContent: 'center' }}>
                    {!videoEnabled || !localStream ?
                        <Avatar style={{ margin: 'auto', backgroundColor: '#029AFF', fontSize: small ? '30px' : '50px' }} sx={{ width: small ? 60 : 120, height: small ? 60 : 120 }}>{user.name[0]}</Avatar>
                        :
                        <ReactPlayer url={localStream} playsinline={true}
                            playing={true} width={'100%'} height={"100%"}
                            muted={true} /> 
                    }

                </div>
                <div className="screenName text-center">You</div>
                <div className={audioEnabled && localAudioStream ? "screenIcon bg_blue" : "screenIcon bg_red"}>
                    {isSpeaking && audioEnabled && localAudioStream ?
                        <div className="boxAudioContainer">
                            <div className="boxAudio box1"></div>
                            <div className="boxAudio box2"></div>
                            <div className="boxAudio box3"></div>
                            <div className="boxAudio box4"></div>
                            <div className="boxAudio box5"></div>
                        </div>
                        :
                        <Icon icon={audioEnabled && localAudioStream ? 'ph:microphone' : "ph:microphone-slash"} fontSize={18} />
                    }
                </div>
            </div>
        </>
    )
}

export default MainScreen