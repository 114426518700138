import React, { useEffect, useState } from 'react'
import Picker from 'emoji-picker-react';
import { Icon } from '@iconify/react';
import ChatList from './ChatList';
import { useSocketStore } from '../../../Store/SocketStore/Socket'
import { MsgType } from '../../../Export';

const Chat = () => {
    const [emojiPicker, setEmojiPicker] = useState(false)
    const { state, connectSocket, oldMsg, newMsg } = useSocketStore()
    const [ms, setMs] = useState('')
    const [chats, setChats] = useState([])
    const handleSendChat = (e) => {
        if (state) {
            e.preventDefault()
            if (ms.length > 0) {
                let data = {
                    type: MsgType.NEWMSG, msg: ms
                }
                state.send(JSON.stringify(data))
                setMs('')
            }
        }

    }

    const onEmojiClick = (event, emojiObject) => {
        setMs(ms + event.emoji)
    };

    const onEnterPress = (e) => {
        if (e.which == 13 && e.shiftKey == false) {
            e.preventDefault()
           document.getElementById('sendBtn').click()

        }
    }

    useEffect(() => {
        if (newMsg) {
            setChats([newMsg, ...chats])
        }
    }, [newMsg])

    useEffect(() => {
        if (oldMsg.length > 0) {
            setChats(oldMsg)
        }
    }, [oldMsg])

    return (
        <>
            <div className='chatWrapper'>
                <div className="myrow justify_space_between align_center" style={{ borderTop: '2px solid #f8f8f8', borderBottom: '2px solid #f8f8f8' }}>
                    <div className="meetingTitle color_dark p-2 bg_white" style={{ fontSize: '14px' }}>
                        Chats
                    </div>
                    <button type="button" className="btn-close me-3" style={{fontSize:'14px'}} data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>

                <div className="bg_secondary rightSideCard scroll" >
                    {chats.length > 0 ?
                        chats.map((d, c) => {
                            return <ChatList data={d} key={c} />
                        })
                        : <div className='color_secondary' style={{ fontSize: '14px', padding: '10px' }}> No chats!!!</div>}
                </div>
            </div>

            {emojiPicker ? <>
                <div className="selected_file2">
                    <Picker theme='light' skinTonesDisabled={false} onEmojiClick={onEmojiClick} />
                </div>
            </> : null}
            <form onSubmit={handleSendChat} className='chatInputWrapper d-flex align_center'>
                <Icon icon="fluent:emoji-28-regular" color={emojiPicker ? "rgb(2, 154, 255)" : 'rgb(126,126,126)'} type='button' style={{ marginRight: '5px' }} fontSize={24} onClick={() => setEmojiPicker(!emojiPicker)} />
                {/* <input type="file" id='fileUploadChat' style={{ display: 'none' }} />
<label htmlFor="fileUploadChat"><Icon icon="ph:plus-square" color='rgb(126,126,126)' type='button' fontSize={22} /></label> */}

                <textarea type="text" value={ms} onChange={(e) => setMs(e.target.value)} onKeyDown={onEnterPress} className='new_chat_input color_dark bg_secondary' rows={1} placeholder='Say Something...' style={{ margin: "0px 10px", paddingLeft: '15px', flexGrow: 1 }}></textarea>

                {/* <Icon icon="logos:mailjet-icon" type='button' fontSize={22} /> */}
                <span type='button' id='sendBtn' onClick={handleSendChat}><Icon icon={'ion:paper-plane-outline'} className='mainBodyConrolsButtonActive m-0' /></span>

            </form>
        </>
    )
}

export default Chat