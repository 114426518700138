import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLoginStore } from '../../Store/AccountStore/LoginStore/LoginStore'
import { useToasts } from 'react-toast-notifications'
import { Icon } from '@iconify/react/dist/iconify.js'
import Loading from './../../Loading';
import { useUserStore } from './../../Store/AccountStore/UserStore/UserStore';
import { mediaUrl } from '../../Export'

const Settings = () => {
  let token = JSON.parse(localStorage.getItem('officiel-user'))
  const { currentUser, setCurrentUser } = useUserStore()
  const { addToast } = useToasts()
  const { updatePassword, changeProfilePic } = useLoginStore()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState({
    password: '', confirm_password: ''
  })
  const [error, setError] = useState({
    password: '', confirm_password: ''
  })

  const handleValidation = () => {
    let formIsValid = true;
    let errors = { ...error }
    if (data.password.length > 0) {
      if (!data.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,20}$/)) {
        formIsValid = false;
        errors.password = 'Password must contain at least one number and one uppercase and lowercase letter and one special character, and at least 6 or more characters'
      } else {
        errors.password = ''
      }
    }
    else {
      formIsValid = false;
      errors.password = "Password field can't be empty."
    }

    if (data.confirm_password.length > 0) {
      if (data.password !== data.confirm_password) {
        formIsValid = false;
        errors.confirm_password = 'Confirm Password does not matched.'
      } else {
        errors.confirm_password = ''
      }
    }
    else {
      formIsValid = false;
      errors.confirm_password = "Confirm Password field can't be empty."
    }
    setError(errors)
    return formIsValid
  }

  const handleSubmit = () => {
    if (handleValidation()) {
      setIsLoading(true)
      updatePassword(data)
        .then(res => {
          setIsLoading(false)
          setData({
            ...data,
            password: '', confirm_password: ''
          })
          addToast('Success !! Password changed.', {
            appearance: 'success',
            autoDismiss: true,
          })
        })
        .catch(err => {
          setIsLoading(false)
          addToast('Oops! Something went wrong.', {
            appearance: 'error',
            autoDismiss: true,
          })
        })
    }
  }

  // ********************************Change Profile Pic*********************************

  const [profilePic, setProfilePic] = useState()

  useEffect(() => {
    if (profilePic) {
      setIsLoading(true)
      let formData = new FormData()
      formData.append('proimg', profilePic)
      changeProfilePic(formData)
        .then(res => {
          setIsLoading(false)
          setCurrentUser(res.data)
          setProfilePic()
          addToast('Success !! Profile picture changed.', {
            appearance: 'success',
            autoDismiss: true,
          })
        })
        .catch(err => {
          setIsLoading(false)
          addToast('Oops! Something went wrong.', {
            appearance: 'error',
            autoDismiss: true,
          })
        })
    }

  }, [profilePic])

  return (<>
    <div className='' style={{ flexDirection: 'column', alignItems: 'center' }}>
      <div className="myrow justify_space_between w-100 py-5">
        <div className='dashboard_meetings_title color_dark '>Settings</div>
        <div>
          {token.auth_provider === 'email' && <button data-bs-toggle="modal" data-bs-target="#updatePasswordModal" className="my_button bg_blue color_white">Change Password</button>}
        </div>
      </div>

      <div className='text-center border p-5 shadow'>
        <Avatar style={{ margin: '10px auto', backgroundColor: '#029AFF', fontSize: '40px' }} src={mediaUrl+currentUser.proimg} sx={{ width: 100, height: 100 }}>{currentUser.name[0]}</Avatar>
        <div className="">
          <label type="button" htmlFor='profilePicInput' className="login_label color_blue">
            Change Profile image
          </label>
          <input type="file" id='profilePicInput' onChange={(e) => setProfilePic(e.target.files[0])} style={{ display: "none" }} />
        </div>
        <h5 className='landing_logo m-0'>{currentUser.name}</h5>
        <p className='login_label m-0'>{currentUser.email}</p>
        <p className='login_label m-0'>{currentUser.country}</p>
      </div>
    </div>

    {isLoading && <Loading />}
    {/* ////////////////////Schedule Modal////////////////////////// */}

    <div className="modal fade" id="updatePasswordModal" tabIndex="-1" aria-labelledby="updatePasswordModal" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content schedule_modal">
          <div className="schedule_modal_header myrow justify_space_between">
            <span className="color_blue">Create User</span>
            <span className="color_blue" type="button" data-bs-dismiss="modal"><Icon icon={"ep:close-bold"} fontSize={22} /></span>
          </div>
          <form autoComplete="off" onSubmit={handleSubmit} className="schedule_modal_body">
            <>
              <label htmlFor="" className="login_label text-start">New Password<span className="text-danger">*</span></label>
              <input type="password" value={data.password} onChange={(e) => setData({ ...data, password: e.target.value })} placeholder='Enter Password' className="login_input" />
              {error.password.length > 0 && <div className="text-danger text-start" style={{ fontSize: '12px' }}>{error.password}</div>}

              <label htmlFor="" className="login_label text-start">Confirm New Password<span className="text-danger">*</span></label>
              <input value={data.confirm_password} onChange={(e) => setData({ ...data, confirm_password: e.target.value })} type={"password"} placeholder='Enter password again' className="login_input" />
              {error.confirm_password.length > 0 && <div className="text-danger text-start" style={{ fontSize: '12px' }}>{error.confirm_password}</div>}

              <button onClick={handleSubmit} className="my_button bg_blue color_white w-100 mt-2 mb-2">
                {isLoading ?
                  <>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                    Please wait...
                  </>
                  :
                  "SUBMIT"}</button>
            </>
          </form>
        </div>
      </div>
    </div>

  </>)
}

export default Settings