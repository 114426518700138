import React, { useEffect, useRef, useState } from 'react';
import Chat from '../Chat/Chat';
import MainHeader from '../MainHeader/MainHeader'
import MainScreen from '../Screen/MainScreen'
import SmallScreen from '../Screen/SmallScreen'
import { useParams } from 'react-router-dom'
import { useSocketStore } from '../../../Store/SocketStore/Socket'
import { useToasts } from 'react-toast-notifications';
import toast, { Toaster } from 'react-hot-toast';
import Janus from 'janus-gateway';
import adapter from "webrtc-adapter"
import SmallScreenList from '../Screen/SmallScreenList';
import RemoteScreen from '../Screen/RemoteScreen.js/RemoteScreen';
import { MsgType, baseUrl, janusUrl } from '../../../Export';
import Controls from './Controls';
import './Meeting.css'
import Topbar from './../../../Components/Topbar/Topbar';
import { Icon } from '@iconify/react/dist/iconify.js';
import MeetingEnd from './MeetingEnd';

const Meeting = (props) => {
  const [first, setFirst] = useState(true)
  const { id, isOnline, setConnected, socketClosed, meetingDetails } = props
  const { addToast } = useToasts()
  const { unique_code } = useParams()
  const { state, connectSocket, newUserJoined, clearNewUserJoined, clearForcedRemoveUser, forcedRemoveUser, exist_participant, remove_participant, clearExistParticipant, clearVideoToggle,
    clearAudioToggle, videotoggle, audiotoggle, endMeeting, forcedMuteUser, clearForcedMuteUser } = useSocketStore()
  const roomId = Number(unique_code)
  let username = JSON.stringify({ name: id.name, token: id.token, email: id.email, type: 0 })
  let myusername = JSON.stringify({ name: id.name, token: id.token, email: id.email, type: 1 })
  const [myFeedId, setMyFeedId] = useState(null)

  const [meetingEndForAll, setMeetingEndForAll] = useState(false)

  useEffect(() => {
    if ((!state || state.readyState == WebSocket.CLOSED) && myFeedId && !first) {
      let newId = id
      delete id['proimg']
      connectSocket(unique_code, JSON.stringify(newId), myFeedId);
    }
    return () => {
      if (state) {
        state.close()
      }
    }
  }, [myFeedId, first])

  const [participant, setParticipant] = useState([])
  const [localStream, setLocalStream] = useState(null);
  const [localAudioStream, setLocalAudioStream] = useState(null);
  const [source, setSource] = useState([])
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [screenSharing, setScreenSharing] = useState(false);
  const [remoteStreams, setRemoteStreams] = useState([]);
  const [remoteAudio, setRemoteAudio] = useState([])
  const [remoteFeeds, setRemoteFeeds] = useState([])
  const [remoteScreens, setRemoteScreens] = useState([])
  const [audioPublished, setAudioPublished] = useState(false)
  const [videoPublished, setVideoPublished] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(true)

  useEffect(() => {
    if (newUserJoined) {
      if (newUserJoined.id === myFeedId) {
        if (state) {
          state.send(JSON.stringify({ feed: myFeedId, enabled: audioEnabled, type: MsgType.AUDIOTOGGLE }))
          state.send(JSON.stringify({ feed: myFeedId, enabled: videoEnabled, type: MsgType.VIDEOTOGGLE }))
        }
      }
      setParticipant(prevParticipant => {
        const fid = new Set(prevParticipant.map(stream => stream.id));
        if (!fid.has(newUserJoined.id) && newUserJoined.id !== myFeedId) {
          return [newUserJoined, ...prevParticipant];
        }
        return [...prevParticipant];
      })
      toast(newUserJoined.display.name + ' Joined.',
        {
          icon: '👏', position: "bottom-left",
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
    }

  }, [newUserJoined])

  useEffect(() => {
    if (exist_participant.length > 0) {
      setParticipant(prevParticipant => {
        let d = []
        const fid = new Set(prevParticipant.map(stream => stream.id));
        for (let i = 0; i < exist_participant.length; i++) {
          const e = exist_participant[i];
          if (!fid.has(e.id) && e.id !== myFeedId) {
            d.push(e)
          }
        }
        return [...d, ...prevParticipant];
      })
    }
  }, [exist_participant])

  useEffect(() => {
    if (remove_participant) {
      setParticipant(prev => {
        let d = prev.filter((f) => f.id !== remove_participant.id)
        return [...d]
      })
      toast(remove_participant.display.name + ' left.',
        {
          position: "bottom-left",
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      unsubscribeFrom(remove_participant.id)
    }
  }, [remove_participant])

  useEffect(() => {
    if (forcedRemoveUser) {
      if (Number(forcedRemoveUser.feed) === Number(myFeedId)) {
        window.location = baseUrl
      }
      clearForcedRemoveUser()
    }
  }, [forcedRemoveUser])

  useEffect(() => {
    if (forcedMuteUser) {
      if (Number(forcedMuteUser.feed) === Number(myFeedId)) {
        if (audioEnabled) {
          toggleAudio()
        } else {
          toast('Host requested you to unmute yourself.',
            {
              position: "top-right",
              style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
              },
            }
          );
        }
      }
      clearForcedMuteUser()
    }
  }, [forcedMuteUser])

  useEffect(() => {
    if (socketClosed) {
      setConnected(false)
    }

  }, [socketClosed])

  useEffect(() => {
    if (endMeeting) {
      toast('Meeting end for all.',
        {
          position: "top-right",
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }
      );
      setMeetingEndForAll(true)

    }
  }, [endMeeting])

  // *********************************************************************************************************
  // *********************************************************************************************************

  const [janus, setJanus] = useState(null);
  const [videoRoom, setVideoRoom] = useState(null);
  const [remoteRoom, setRemoteRoom] = useState(null)

  let myId = null; let published = false; let feedStreams = {}; var remoteFeed = null; var subStreams = {}, slots = {}, mids = {}, subscriptions = {}, feeds = {};
  let remoteTracks = {}
  Janus.init({
    debug: false,
    dependencies: Janus.useDefaultDependencies({ adapter: adapter }),
    callback: function () { },
  })

  useEffect(() => {
    const initializeJanus = async () => {
      const janusInstance = new Janus({
        server: janusUrl,
        iceServers: [{ url: 'stun:stun.l.google.com:19302' }, { url: 'turn:20.198.22.73:3478', username: 'username', credential: 'password' }],
        success: () => {
          setJanus(janusInstance);
        },
        error: (error) => {
          console.error('Error initializing Janus:', error);
        },
      });
    };
    initializeJanus();
    return () => {
      if (janus) {
        janus.destroy();
        document.location.reload()
      }
    };

  }, []);

  useEffect(() => {
    if (janus) {
      joinVideoRoom(roomId, username);
    }
  }, [janus])

  var vrom = null

  const joinVideoRoom = (roomName, username) => {
    janus.attach({
      plugin: 'janus.plugin.videoroom',
      success: function (pluginHandle) {
        vrom = pluginHandle
        setVideoRoom(pluginHandle);
        // Join the video room
        pluginHandle.send({
          message: {
            request: 'join',
            room: roomName,
            ptype: 'publisher',
            display: username,
          },
        });
      },
      error: function (cause) {
        console.log('Publisher Error: ', cause);
        // Couldn't attach to the plugin
      },
      onmessage: function (msg, jsep) {
        let event = msg["videoroom"];
        if (event != undefined && event != null) {
          if (msg.videoroom === 'joined') {
            myId = msg.id
            setMyFeedId(msg.id)
            if (!published) {
              vrom.createOffer(
                {
                  tracks: [
                    { type: 'audio', capture: true, recv: true },
                    { type: 'video', capture: true, recv: true },
                  ],
                  success: function (jsep) {
                    vrom.send({ message: { request: 'publish', audiocodec: 'opus', videocode: 'vp-8' }, jsep: jsep });
                    published = true
                  },
                  error: function (error) {
                    console.error('Error creating offer:', error);
                  },
                });
            }
            if (msg["publishers"]) {
              let list = msg["publishers"];
              let sources = null;
              for (let f in list) {
                let id = list[f]["id"];
                let display = list[f]["display"];
                let streams = list[f]["streams"];
                for (let i in streams) {
                  let stream = streams[i];
                  stream["id"] = id;
                  stream["display"] = display;
                }
                let slot = feedStreams[id] ? feedStreams[id].slot : null;
                let remoteVideos = feedStreams[id] ? feedStreams[id].remoteVideos : 0;
                feedStreams[id] = {
                  id: id,
                  display: display,
                  streams: streams,
                  slot: slot,
                  remoteVideos: remoteVideos
                }
                if (!sources)
                  sources = [];
                sources.push(streams);
              }
              if (sources)
                subscribeTo(sources)
            }
          }

          if (event === 'event') {
            if (msg["streams"]) {
              let streams = msg["streams"];
              for (let i in streams) {
                let stream = streams[i];
                stream["id"] = myId;
                stream["display"] = username;
              }
              feedStreams[myId] = {
                id: myId,
                display: username,
                streams: streams
              }
            }
            else if (msg["publishers"]) {
              let list = msg["publishers"];
              let sources = null;
              for (let f in list) {
                let id = list[f]["id"];
                let display = list[f]["display"];
                let streams = list[f]["streams"];
                for (let i in streams) {
                  let stream = streams[i];
                  stream["id"] = id;
                  stream["display"] = display;
                }
                let slot = feedStreams[id] ? feedStreams[id].slot : null;
                let remoteVideos = feedStreams[id] ? feedStreams[id].remoteVideos : 0;
                feedStreams[id] = {
                  id: id,
                  display: display,
                  streams: streams,
                  slot: slot,
                  remoteVideos: remoteVideos
                }
                if (!sources)
                  sources = [];
                sources.push(streams);
              }
              if (sources)
                subscribeTo(sources);

            } else if (msg["leaving"]) {
              // One of the publishers has gone away?
              let leaving = msg["leaving"];
              unsubscribeFrom(leaving);
            } else if (msg["unpublished"]) {
              // One of the publishers has unpublished?
              let unpublished = msg["unpublished"];
              if (unpublished === 'ok') {
                vrom.hangup();
                return;
              }
              unsubscribeFrom(unpublished);
            }
          }

          if (jsep) {
            // We have an OFFER from the plugin
            vrom.handleRemoteJsep({ jsep: jsep })
          }
        }

      },
      onlocaltrack: function (track, added) {
        if (added) {
          if (track.kind === 'audio') {
            let stream = new MediaStream([track]);
            setLocalAudioStream(stream)
          } else if (track.kind === 'video') {
            let stream = new MediaStream([track]);
            setLocalStream(stream)
          }
        } else {
          if (track.kind === 'audio') {
            setLocalAudioStream()
          } else if (track.kind === 'video') {
            setLocalStream()
          }
        }
      },
      mediaState: function (medium, on, mid) {
        // console.log(medium, on, mid);
        setBtnDisabled(false)
        console.log("Publisher Janus " + (on ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")");
      },
      iceState: function (state) {
        console.log("Publisher ICE state changed to " + state);
        if (state === 'disconnected') {
          vrom.send({ request: 'unpublish' })
          setConnected(false)
        }
      },
      webrtcState: function (on) {
        console.log("Publishers Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");

        // sfutest.send({ message: { request: "configure", bitrate: bitrate } });
        // return false;
      },
      slowLink: function (uplink, lost, mid) {
        console.log("Publishers Janus reports problems " + (uplink ? "sending" : "receiving") + " packets on mid " + mid + " (" + lost + " lost packets)");
      }
    })
  };

  const [creatingSubscription, setCreatingSubscription] = useState(false)

  function subscribeTo(sources) {
    // Check if we're still creating the subscription handle
    if (creatingSubscription) {
      // Still working on the handle, send this request later when it's ready
      setTimeout(function () {
        subscribeTo(sources);
      }, 1000);
      return;
    }
    // If we already have a working subscription handle, just update that one
    if (remoteFeed) {
      let added = null, removed = null;
      for (let s in sources) {
        let streams = sources[s];
        for (let i in streams) {
          let stream = streams[i];
          // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
          if (stream.disabled) {
            // Unsubscribe
            if (!removed)
              removed = [];
            removed.push({
              feed: stream.id,	// This is mandatory
              mid: stream.mid		// This is optional (all streams, if missing)
            });
            delete subscriptions[stream.id][stream.mid];
            continue;
          }
          if (subscriptions[stream.id] && subscriptions[stream.id][stream.mid]) {
            continue;
          }
          // Subscribe
          if (!added)
            added = [];
          added.push({
            feed: stream.id,	// This is mandatory
            mid: stream.mid		// This is optional (all streams, if missing)
          });
          if (!subscriptions[stream.id])
            subscriptions[stream.id] = {};
          subscriptions[stream.id][stream.mid] = true;
        }
      }

      let update = { request: 'update' };
      if (!added && !removed) {
        remoteFeed = null
        subStreams = {}
        slots = {}
        mids = {}
        subscriptions = {}
        feeds = {};
        subscribeTo(sources)
      }
      else if (added) {
        update.subscribe = added;
      }
      else if (removed) {
        update.unsubscribe = removed
      }
      remoteFeed.send({ message: update });
      // Nothing else we need to do
      return;
    }
    // If we got here, we're creating a new handle for the subscriptions (we only need one)

    setCreatingSubscription(true)
    janus.attach(
      {
        plugin: "janus.plugin.videoroom",
        success: function (pluginHandle) {
          remoteFeed = pluginHandle;
          remoteTracks = {};
          // Prepare the streams to subscribe to, as an array: we have the list of
          // streams the feed is publishing, so we can choose what to pick or skip
          let subscription = [];
          for (let s in sources) {
            let streams = sources[s];
            for (let i in streams) {
              let stream = streams[i];

              if (subscriptions[stream.id] && subscriptions[stream.id][stream.mid]) {
                console.log("Already subscribed to stream, skipping:", stream);
                continue;
              }
              // Find an empty slot in the UI for each new source
              if (!feedStreams[stream.id].slot) {
                let slot;
                for (let i = 1; i < 6; i++) {
                  if (!feeds[i]) {
                    slot = i;
                    feeds[slot] = stream.id;
                    feedStreams[stream.id].slot = slot;
                    feedStreams[stream.id].remoteVideos = 0;
                    break;
                  }
                }
              }
              subscription.push({
                feed: stream.id,	// This is mandatory
                mid: stream.mid		// This is optional (all streams, if missing)
              });
              if (!subscriptions[stream.id]) {
                subscriptions[stream.id] = {};
              }
              subscriptions[stream.id][stream.mid] = true;
            }
          }
          // We wait for the plugin to send us an offer
          let subscribe = {
            request: "join",
            room: roomId,
            ptype: "subscriber",
            streams: subscription,
          };
          remoteFeed.send({ message: subscribe });
        },
        error: function (error) {
          console.log("  -- Error attaching plugin...", error);
        },
        onmessage: function (msg, jsep) {
          // console.log('Subscriber Msg: ', msg, jsep);
          let event = msg["videoroom"];
          if (msg["error"]) {
            console.log(msg["error"]);
          } else if (event) {
            if (event === "attached") {
              // Now we have a working subscription, next requests will update this one
              setCreatingSubscription(false)
              // console.log("Successfully attached to feed in room " + msg["room"]);
            } else if (event === "event") {

            } else {
              // What has just happened?
            }
          }
          if (msg["streams"]) {
            // Update map of subscriptions by mid
            for (let i in msg["streams"]) {
              let mid = msg["streams"][i]["mid"];
              subStreams[mid] = msg["streams"][i];
              let feed = feedStreams[msg["streams"][i]["feed_id"]];
              if (feed && feed.slot) {
                slots[mid] = feed.slot;
                mids[feed.slot] = mid;
              }
            }
          }
          if (jsep) {
            remoteFeed.createAnswer(
              {
                jsep: jsep,
                tracks: [
                  { type: 'data' }
                ],
                success: function (ourjsep) {
                  let body = { request: "start", room: roomId };
                  remoteFeed.send({ message: body, jsep: ourjsep });
                },
                error: function (error) {
                  Janus.error("WebRTC error:", error);
                }
              });
          }
        },
        slowLink: function (uplink, lost, mid) {
          console.log("Janus reports problems " + (uplink ? "sending" : "receiving") +
            " packets on mid " + mid + " (" + lost + " lost packets)");
        },
        webrtcState: function (on) {
          console.log("Janus says this WebRTC PeerConnection (remote feed) is " + (on ? "up" : "down") + " now");
        },
        onlocaltrack: function (track, on) {
          console.log(track, on);
          // The subscriber stream is recvonly, we don't expect anything here
        },
        onremotetrack: async function (track, mid, on, metadata) {
          let sub = subStreams[mid];
          let feed = feedStreams[sub.feed_id];
          let id = sub.feed_id
          // if (feed) {
          //   console.log(" >> This track is coming from feed ", feed, track, mid, on, metadata);
          // } else {
          //   console.log(" >> This track is coming without feed ", feed, track, mid, on, metadata);
          // }
          if (feed && metadata.reason !== 'mute') {
            if (Number(JSON.parse(feed.display).type) === 0) {
              if (on) {
                setRemoteFeeds(prevFeed => {
                  const fid = new Set(prevFeed.map(stream => stream.id));
                  if (!fid.has(feed.id)) {
                    return [feed, ...prevFeed];
                  }
                  return prevFeed;
                })
                if (track.kind === 'audio') {
                  setRemoteAudio(prevAudio => {
                    const fid = new Set(prevAudio.map(stream => stream.id));
                    if (!fid.has(feed.id)) {
                      return [{ id: feed.id, audioTrack: track, mid: mid }, ...prevAudio];
                    } else {
                      let items = [...prevAudio];
                      for (let i = 0; i < items.length; i++) {
                        const e = items[i];
                        if (e.id === feed.id) {
                          items[i] = { id: feed.id, audioTrack: track, mid: mid }
                        }
                      }
                      return items
                    }
                  })
                }
                if (track.kind === 'video') {
                  setRemoteStreams(prevStream => {
                    if (prevStream.some(stream => stream.id === feed.id)) {
                      let items = [...prevStream];
                      for (let i = 0; i < items.length; i++) {
                        const e = items[i];
                        if (e.id === feed.id) {
                          items[i] = { id: feed.id, videoTrack: track, mid: mid }
                        }
                      }
                      return items
                    }
                    return [{ id: feed.id, videoTrack: track, mid: mid }, ...prevStream];
                  })
                }
              }
              else if (!on) {
                if (track.kind === 'audio') {
                  setRemoteAudio(prevAudio => {
                    const fid = new Set(prevAudio.map(stream => stream.id));
                    if (fid.has(feed.id)) {
                      let items = [...prevAudio];
                      for (let i = 0; i < items.length; i++) {
                        const e = items[i];
                        if (e.id === feed.id) {
                          items[i] = { id: feed.id, audioTrack: track, mid: mid }
                        }
                      }
                      return items
                    }
                    return prevAudio;
                  })
                }
                if (track.kind === 'video') {
                  setRemoteStreams(prevStream => {
                    const fid = new Set(prevStream.map(stream => stream.id));
                    if (fid.has(feed.id)) {
                      let items = [...prevStream];
                      for (let i = 0; i < items.length; i++) {
                        const e = items[i];
                        if (e.id === feed.id) {
                          items[i] = { id: feed.id, videoTrack: track, mid: mid }
                        }
                      }
                      return items
                    }
                    return prevStream;
                  })
                }
              }
            }
            else {
              if (on) {
                setRemoteFeeds(prevFeed => {
                  const fid = new Set(prevFeed.map(stream => stream.id));
                  if (!fid.has(feed.id)) {
                    return [feed, ...prevFeed];
                  }
                  return prevFeed;
                })
                if (track.kind === 'video') {
                  setRemoteScreens(prevStream => {
                    if (prevStream.some(stream => stream.id === feed.id)) {
                      let items = [...prevStream];
                      for (let i = 0; i < items.length; i++) {
                        const e = items[i];
                        if (e.id === feed.id) {
                          items[i] = { id: feed.id, videoTrack: track, mid: mid }
                        }
                      }
                      return items
                    }
                    return [{ id: feed.id, videoTrack: track, mid: mid }, ...prevStream];
                  })
                }
              }
              else if (!on) {
                if (track.kind === 'video') {
                  setRemoteScreens(prevStream => {
                    return prevStream.filter((f) => { return Number(f.id) !== feed.id });
                  })
                }
              }
            }
          }
        },
        oncleanup: function () {
        }
      });
  }

  function unsubscribeFrom(id) {
    let feed = feedStreams[id];
    setRemoteStreams(prev => {
      let d = prev.filter((f) => f.id !== id)
      return [...d]
    })
    setRemoteScreens(prev => {
      let d = prev.filter((f) => f.id !== id)
      return [...d]
    })
    setRemoteAudio(prev => {
      let d = prev.filter((f) => f.id !== id)
      return [...d]
    })
    setRemoteFeeds(prev => {
      let d = prev.filter((f) => f.id !== id)
      return [...d]
    })
    // Unsubscribe from this publisher

    if (!feed)
      return;
    // console.log("Feed " + id + " (" + feed.display + ") has left the room, detaching");
    delete feeds[feed.slot];
    feeds.slot = 0;
    delete feedStreams[id];
    // Send an unsubscribe request
    let unsubscribe = {
      request: "unsubscribe",
      streams: [{ feed: id }]
    };
    if (remoteFeed != null)
      remoteFeed.send({ message: unsubscribe });
    delete subscriptions[id];
  }

  // **************************************Audio Toggle Work******************************************
  function toggleAudio() {
    if (audioEnabled) {
      setBtnDisabled(true)
      videoRoom.createOffer(
        {
          tracks: [{ type: 'audio', remove: true }],
          success: function (jsep) {
            videoRoom.send({ message: { request: 'configure' }, jsep: jsep })
            setAudioEnabled(false)
            if (state) {
              state.send(JSON.stringify({ feed: myFeedId, enabled: false, type: MsgType.AUDIOTOGGLE }))
            }
          },
          error: function (error) {
            setBtnDisabled(false)
            console.log('WebRTC error... ' + error.message);
          }
        });
    } else {
      let tracks = []
      tracks.push({ type: 'audio', capture: true, recv: true, replace: true })
      setBtnDisabled(true)
      videoRoom.createOffer(
        {
          tracks: tracks,
          success: function (jsep) {
            videoRoom.send({ message: { request: 'configure' }, jsep: jsep })
            setAudioEnabled(true)
          },
          error: function (error) {
            setBtnDisabled(false)
            console.log('WebRTC error... ' + error.message);
          }
        });
    }

  }

  useEffect(() => {
    if (audiotoggle) {
      if (!audiotoggle.enabled) {
        setRemoteAudio(prev => {
          let d = prev.filter((f) => Number(f.id) !== Number(audiotoggle.feed))
          return [...d]
        })
      }
      clearAudioToggle()
    }
  }, [audiotoggle])
  // **************************************Video Toggle Work******************************************
  const toggleVideo = () => {
    if (videoRoom) {
      if (videoEnabled) {
        setBtnDisabled(true)
        videoRoom.createOffer(
          {
            tracks: [{ type: 'video', remove: true }],
            success: function (jsep) {
              videoRoom.send({ message: { request: 'configure' }, jsep: jsep })
              setVideoEnabled(!videoEnabled)
              if (state) {
                state.send(JSON.stringify({ feed: myFeedId, enabled: false, type: MsgType.VIDEOTOGGLE }))
              }
            },
            error: function (error) {
              setBtnDisabled(false)
              console.log('WebRTC error... ' + error.message);
            }
          });
      } else {
        setBtnDisabled(true)
        let tracks = []
        tracks.push({ type: 'video', capture: true, recv: true, replace: true })
        videoRoom.createOffer(
          {
            tracks: tracks,
            success: function (jsep) {
              videoRoom.send({ message: { request: 'configure' }, jsep: jsep })
              setVideoEnabled(!videoEnabled)
              setVideoPublished(true)
            },
            error: function (error) {
              setBtnDisabled(false)
              console.log('WebRTC error... ' + error.message);
            }
          });
      }
    }
  };

  useEffect(() => {
    if (videotoggle) {
      if (!videotoggle.enabled) {
        setRemoteStreams(prev => {
          let d = prev.filter((f) => Number(f.id) !== Number(videotoggle.feed))
          return [...d]
        })
        clearVideoToggle()
      }
    }
  }, [videotoggle])
  // ------------------------------------Screen Sharing Work---------------------------------
  let myshareid = null, srom = null
  const [shareRoom, setShareRoom] = useState(null)

  const toggleScreenSharing = async () => {
    if (!screenSharing) {
      janus.attach({
        plugin: 'janus.plugin.videoroom',
        success: function (pluginHandle) {
          srom = pluginHandle
          setShareRoom(pluginHandle);
          // Join the video room
          pluginHandle.send({
            message: {
              request: 'join',
              room: roomId,
              ptype: 'publisher',
              display: myusername,
            },
          });
        },
        error: function (cause) {
          // Couldn't attach to the plugin
        },
        onmessage: function (msg, jsep) {
          // console.log('Share Publisher Msg: ', msg, jsep);
          let event = msg["videoroom"];
          if (event != undefined && event != null) {
            if (msg.videoroom === 'joined') {
              myshareid = msg.id
              srom.createOffer(
                {
                  tracks: [{ type: 'screen', capture: true, add: true }],
                  success: function (jsep) {
                    srom.send({ message: { request: 'publish' }, jsep: jsep });
                    setScreenSharing(true)
                  },
                  error: function (error) {
                    console.error('Error creating offer:', error);
                  },
                });
              if (msg["publishers"]) {
                let list = msg["publishers"];
                let sources = null;
                for (let f in list) {
                  let id = list[f]["id"];
                  let display = list[f]["display"];
                  let streams = list[f]["streams"];
                  if (Number(id) !== Number(myFeedId)) {
                    for (let i in streams) {
                      let stream = streams[i];
                      stream["id"] = id;
                      stream["display"] = display;
                    }
                    let slot = feedStreams[id] ? feedStreams[id].slot : null;
                    let remoteVideos = feedStreams[id] ? feedStreams[id].remoteVideos : 0;
                    feedStreams[id] = {
                      id: id,
                      display: display,
                      streams: streams,
                      slot: slot,
                      remoteVideos: remoteVideos
                    }
                    if (!sources)
                      sources = [];
                    sources.push(streams);
                  }
                }
                if (sources)
                  subscribeTo(sources);
              }
            }

            if (event === 'event') {
              if (msg["streams"]) {
                let streams = msg["streams"];
                for (let i in streams) {
                  let stream = streams[i];
                  stream["id"] = myshareid;
                  stream["display"] = myusername;
                }
                feedStreams[myshareid] = {
                  id: myshareid,
                  display: myusername,
                  streams: streams
                }
              }
              else if (msg["publishers"]) {
                let list = msg["publishers"];
                let sources = null;
                for (let f in list) {
                  let id = list[f]["id"];
                  let display = list[f]["display"];
                  let streams = list[f]["streams"];
                  if (Number(id) !== Number(myFeedId)) {
                    for (let i in streams) {
                      let stream = streams[i];
                      stream["id"] = id;
                      stream["display"] = display;
                    }
                    let slot = feedStreams[id] ? feedStreams[id].slot : null;
                    let remoteVideos = feedStreams[id] ? feedStreams[id].remoteVideos : 0;
                    feedStreams[id] = {
                      id: id,
                      display: display,
                      streams: streams,
                      slot: slot,
                      remoteVideos: remoteVideos
                    }
                    if (!sources)
                      sources = [];
                    sources.push(streams);
                  }
                }
                if (sources)
                  subscribeTo(sources);

              } else if (msg["leaving"]) {
                // One of the publishers has gone away?
                let leaving = msg["leaving"];
                console.log("Publisher left: " + leaving);
                unsubscribeFrom(leaving);
              } else if (msg["unpublished"]) {
                // One of the publishers has unpublished?
                let unpublished = msg["unpublished"];
                console.log("Publisher left: " + unpublished);
                if (unpublished === 'ok') {
                  srom.hangup();
                  return;
                }
              }
            }
          }

          if (jsep) {
            // We have an OFFER from the plugin
            srom.handleRemoteJsep({ jsep })
          }

        },
        onlocaltrack: function (track, added) {
          track.onended = () => {
            srom.createOffer(
              {
                tracks: [{ type: 'screen', capture: false }],
                success: function (jsep) {
                  srom.send({ message: { request: 'unpublish' }, jsep: jsep });
                  setScreenSharing(false)
                },
                error: function (error) {
                  console.error('Error creating offer:', error);
                },
              });
          }
        },
        onremotetrack: function (track, mid, added, metadata) {
          console.log('Trackssss', track);
        },

      });
    } else {
      shareRoom.createOffer(
        {
          tracks: [{ type: 'screen', capture: false }],
          success: function (jsep) {
            shareRoom.send({ message: { request: 'unpublish' }, jsep: jsep });
            setScreenSharing(false)
          },
          error: function (error) {
            console.error('Error creating offer:', error);
          },
        });

    }
  };

  if (!first) {
    return (
      <>
        {!meetingEndForAll ? <>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Toaster
              position="top-center"
              reverseOrder={false}
            />
            <MainHeader code={unique_code} user={id} meetingDetails={meetingDetails} />

            {remoteScreens.length > 0 ?
              <div className="mainHeaderBodyWrapper">

                {/* ******************************** DeskTop View****************** */}
                <div className="mainBodyBlock1 main_mobile_hide">
                  <RemoteScreen remoteScreen={remoteScreens[0]} username={username} remoteAudio={remoteAudio} remoteFeeds={remoteFeeds} />
                </div>
                <div className="mainBodyBlock2 main_mobile_hide scroll bg_meeting">
                  <div className="" style={{ overflowY: 'auto' }}>
                    <div className="meetingTitle color_dark p-2 bg_white" style={{ fontSize: '13px', borderBottom: '2px solid #f8f8f8' }}>
                      Participant - {participant.length}
                    </div>
                    <div className="myrow pt-1">
                      {participant.map((d, key) => {
                        return <div className="tilesWrapperCard" key={d.id}>
                          <SmallScreenList d={d} count={key} remoteStreams={remoteStreams} remoteAudio={remoteAudio} remoteFeeds={remoteFeeds} />
                        </div>
                      })
                      }
                      <div className="tilesWrapperCard">
                        <MainScreen localAudioStream={localAudioStream} small={true} localStream={localStream} username={username} audioEnabled={audioEnabled} videoEnabled={videoEnabled} />
                      </div>
                    </div>

                  </div>
                </div>

                {/* ******************************Mobile View******************************** */}
                <div className='main_desktop_hide'>
                  <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div className='bg_meeting' style={{ flexGrow: 1, position: 'relative' }}>
                      <RemoteScreen remoteScreen={remoteScreens[0]} username={username} remoteAudio={remoteAudio} remoteFeeds={remoteFeeds} />
                    </div>

                    <div className="align_center" style={{ overflowX: 'auto', whiteSpace: 'nowrap', alignItems: 'center' }}>
                      {participant.map((d, key) => {
                        return <div className="tilesWrapperCard2" key={d.id}>
                          <SmallScreenList d={d} count={key} remoteStreams={remoteStreams} remoteAudio={remoteAudio} remoteFeeds={remoteFeeds} />
                        </div>
                      })
                      }

                    </div>
                  </div>
                </div>

              </div>
              :
              <div className="mainHeaderBodyWrapper">
                <div className="mainBodyBlock1 w-100">
                  {participant.length > 0 ? <>
                    <SmallScreen localAudioStream={localAudioStream} participant={participant} remoteStreams={remoteStreams} remoteAudio={remoteAudio} remoteFeeds={remoteFeeds} small={true} localStream={localStream} username={username} audioEnabled={audioEnabled} videoEnabled={videoEnabled} />
                  </>
                    :
                    <div className="tilesWrapper scroll align_center">
                      <div className="w-100 h-100">
                        <MainScreen localAudioStream={localAudioStream} small={false} localStream={localStream} username={username} audioEnabled={audioEnabled} videoEnabled={videoEnabled} />
                      </div>
                    </div>
                  }


                </div>

              </div>
            }
            <Controls remoteAudio={remoteAudio} localAudioStream={localAudioStream} meetingDetails={meetingDetails} state={state} setBtnDisabled={setBtnDisabled} btnDisabled={btnDisabled} audioEnabled={audioEnabled} videoEnabled={videoEnabled} screenSharing={screenSharing} toggleAudio={toggleAudio} toggleScreenSharing={toggleScreenSharing} toggleVideo={toggleVideo} />

          </div>

          <div className="offcanvas offcanvas-end" data-bs-backdrop="false" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
            <Chat />
          </div>
        </>
          :
          <>
            <Toaster
              position="top-center"
              reverseOrder={false}
            />
            <MeetingEnd state={state} />
          </>
        }

      </>
    )
  } else {
    return (
      <>
        <Topbar />
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '90vh', alignItems: 'center', justifyContent: 'center' }}>
          <div className="otp_block text-center">
            <h2 className='login_title text-center'>Officiel<span className='color_blue'>Meet</span></h2>
            <div style={{ height: '280px' }}>
              <MainScreen localAudioStream={localAudioStream} small={true} localStream={localStream} username={username} audioEnabled={audioEnabled} videoEnabled={videoEnabled} />
            </div>
            <div className='py-3' style={{ borderRadius: '4px', backgroundColor: 'whitesmoke' }}>
              <Icon icon={audioEnabled ? 'ph:microphone' : 'ph:microphone-slash'} className={audioEnabled ? 'mainBodyConrolsButtonActive' : 'mainBodyConrolsButtonInActive'} onClick={!btnDisabled ? toggleAudio : null} />{/* ph:microphone-slash */}
              <Icon icon={videoEnabled ? 'ph:video-camera' : 'ph:video-camera-slash'} className={videoEnabled ? 'mainBodyConrolsButtonActive' : 'mainBodyConrolsButtonInActive'} onClick={!btnDisabled ? toggleVideo : null} />{/* ph:video-camera-slash */}
            </div>

            <button disabled={btnDisabled} onClick={() => setFirst(false)} className="my_button bg_blue color_white w-100">
              {btnDisabled ? "Please wait..." : "Join"}
            </button>

          </div>
        </div>
      </>
    )
  }
}

export default Meeting