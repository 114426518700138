import { Avatar } from '@mui/material'
import moment from 'moment'
import React from 'react'

const ChatList = (props) => {
  const { data } = props
  return (
    <>
      <div className="chatCard my-1 justify_space_between bg_white">
        <div className="d-flex">
          <div className='me-2' >
            <Avatar style={{backgroundColor: '#029AFF', fontSize: '13px'}} sx={{ width: 30, height: 30 }} >{data.name[0]}</Avatar>
          </div>
          <div style={{flexGrow:1}}>
            <div className="meetingInfo color_secondary mb-1">
              {data.name}
            </div>
            <div className="meetingTitle color_dark">
              {data.msg}
            </div>
            <div style={{ fontSize: '9px' }} className="meetingInfo color_secondary text-end">{moment(data.timestamp).format('hh:mm A')}</div>
          </div>
        </div>

      </div>
    </>
  )
}

export default ChatList