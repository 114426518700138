import React, { useEffect, useState } from 'react'
import { useMeetingStore } from '../../Store/MeetingStore/MeetingStore'
import MeetingCard from '../Dashboard/MeetingCard/MeetingCard'
import Loading from '../../Loading'

const Upcoming = (props) => {
    const { subUsers } = props
    const { schedule_meetings, setScheduleMeetings, fetchScheduleMeeting } = useMeetingStore()
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        setIsLoading(true)
        fetchScheduleMeeting()
            .then(res => {
                setIsLoading(false)
                setScheduleMeetings(res.data)
            })
            .catch(err => {
                setIsLoading(false)
                console.log(err);
            })
    }, [])
    return (
        <>
            {schedule_meetings.length > 0 ?
                schedule_meetings.map((m) => {
                    return <MeetingCard data={m} previous={false} key={m.id} subUsers={subUsers} />
                })
                : <div className='color_secondary px-2' style={{fontFamily:'Inter regular'}}>No meetings available.</div>}
            {isLoading && <Loading />}
        </>
    )
}

export default Upcoming