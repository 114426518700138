import React, { useEffect } from 'react'
import './Dashboard.css'
import Topbar from './../../Components/Topbar/Topbar';
import Sidebar from '../../Components/Dashboard/Sidebar/Sidebar';
import { Icon } from '@iconify/react';
import MeetingCard from '../../Components/Dashboard/MeetingCard/MeetingCard';
import Main from '../Main/Main';
import { Route, Routes } from 'react-router-dom';
import Home from '../Home/Home';
import { Path } from '../../Routes/Path';
import Schedule from '../Schedule/Schedule';
import axios from 'axios';
import { url } from './../../Export';
import User from '../Users/User';
import Settings from '../Settings/Settings';

const Dashboard = () => {
    let token = JSON.parse(localStorage.getItem('officiel-user'))
    // useEffect(() => {
    //  axios.get(url+'client/test-msg/', {
    //     headers: {
    //         'Authorization': 'Token ' + token.token,
    //     }
    // })
    //  .then(res=>{
    //     console.log(res.data);
    //  })
    // }, [])
    
    return (
        <>
            <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
                <Topbar />
                <Sidebar />
                <div className="dashboard_body">
                    <div className="dashboard_block">
                        <Routes>
                            <Route path={Path.HOME} element={<Home />} />
                            <Route path={Path.MEETING} element={<Main />} />
                            <Route path={Path.SCHEDULE} element={<Schedule />} />
                            <Route path={Path.SETTINGS} element={<Settings />} />
                            <Route path={Path.USERS} element={<User />} />
                            <Route path={Path.MEETING+'/:unique_code'} element={<Main />} />
                        </Routes>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Dashboard