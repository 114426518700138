import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { Icon } from '@iconify/react';
import { Avatar } from '@mui/material';

const RemoteScreen = (props) => {
    const uiRef = useRef(null);
    const { remoteScreen, remoteFeeds, remoteAudio, uiref } = props
    const [video, setvideo] = useState(new MediaStream([remoteScreen.videoTrack]))
    const [myFeed, setMyFeed] = useState()
    const [remoteUser, setRemoteUser] = useState()

    useEffect(() => {
        setMyFeed(remoteFeeds.find((f) => f.id === remoteScreen.id))
    }, [remoteFeeds])

    useEffect(() => {
        if (myFeed) {
            setRemoteUser(JSON.parse(myFeed.display))
        }
    }, [myFeed])

    // *******************Full Screen Work******************************
    const [isFullScreen, setIsFullScreen] = useState(false)

    const handleFullScreen = () => {
        if (isFullScreen) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { // Chrome, Safari, and Opera
                document.webkitExitFullscreen();
            }
            setIsFullScreen(false)
        } else {
            if (uiRef.current) {
                const elem = uiRef.current;
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) { // Firefox
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) { // Chrome, Safari, and Opera
                    elem.webkitRequestFullscreen();
                }
                elem.style.backgroundColor = '#fff';
                setIsFullScreen(true)
            }
        }
    }

    useEffect(() => {
        document.addEventListener('fullscreenchange', () => {
            if (!document.fullscreenElement) {
                setIsFullScreen(false)
            }
        });
    }, [])

    return (
        <>

            <div className="mainFrame2 bg_navy" ref={uiRef} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative' }}>

                <ReactPlayer width={'100%'} height={'100%'} url={video} playsinline={true}
                    playing={true}
                    muted={false} />
                <div className="myrow justify_space_between screenShareInfo">
                    <div className="">{remoteUser ? remoteUser.name : ''} is Sharing Screen</div>
                    <Icon icon={'icon-park-outline:full-screen'} onClick={handleFullScreen} className={isFullScreen ? 'mainBodyConrolsButtonActive' : 'mainBodyConrolsButtonInActive'} />
                </div>
            </div>

        </>
    )
}

export default RemoteScreen