import { Icon } from '@iconify/react'
import { Tooltip } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import { MsgType, baseUrl } from '../../../Export'
import RecordRTC from 'recordrtc';
import screenfull from 'screenfull';

const Controls = (props) => {
    let user = JSON.parse(localStorage.getItem('officiel-user'))
    const { localAudioStream, audioEnabled, videoEnabled, toggleAudio, toggleScreenSharing, toggleVideo, screenSharing, btnDisabled, setBtnDisabled, meetingDetails, state, remoteAudio } = props;
    // **************************Recording Work Start here ***************************
    const [isRecording, setIsRecording] = useState(false);
    const [screenStream, setScreenStream] = useState()
    const [shared, setShared] = useState(false)
    const recorderRef = useRef(null);
    const mediaStreamRef = useRef(null);
    const [blob, setBlob] = useState([])
    const [stream, setStream] = useState()

    // useEffect(() => {
    //     const handleNewAudioStream = async () => {
    //         if (isRecording) {
    //             try {
    //                 let audioTracks = [];

    //                 if (localAudioStream && !localAudioStream.muted) {
    //                     audioTracks = [...localAudioStream.getTracks()];
    //                 }

    //                 for (let i = 0; i < remoteAudio.length; i++) {
    //                     const remoteStream = remoteAudio[i].audioTrack;
    //                     audioTracks = [...audioTracks, ...remoteStream.getTracks()];
    //                 }

    //                 let combinedStream;
    //                 if (!shared) {
    //                     const screenStreamn = await navigator.mediaDevices.getDisplayMedia({ video: true });
    //                     setScreenStream(screenStreamn);
    //                     setShared(true);
    //                     combinedStream = new MediaStream([...audioTracks, ...screenStreamn.getTracks()]);
    //                 } else {
    //                     combinedStream = new MediaStream([...audioTracks, ...screenStream.getTracks()]);
    //                 }

    //                 mediaStreamRef.current = combinedStream;

    //                 if (!recorderRef.current) {
    //                     recorderRef.current = await RecordRTC(mediaStreamRef.current, {
    //                         type: 'video',
    //                         mimeType: 'video/webm',
    //                         timeSlice: 1000,
    //                         ondataavailable: function (b) {
    //                             console.log(b);
    //                             if (b.size > 0) {
    //                                 setBlob((prevBlobs) => [...prevBlobs, b]);
    //                             }
    //                         },
    //                     });
    //                     await recorderRef.current.startRecording();
    //                 } else {
    //                     await recorderRef.current.stopRecording(function (videoBlob) {
    //                         recorderRef.current.clearRecordedData()
    //                     })
    //                     recorderRef.current = await RecordRTC(mediaStreamRef.current, {
    //                         type: 'video',
    //                         mimeType: 'video/webm',
    //                         timeSlice: 1000,
    //                         ondataavailable: function (b) {
    //                             console.log(b);
    //                             if (b.size > 0) {
    //                                 setBlob((prevBlobs) => [...prevBlobs, b]);
    //                             }
    //                         },
    //                     });
    //                     await recorderRef.current.startRecording();


    //                 }
    //             } catch (error) {
    //                 console.error('Error starting recording:', error);
    //             }
    //         }
    //     };

    //     handleNewAudioStream();

    // }, [localAudioStream, remoteAudio, isRecording]);

    const startRecording = async () => {
        document.getElementById('closeRecordingModal').click()
        setShared(false)
        setScreenStream()
        try {
            const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const speakerStream = await navigator.mediaDevices.getDisplayMedia({
                audio: true,
                video: true,
            });

            const audioContext = new window.AudioContext();
            const micSource = audioContext.createMediaStreamSource(audioStream);
            const speakerSource = audioContext.createMediaStreamSource(speakerStream);

            const destination = audioContext.createMediaStreamDestination();
            micSource.connect(destination);
            speakerSource.connect(destination);

            setIsRecording(true);
            setStream(destination.stream);

            const mimeTypes = ["audio/mp4", "audio/webm"].filter((type) =>
                MediaRecorder.isTypeSupported(type)
            );

            if (mimeTypes.length === 0) {
                return alert("Browser not supported");
            }

            let recorder = new MediaRecorder(destination.stream, { mimeType: mimeTypes[0] });

            recorder.addEventListener("dataavailable", async (event) => {
                if (event.data.size > 0) {
                    setBlob((prevBlob) => [...prevBlob, event.data])
                }
            });

            recorder.start(1000);
        } catch (error) {
            setIsRecording(false)
            console.error('Error starting recording:', error);
        }
    };

    async function concatenateBlobs(blobs) {
        return new Blob(blobs, { type: 'video/mp4' });
    }

    const stopRecording = async () => {
        if (!recorderRef.current) {
            document.getElementById('closeRecordingModal').click()
            setIsRecording(false);
            // recorderRef.current.stopRecording();
            const blob2 = await concatenateBlobs(blob);
            const downloadUrl = URL.createObjectURL(blob2);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = 'recorded-video.webm'; // Adjust filename and extension
            link.click();
            URL.revokeObjectURL(downloadUrl);
            if (mediaStreamRef.current) {
                mediaStreamRef.current.getTracks().forEach(track => track.stop());
                recorderRef.current = null
            }
        }
    };

    // **************************Recording Work End here ***************************
    useEffect(() => {
        if (btnDisabled) {
            setTimeout(() => {
                setBtnDisabled(false);
            }, 2000);
        }
    }, [btnDisabled]);

    const [host, setHost] = useState(false)

    useEffect(() => {
        if (user) {
            if (meetingDetails.host_id === user.id) {
                setHost(true)
            } else {
                setHost(false)
            }
        }
    }, [user, meetingDetails])

    const handleEndMeeting = () => {
        if (state) {
            document.getElementById('meetingEndHostModalDissmiss').click()
            state.send(JSON.stringify({ type: MsgType.ENDMEETINGFORALL }))
        }
    }

    return (
        <>
            <div className='mainBodyControlsWrapper'>
                <div className="mainBodyConrolsButtonGroup align_center">
                    <div className="mainBodyConrolsButtonGroupLeft">
                        <Icon icon={audioEnabled ? 'ph:microphone' : 'ph:microphone-slash'} className={audioEnabled ? 'mainBodyConrolsButtonActive' : 'mainBodyConrolsButtonInActive'} onClick={!btnDisabled ? toggleAudio : null} />{/* ph:microphone-slash */}
                        <Icon icon={videoEnabled ? 'ph:video-camera' : 'ph:video-camera-slash'} className={videoEnabled ? 'mainBodyConrolsButtonActive' : 'mainBodyConrolsButtonInActive'} onClick={!btnDisabled ? toggleVideo : null} />{/* ph:video-camera-slash */}
                        {meetingDetails.screen_sharing ?
                            <Icon icon={'material-symbols:present-to-all-outline-rounded'} className={screenSharing ? 'mainBodyConrolsButtonActive' : 'mainBodyConrolsButtonInActive'} onClick={toggleScreenSharing} />
                            :
                            <>
                                {host && <Icon icon={'material-symbols:present-to-all-outline-rounded'} className={screenSharing ? 'mainBodyConrolsButtonActive' : 'mainBodyConrolsButtonInActive'} onClick={toggleScreenSharing} />}
                            </>
                        }
                        {/* {isRecording ?
                            <div data-bs-toggle="modal" data-bs-target="#recordMeeting" style={{ cursor: 'pointer' }}>
                                <span className="spinner-grow text-danger mt-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </span>
                            </div>
                            :
                            <Icon icon={'vaadin:dot-circle'} data-bs-toggle="modal" data-bs-target="#recordMeeting" style={{ color: isRecording ? '#EB5757' : '#029AFF' }} className={isRecording ? 'mainBodyConrolsButtonInActive' : 'mainBodyConrolsButtonInActive'} />
                        } */}
                        <Icon data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" icon={'mynaui:message-dots'} className='mainBodyConrolsButtonInActive' />

                        <Tooltip title="Coming Soon" arrow> <Icon icon={'ri:more-fill'} className='mainBodyConrolsButtonInActive' /></Tooltip>
                    </div>

                    <div className='mainBodyConrolsButtonGroupRight'>
                        {host ?
                            <button data-bs-toggle="modal" data-bs-target={"#endMeetingConfirmationModalHost"} className="end_call_button color_white bg_red">End call</button>
                            :
                            <button data-bs-toggle="modal" data-bs-target={"#endMeetingConfirmationModal"} className="end_call_button color_white bg_red">End call</button>
                        }
                    </div>
                </div>
            </div>

            {/* ------------------------------------------------------------------ */}

            <div className="modal fade" id="recordMeeting" tabIndex="-1" aria-labelledby="recordMeetingLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="p-2 px-3 myrow justify_space_between" style={{ borderBottom: '1px solid rgba(0,0,0,0.1)' }}>
                            <span className="color_red">Record Meeting</span>
                        </div>
                        {isRecording ?
                            <div className="modal-body">
                                After clicking on stop recording your recording media will be start downloading.
                                <div className="mt-3 text-end">
                                    <button className="my_button bg_red color_white me-2" onClick={stopRecording} >Stop Recording</button>
                                    <button className="my_button bg_dark color_white" id='closeRecordingModal' type="button" data-bs-dismiss="modal" >Cancel</button>
                                </div>
                            </div>
                            :
                            <div className="modal-body">
                                To record meeting you have to give permission to share screen and system audio due to security reasons. Please do not click on stop sharing button of browser other wise no screen will be capture.
                                <div className="mt-3 text-end">
                                    <button className="my_button bg_red color_white me-2" onClick={startRecording} >Start Recording</button>
                                    <button className="my_button bg_dark color_white" id='closeRecordingModal' type="button" data-bs-dismiss="modal" >Cancel</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            {/* ////////////////////Host Meeting Leave Confirmation Modal////////////////////////// */}

            <div className="modal fade" id={"endMeetingConfirmationModalHost"} tabIndex="-1" aria-labelledby="endMeetingConfirmationModalHostLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content schedule_modal">
                        <div className="schedule_modal_header myrow justify_space_between">
                            <span className="color_blue">Are you sure?</span>
                            <span className="color_blue" type="button" data-bs-dismiss="modal"><Icon icon={"ep:close-bold"} fontSize={22} /></span>
                        </div>
                        <div className="schedule_modal_body">
                            <div className='pb-3' style={{ fontSize: '15px' }}>Do you want to leave this meeting or end this meeting? </div>

                            <div className="modal_input_wrapper align_center" style={{ justifyContent: 'end' }}>
                                <span id='meetingEndHostModalDissmiss' className="my_button bg_dark color_white mx-1" data-bs-dismiss="modal">Cancel</span>
                                <button className="my_button bg_navy2 color_white mx-1" onClick={() => window.location = baseUrl}>Leave</button>
                                <button className="my_button bg_red color_white mx-1" onClick={handleEndMeeting}>End Meeting</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* ////////////////////Meeting Leave Confirmation Modal////////////////////////// */}

            <div className="modal fade" id={"endMeetingConfirmationModal"} tabIndex="-1" aria-labelledby="endMeetingConfirmationModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content schedule_modal">
                        <div className="schedule_modal_header myrow justify_space_between">
                            <span className="color_blue">Are you sure?</span>
                            <span className="color_blue" type="button" data-bs-dismiss="modal"><Icon icon={"ep:close-bold"} fontSize={22} /></span>
                        </div>
                        <div className="schedule_modal_body">
                            <div className='pb-3' style={{ fontSize: '15px' }}>Do you really want to leave this meeting ? </div>

                            <div className="modal_input_wrapper align_center" style={{ justifyContent: 'end' }}>
                                <span className="my_button bg_dark color_white mx-1" data-bs-dismiss="modal">Cancel</span>
                                <button className="my_button bg_red color_white mx-1" onClick={() => window.location = baseUrl}>Leave</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Controls