import React, { useEffect, useState } from 'react'
import './ForgotPassword.css'
import { Link, useNavigate } from 'react-router-dom'
import { Path } from './../../Routes/Path';
import { Icon } from '@iconify/react';
import { useLoginStore } from '../../Store/AccountStore/LoginStore/LoginStore';
import { useToasts } from 'react-toast-notifications';
import { useRegisterStore } from './../../Store/AccountStore/RegisterStore/RegisterStore';
import logo from '../../Images/logo2.png'

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts()
  const { sendForgotPasswordOTP, otpVerify, changePassword } = useLoginStore()
  const { sendEmailOtp } = useRegisterStore()
  const [isLoading, setIsLoading] = useState(false)
  const [otpSend, setOtpSend] = useState(false)
  const [payload, setPayload] = useState({
    email: '', email_otp: ''
  })
  const [validate, setValidate] = useState(false)
  useEffect(() => {
    if (payload.email.length > 0) {
      if (!payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        setValidate(false)
      } else {
        setValidate(true)
      }
    }
  }, [payload.email])

  const sendOtp = () => {
    setIsLoading(true)
    sendForgotPasswordOTP(payload)
      .then(res => {
        setIsLoading(false)
        setOtpSend(true)
        addToast('OTP !! Successfully sent to your registered mail id.', {
          appearance: 'success',
          autoDismiss: true,
        })
      })
      .catch(err => {
        setIsLoading(false)
        err.response.status === 400 ?
          addToast(err.response.data, {
            appearance: 'error',
            autoDismiss: true,
          })
          :
          addToast('Oops! Something went wrong.', {
            appearance: 'error',
            autoDismiss: true,
          })
      })
  }

  const [finalStep, setFinalStep] = useState(false)

  const handleVerifyOtp = () => {
    setIsLoading(true)
    otpVerify(payload)
      .then(res => {
        setIsLoading(false)
        setFinalStep(true)
      })
      .catch(err => {
        setIsLoading(false)
        setIsLoading(false)
        err.response.status === 400 ?
          addToast(err.response.data, {
            appearance: 'error',
            autoDismiss: true,
          })
          :
          addToast('Oops! Something went wrong.', {
            appearance: 'error',
            autoDismiss: true,
          })
      })
  }

  const emailOtpResend = () => {
    setIsLoading(true)
    sendEmailOtp(payload.email)
      .then(res => {
        setIsLoading(false)
        addToast('OTP successfully sent to your email...!', {
          appearance: 'success',
          autoDismiss: true,
        })
      })
      .catch(err => {
        setIsLoading(false)
        addToast('Oops! Something went wrong.', {
          appearance: 'error',
          autoDismiss: true,
        })
      })
  }

  const [data, setData] = useState({
    password: '', confirm_password: ''
  })
  const [error, setError] = useState({
    password: '', confirm_password: ''
  })

  const handleValidation = () => {
    let formIsValid = true;
    let errors = { ...error }
    if (data.password.length > 0) {
      if (!data.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,20}$/)) {
        formIsValid = false;
        errors.password = 'Password must contain at least one number and one uppercase and lowercase letter and one special character, and at least 6 or more characters'
      } else {
        errors.password = ''
      }
    }
    else {
      formIsValid = false;
      errors.password = "Password field can't be empty."
    }

    if (data.confirm_password.length > 0) {
      if (data.password !== data.confirm_password) {
        formIsValid = false;
        errors.confirm_password = 'Confirm Password does not matched.'
      } else {
        errors.confirm_password = ''
      }
    }
    else {
      formIsValid = false;
      errors.confirm_password = "Confirm Password field can't be empty."
    }
    setError(errors)
    return formIsValid
  }

  const handleSubmit = () => {
    if (handleValidation()) {
      setIsLoading(true)
      changePassword(data, payload.email)
        .then(res => {
          setIsLoading(false)
          addToast('Success !! login to continue.', {
            appearance: 'success',
            autoDismiss: true,
          })
          navigate(Path.LOGIN);
        })
        .catch(err => {
          setIsLoading(false)
          err.response.status === 400 ?
            addToast(err.response.data, {
              appearance: 'error',
              autoDismiss: true,
            })
            // Swal.fire('Error', err.response.data, 'error')
            :
            addToast('Oops! Something went wrong.', {
              appearance: 'error',
              autoDismiss: true,
            })
          // Swal.fire('Error', 'Oops! Something went wrong.', 'error')
        })
    }
  }

  return (
    <>
      <div className="forgot_password_wrapper bg_blue">
        <div className="forgot_password_box">
          <div className='landing_logo'><img src={logo} className='logo' alt="" /></div>
          <h5 className='mt-2 landing_logo'>Forgot Password?</h5>
          {!finalStep ? <>
            <p className='login_label'>Enter your email and we'll send you a otp to reset your password.</p>
            {otpSend ?
              <>
                <input type="text" value={payload.email_otp} className='login_input' onChange={(e) => setPayload({ ...payload, email_otp: e.target.value })} placeholder='Enter otp sent to email...' />
                <button onClick={handleVerifyOtp} className="my_button bg_blue color_white w-100" style={{ opacity: payload.email_otp.length === 6 ? 1 : 0.4 }} disabled={payload.email_otp.length === 6 ? false : true}>
                  {isLoading ?
                    <>
                      <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                      Please wait...
                    </>
                    :
                    "Verify"}</button>
                <div className="login_label mt-2">
                  If you didn't receive a code !! <span type="button" className='color_blue' onClick={emailOtpResend}>RESEND</span>
                </div>
              </> : <>
                <input type="text" className='login_input' value={payload.email} onChange={(e) => setPayload({ ...payload, email: e.target.value })} placeholder='Enter register email...' />
                <button onClick={sendOtp} className="my_button bg_blue color_white w-100 mt-2" style={{ opacity: validate ? 1 : 0.4 }} disabled={validate ? false : true}>
                  {isLoading ?
                    <>
                      <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                      Please wait...
                    </>
                    :
                    "SEND OTP"}</button>
              </>}
          </> :
            <>
              <label htmlFor="" className="login_label text-start">New Password</label>
              <input type="password" value={data.password} onChange={(e) => setData({ ...data, password: e.target.value })} placeholder='Enter Password' className="login_input" />
              {error.password.length > 0 && <div className="text-danger text-start" style={{ fontSize: '12px' }}>{error.password}</div>}

              <label htmlFor="" className="login_label text-start">Confirm New Password</label>
              <input value={data.confirm_password} onChange={(e) => setData({ ...data, confirm_password: e.target.value })} type={"password"} placeholder='Enter password' className="login_input" />
              {error.confirm_password.length > 0 && <div className="text-danger text-start" style={{ fontSize: '12px' }}>{error.confirm_password}</div>}

              <button onClick={handleSubmit} className="my_button bg_blue color_white w-100 mt-2" style={{ opacity: validate ? 1 : 0.4 }} disabled={validate ? false : true}>
                  {isLoading ?
                    <>
                      <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                      Please wait...
                    </>
                    :
                    "SUBMIT"}</button>
            </>
          }
          <div className="mt-2">
            <Link to={Path.LOGIN} className="login_label color_blue">
              <Icon fontSize={14} icon="ion:chevron-back-outline" /> &nbsp; Back to Login
            </Link>
          </div>

        </div>
      </div>
    </>
  )
}

export default ForgotPassword