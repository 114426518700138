import React, { useState, useEffect } from 'react'
import './JoinePage.css'
import Topbar from '../../../Components/Topbar/Topbar'
import Meeting from '../Meeting/Meeting'
import { useToasts } from 'react-toast-notifications'
import NoNetwork from '../../../Components/NoNetwork/NoNetwork'
import logo from '../../../Images/logo2.png'

const JoinePage = (props) => {
    const { addToast } = useToasts()
    const { meetingDetails } = props
    const [isLoading, setIsLoading] = useState(false)
    const [token, setToken] = useState(JSON.parse(localStorage.getItem('officiel-user')))
    const [connected, setConnected] = useState(true)

    const [payload, setPayload] = useState({
        name: '', email: '', token: '', contact: '', password: ''
    })

    const handlePayload = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (meetingDetails.password) {
            if (payload.name.length > 0 && payload.email.length > 0 && payload.password.length > 0) {
                if (payload.password + "ofcmet" === meetingDetails.password + "ofcmet") {
                    if (emailRegex.test(payload.email)) {
                        setToken(payload)
                    } else {
                        addToast('Please enter valid email...', {
                            appearance: 'error',
                            autoDismiss: true,
                        })
                    }
                } else {
                    addToast('Invalid password.', {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            }
            else {
                addToast('All fields required...', {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }

        } else {
            if (payload.name.length > 0 && payload.email.length > 0) {
                if (emailRegex.test(payload.email)) {
                    setToken(payload)
                } else {
                    addToast('Please enter valid email...', {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            } else {
                addToast('All fields required...', {
                    appearance: 'error',
                    autoDismiss: true,
                })
            }
        }
    }

    // Internet Checking
    const [isOnline, setIsOnline] = useState(true);

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
        };
        const handleOffline = () => {
            setIsOnline(false);
            setConnected(false)
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    if (connected) {
        if (token) {
            return <Meeting id={token} meetingDetails={meetingDetails} isOnline={isOnline} setConnected={setConnected} />
        } else {
            return (
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }}>
                        <div className="otp_block text-center">
                            <h2 className='text-center'><img src={logo} className='logo' alt="" /></h2>
                            <p className='login_label'>Please enter your details to join meeting.</p>
                            <div className='text-start'>
                                <label htmlFor="" className="login_label">Name <span className="text-danger">*</span></label>
                                <input type="text" className='login_input' value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })} placeholder='Enter your name...' />
                            </div>
                            <div className='text-start'>
                                <label htmlFor="" className="login_label">Email <span className="text-danger">*</span></label>
                                <input type="text" className='login_input' value={payload.email} onChange={(e) => setPayload({ ...payload, email: e.target.value })} placeholder='Enter your email...' />
                            </div>
                            {meetingDetails.password && <div className='text-start'>
                                <label htmlFor="" className="login_label">Password <span className="text-danger">*</span></label>
                                <input type="text" className='login_input' value={payload.password} onChange={(e) => setPayload({ ...payload, password: e.target.value })} placeholder='Enter password...' />
                            </div>}
                            <button onClick={handlePayload} className="my_button mt-3 bg_blue color_white w-100">
                                {isLoading ?
                                    <>
                                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                        Please wait...
                                    </>
                                    :
                                    "Join Now"}</button>
                        </div>
                    </div>
                </>
            )
        }
    } else {
        return <NoNetwork />
    }
}

export default JoinePage