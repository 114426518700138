import React from 'react'
import thumb1 from '../../Images/thumb-1.png'
import thumb2 from '../../Images/thumb-2.png'
import thumb3 from '../../Images/thumb-3.png'
import thumb4 from '../../Images/thumb-4.png'
import thumb5 from '../../Images/thumb-5.png'
import thumb6 from '../../Images/thumb-6.png'
import thumb9 from '../../Images/thumb-9.png'
import thumb7 from '../../Images/thumb-7.png'

const LandingPageImage = () => {
    return (
        <>
            <span style={{ position: 'absolute', right: 0, top: '10%', overflow: 'hidden' }}>
                <img style={{ opacity: 0.30, marginRight: '-50px', overflow: 'hidden' }} width={330} height={158} src={thumb1} />
            </span>
            <img style={{ position: 'absolute', right: '10%', bottom: '50%', zIndex: 2 }} width={350} height={200} src={thumb2} />

            <img style={{ position: 'absolute', right: '25%', bottom: '35%', zIndex: 1 }} width={380} height={200} src={thumb3} />

            <img style={{ position: 'absolute', right: 0, bottom: '15%', zIndex: 3, objectFit: 'cover' }} width={420} height={220} src={thumb4} />

            

            <img style={{ position: 'absolute', right: 300, bottom:'7%', opacity: 0.30, overflow: 'hidden' }} width={400} height={230} src={thumb6} />

            
        </>
    )
}

export default LandingPageImage

{/* <>
            <span style={{ position: 'absolute', right: 0, top: 10, overflow: 'hidden' }}>
                <img style={{ opacity: 0.30, marginRight: '-50px', overflow: 'hidden' }} width={330} height={158} src={thumb1} />
            </span>
            <img className='landing_img_hide' style={{ position: 'absolute', right: 200, bottom: 450, zIndex: 2 }} width={350} height={200} src={thumb2} />

            <img className='landing_img_hide' style={{ position: 'absolute', right: 400, bottom: 300, zIndex: 1 }} width={380} height={200} src={thumb3} />

            <img style={{ position: 'absolute', right: 155, bottom: 255, zIndex: 3, objectFit: 'cover' }} width={420} height={220} src={thumb4} />

            <img className='landing_img_hide' style={{ position: 'absolute', right: 570, bottom: 150, opacity: 0.30, marginRight: '-50px', overflow: 'hidden' }} width={400} height={200} src={thumb5} />

            <img style={{ position: 'absolute', right: 0, bottom: 130, opacity: 0.30, overflow: 'hidden' }} width={300} height={200} src={thumb6} />

            <span style={{ position: 'absolute', right: 0, bottom: 0, overflow: 'hidden' }}>
                <img style={{opacity: 1,marginRight: '-90px', overflow: 'hidden',zIndex:8 }} width={350} height={178} src={thumb9} />
            </span>

            <img className='landing_img_hide' style={{ position: 'absolute', right: 470, bottom: 20, overflow: 'hidden' }} width={300} height={200} src={thumb7} />
        </> */}