import React, { useState } from 'react'
import { GoogleLogin } from '@react-oauth/google';
import { CLIENT_ID } from '../../Export';
import { useLoginStore } from '../../Store/AccountStore/LoginStore/LoginStore'
import { useToasts } from 'react-toast-notifications';
import Loading from '../../Loading';

const GoogleLoginComponent = () => {
    const { addToast } = useToasts()
    const { googleLogin } = useLoginStore()
    const [isLoading, setIsLoading] = useState(false)
    const clientId = CLIENT_ID;
    const onSuccess = (res) => {
        console.log('Login successful:', res.credential);
        handleSuccess(res.credential)
    };

    const onFailure = (error) => {
        addToast('Error !! Something went wrong...', {
            appearance: 'error',
            autoDismiss: true,
        })
    };

    const handleSuccess = (data) => {
        setIsLoading(true)
        googleLogin({ auth_token: data })
            .then(res => {
                setIsLoading(false)
                addToast('Success !! Login...', {
                    appearance: 'success',
                    autoDismiss: true,
                })
                localStorage.setItem('officiel-user', JSON.stringify(res.data))
                document.location.href = "/";
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false)
                addToast('Error !! Something went wrong...', {
                    appearance: 'error',
                    autoDismiss: true,
                })
            })
    }

    return (<>
        <GoogleLogin
            clientId={clientId}
            buttonText="Continue with Google"
            onSuccess={onSuccess}
            onFailure={onFailure}
            theme='filled_black' size='large' width='340px' logo_alignment='center'
        />
        {isLoading && <Loading />}
    </>
    );
}

export default GoogleLoginComponent