import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { Path } from './Path';
import LandingPage from '../Pages/LandingPage/LandingPage';
import Main from '../Pages/Main/Main';
import Dashboard from '../Pages/Dashboard/Dashboard';
import Home from '../Pages/Home/Home';
import Schedule from '../Pages/Schedule/Schedule';
import User from '../Pages/Users/User';
import Settings from '../Pages/Settings/Settings';

export const PageRoutes = () => {
    return (
        <>
            <Routes>
                <Route path={'/'} element={<Dashboard />}>
                    <Route path={Path.HOME} element={<Home />} />
                    <Route path={Path.MEETING} element={<Main />} />
                    <Route path={Path.USERS} element={<User />} />
                    <Route path={Path.SCHEDULE} element={<Schedule />} />
                    <Route path={Path.SETTINGS} element={<Settings />} />
                </Route>
                <Route path={Path.MEETING + '/:unique_code'} element={<Main />} />
            </Routes>
        </>
    )
}