import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useMeetingStore = create((set) => ({
    meeting_code: '',schedule_meetings:[],previous_meetings:[],current_meeting:[],
    createMeeting: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('officiel-user'))
        const response = await axios.post(url + 'meeting/create-meeting/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    setMeetingCode: async (payload) => {
        set({ meeting_code: payload });
    },
    scheduleMeeting: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('officiel-user'))
        const response = await axios.post(url + 'meeting/schedule-meeting/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    fetchScheduleMeeting: async () => {
        let token = await JSON.parse(localStorage.getItem('officiel-user'))
        const response = await axios.get(url + 'meeting/fetch-schedule-meeting/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    setScheduleMeetings: async (payload) => {
        set({ schedule_meetings: payload });
    },

    fetchPreviousMeeting: async () => {
        let token = await JSON.parse(localStorage.getItem('officiel-user'))
        const response = await axios.get(url + 'meeting/fetch-previous-meeting/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    setPreviousMeetings: async (payload) => {
        set({ previous_meetings: payload });
    },
    fetchMeetingDetails: async (id) => {
        const response = await axios.get(url + 'meeting/fetch-meeting-details/'+id+'/');
        return response
    },
    setMeetingDetails: async (payload) => {
        set({ current_meeting: payload });
    },
    deleteMeeting: async (id) => {
        const response = await axios.delete(url + 'meeting/delete-meeting/'+id+'/');
        return response
    },
    updateMeeting: async (payload,id,id2) => {
        let token = await JSON.parse(localStorage.getItem('officiel-user'))
        const response = await axios.put(url + 'meeting/update-meeting/'+id+'/'+id2+'/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
}));