import { useEffect, useState } from 'react';
import './App.css';
import LandingPage from './Pages/LandingPage/LandingPage';
import { AuthRoutes } from './Routes/AuthRoutes';
import { PageRoutes } from './Routes/PageRoutes';
import axios from 'axios';
import { url } from './Export';
import Loading from './Loading';
import { useUserStore } from './Store/AccountStore/UserStore/UserStore';

function App() {
  const [load, setLoad] = useState(true)
  const [user, setUser] = useState(undefined)
  const { currentUser, setCurrentUser, fetchLicense, license } = useUserStore()

  let token = JSON.parse(localStorage.getItem('officiel-user'))

  useEffect(() => {
    if (token === null) {
      setLoad(false)
    } else {
      axios.get(url + 'auth/auth-user/', {
        headers: {
          'Authorization': 'Token ' + token.token
        }
      })
        .then(res => {
          setUser(res.data)
          setCurrentUser(res.data)
          fetchLicense()
          setLoad(false)
        })
        .catch(err => {
          localStorage.removeItem("officiel-user")
          setLoad(false)
        })
    }

  }, [])

  if (!load) {
    return (
      <>
        {!user ?
          <AuthRoutes />
          :
          <PageRoutes />
        }
      </>
    );
  } else {
    return <Loading />
  }
}

export default App;
