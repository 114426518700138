import { Icon } from '@iconify/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Path } from '../../Routes/Path'
import { useToasts } from 'react-toast-notifications'

const JoinMeeting = () => {
    const {addToast} = useToasts()
    const [link, setlink] = useState('')
    const navigate = useNavigate();

    const handleJoinMeeting = () => {
        if(link.length>0){
            document.getElementById("closeJoinModal").click()
            window.open(Path.MEETING + '/' + link, '_blank');
        }else{
            addToast('Please enter meeting code...', {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    }
    return (
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content schedule_modal">
                <div className="schedule_modal_header myrow justify_space_between">
                    <span className="color_blue">Join Meeting</span>
                    <span className="color_blue" id='closeJoinModal' type="button" data-bs-dismiss="modal"><Icon icon={"ep:close-bold"} fontSize={25} /></span>
                </div>
                <div className="schedule_modal_body">
                    <div className="">
                        <input type="text" value={link} onChange={(e)=>setlink(e.target.value)} placeholder='Enter meeting code...' style={{ padding: "7.5px 10px", fontSize: '14px', flexGrow: 1 }} className='login_input mb-3 me-2' />
                    </div>
                    <div className="mt-3">
                        <button className="my_button bg_blue color_white w-100" onClick={handleJoinMeeting}>Join Now</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JoinMeeting