import React from 'react'
import './LandingPage.css'
import { Icon } from '@iconify/react';
import LandingPageImage from './LandingPageImage';
import mobileLandingImg from '../../Images/mobile_landing_cover.png'
import { Link } from 'react-router-dom';
import { Path } from '../../Routes/Path';
import img1 from '../../Images/img1.jpg'
import img2 from '../../Images/img2.jpg'
import img3 from '../../Images/img3.png'
import img4 from '../../Images/img4.png'
import dp from '../../Images/dp.jpg'
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import JoinMeeting from '../../Components/Meeting/JoinMeeting';

const LandingPage = () => {
    return (
        <>
            <Header />
            <div className="landing_wrapper bg_blue">
                <div className='landing_left'>
                    <div>
                        <h1 className='landing_title color_white mt-5'>Elevate Your Business <br />Meetings with Officiel Meet</h1>
                        <div className='landing_logo color_white pt-3' style={{ wordWrap: 'break-word' }}>Experience Hassle-Free Meetings<br />with Our Intuitive Platform!<br /><br />Let’s Get Started!</div>
                    </div>

                    <div className="landing_button_group">
                        <div className='landing_meeting_button'>
                            <button className='landing_button color_blue' data-bs-toggle="modal" data-bs-target="#joinMeeting" style={{ backgroundColor: '#fff' }}><Icon icon="mingcute:add-fill" />&nbsp; Join Meeting</button>
                        </div>
                    </div>

                </div>

                <div className='landing_img_group mt-3 landing_img_hide'>
                    <LandingPageImage />
                </div>
                <div className="w-100 landing_img_show">
                    <img src={mobileLandingImg} alt="" className="img-fluid" />
                </div>
            </div>

            <div className='bg_secondary py-5'>
                <div className="slider_bottom_wrapper myrow justify_space_between align_center">
                    <div className="slider_bottom_block_1">
                        <img src={img1} className='img-fluid' alt="" style={{ borderRadius: 4 }} />
                    </div>
                    <div className="slider_bottom_block_2">

                        <h2 className='login_title mb-4'>Everyone on their <br />own device.</h2>
                        <p className='slider_bottom_block_2_desc'><b>Officiel Meet</b> is designed to be inclusive, by being able to use your own device to help hybrid-conference teams create, collaborate and celebrate together.</p>

                    </div>
                </div>
            </div>

            <div className='py-5'>
                <div className="slider_bottom_wrapper">
                    <h2 className='login_title mb-4 text-center'>Powerful virtual meeting <br />platform solution</h2>
                    <p className='slider_bottom_block_2_desc text-center'>Dedicated spaces that make it easy to come together.</p>

                    <div className="imgClassWrapper">
                        <img src={img2} className='img-fluid' style={{ borderRadius: 6 }} alt="" />
                    </div>
                </div>
            </div>

            <div className='bg_navy'>
                <div className="py-5">
                    <div className="home_dark_wrapper myrow justify_space_between align_center">

                        <div className="slider_bottom_block_1">

                            <h2 className='home_dark_title'>- ALL FEATURES</h2>
                            <h2 className='login_title mb-4 color_white'>Video calls loved by <br /> extraordinary teams.</h2>
                            <p className='slider_bottom_block_2_desc_dark'>By using us, get the benefits that make it easier for you in the conference for your convenience and the participants</p>

                            <div className="mt-5">
                                <div className="myrow justify_space_between">
                                    <div className="d-flex w-50 mt-2 pt-1" style={{ alignItems: "flex-start" }}>
                                        <Icon icon="simple-line-icons:check" fontSize={18} className='color_blue' />
                                        <span className='slider_bottom_block_2_desc_dark ms-3'>Intergration with google meet</span>
                                    </div>
                                    <div className="d-flex w-50 mt-2 pt-1" style={{ alignItems: "flex-start" }}>
                                        <Icon icon="simple-line-icons:check" fontSize={18} className='color_blue' />
                                        <span className='slider_bottom_block_2_desc_dark ms-3'>Messages with participation</span>
                                    </div>
                                </div>
                                <div className="myrow justify_space_between">
                                    <div className="d-flex w-50 mt-2 pt-1" style={{ alignItems: "flex-start" }}>
                                        <Icon icon="simple-line-icons:check" fontSize={18} className='color_blue' />
                                        <span className='slider_bottom_block_2_desc_dark ms-3'>Get data event analytics</span>
                                    </div>
                                    <div className="d-flex w-50 mt-2 pt-1" style={{ alignItems: "flex-start" }}>
                                        <Icon icon="simple-line-icons:check" fontSize={18} className='color_blue' />
                                        <span className='slider_bottom_block_2_desc_dark ms-3'>Advanced Q&A settings</span>
                                    </div>
                                </div>
                                <div className="myrow justify_space_between">
                                    <div className="d-flex w-50 mt-2 pt-1" style={{ alignItems: "flex-start" }}>
                                        <Icon icon="simple-line-icons:check" fontSize={18} className='color_blue' />
                                        <span className='slider_bottom_block_2_desc_dark ms-3'>Protect events with a passcode</span>
                                    </div>
                                    <div className="d-flex w-50   mt-2 pt-1" style={{ alignItems: "flex-start" }}>
                                        <Icon icon="simple-line-icons:check" fontSize={18} className='color_blue' />
                                        <span className='slider_bottom_block_2_desc_dark ms-3'>Crowdsource questions</span>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="slider_bottom_block_2 ">
                            <img src={img3} className='img-fluid' alt="" style={{ borderRadius: 4 }} />
                        </div>

                    </div>
                </div>

                <div className="py-5">
                    <div className="home_dark_wrapper myrow justify_space_between align_center">

                        <div className="slider_bottom_block_1 ">
                            <img src={img4} className='img-fluid' alt="" style={{ borderRadius: 4 }} />
                        </div>

                        <div className="slider_bottom_block_2">

                            <h2 className='home_dark_title'>- BENEFITS OF YOU</h2>
                            <h2 className='login_title mb-4 color_white'>Fast, reliable and secure <br /> for your meetings</h2>
                            <p className='slider_bottom_block_2_desc_dark'>By using us, get the benefits that make it easier for you in the conference for your convenience and the participants</p>

                            <div className="mt-5">
                                <div className="d-flex align_center mt-2 pt-1">
                                    <Icon icon="simple-line-icons:check" fontSize={18} className='color_blue' />
                                    <span className='slider_bottom_block_2_desc_dark ms-3'>Security & Privacy</span>
                                </div>
                                <div className="d-flex align_center mt-2 pt-1">
                                    <Icon icon="simple-line-icons:check" fontSize={18} className='color_blue' />
                                    <span className='slider_bottom_block_2_desc_dark ms-3'>Audience Q&A</span>
                                </div>
                                <div className="d-flex align_center mt-2 pt-1">
                                    <Icon icon="simple-line-icons:check" fontSize={18} className='color_blue' />
                                    <span className='slider_bottom_block_2_desc_dark ms-3'>Engagement</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='py-5'>
                <div className="slider_bottom_wrapper">
                    <div className="text-center mb-3">
                        <img src={dp} className='founder_pic' alt="" />
                    </div>
                    <div className='login_title text-center' style={{ fontSize: '20px', lineHeight: '15px' }}>Vivek Vyas</div>
                    <p className='login_label text-center'>Founder & CEO, Officiel Meet</p>

                    <h2 className='login_title mb-4 text-center'>
                        <span className='color_blue'>"</span>  Our mission is to empower <br /> productivity, foster meaningful <br /> connections, and redefine the <br /> way teams communicate in <br /> the digital age. <span className='color_blue'>"</span>
                    </h2>
                </div>
            </div>

            <div className='pb-5'>
                <div className="slider_bottom_wrapper bg_blue home_bottom_wrapper">
                    <div className="myrow justify_space_evenly align_center">
                        <div className="slider_bottom_block_1">
                            <h2 className='login_title mb-2 color_white'>Ready to setup your <br /> next officiel meeting?</h2>
                            <div className='login_label color_offwhite'>"Coffee's brewing, minds are buzzing - OfficielMeet, let's get official!"</div>
                        </div>
                        <div className="slider_bottom_block_2 text-center">
                            <div className="">
                                <div>
                                    <Link to={Path.REGISTER}>
                                        <button className="my_button m-2 bg_dark color_white">
                                            Get started
                                        </button>
                                    </Link>
                                    <Link to={Path.LOGIN}>
                                        <button className="my_button m-2">
                                            Create meeting
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

            {/* //////////////////////Join Meeting ///////////////////////// */}
            <div className="modal fade" id="joinMeeting" tabIndex="-1" aria-labelledby="joinMeetingLabel" aria-hidden="true">
                <JoinMeeting />
            </div>
        </>
    )
}

export default LandingPage

