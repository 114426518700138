import React from 'react'
import { Routes, Route } from 'react-router-dom';
import { Path } from './Path';
import LandingPage from '../Pages/LandingPage/LandingPage';
import Login from '../Pages/Login/Login';
import Register from '../Pages/Register/Register';
import ForgotPassword from './../Pages/Login/ForgotPassword';
import Main from '../Pages/Main/Main';

export const AuthRoutes = () => {
    return (
        <>
            <Routes>
                <Route path={'/'} element={<LandingPage />} />
                <Route path={Path.LOGIN} element={<Login />} />
                <Route path={Path.REGISTER} element={<Register />} />
                <Route path={Path.FORGOTPASSWORD} element={<ForgotPassword />} />
                <Route path={Path.MEETING + '/:unique_code'} element={<Main />} />
            </Routes>
        </>
    )
}