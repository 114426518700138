import toast from "react-hot-toast"

const DEBUG = false

export const flagUrl = "https://flagsapi.com"
export const baseUrl = DEBUG ? 'http://localhost:3000/' : "https://officielmeet.com/"
export const janusUrl = DEBUG && !DEBUG ? 'http://20.198.22.73:8088/janus' : "https://link.officielmeet.com/janus"
export const url = DEBUG ? 'http://127.0.0.1:8000/' : "https://officielmeet.com:8080/"
export const socketUrl = DEBUG ? 'ws://127.0.0.1:8000/ws/' : "wss://officielmeet.com:8080/ws/"
export const fileUrl = DEBUG ? 'http://127.0.0.1:8000' : "https://officielmeet.com:8080"
export const mediaUrl = DEBUG ? 'http://127.0.0.1:8000/media/' : "https://officielmeet.com:8080/media/"

// export const url = DEBUG? 'http://127.0.0.1:8000/':"http://20.205.128.64:8000/"
// export const socketUrl = DEBUG? 'ws://127.0.0.1:8000/ws/':"ws://20.205.128.64:8000/ws/"
// export const fileUrl = DEBUG? 'http://127.0.0.1:8000':"http://20.205.128.64:8000"
// export const mediaUrl = DEBUG? 'http://127.0.0.1:8000/media/':"http://20.205.128.64:8000/media/"

export const CLIENT_ID = "1029278532850-jm7nsm05eo6s6vsbja6j06ukd7jvbsv7.apps.googleusercontent.com"
export const CLIENT_SECRET = "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"

export const MsgType = {
    'NEWMSG': 'NEWMSG',
    'AUDIOTOGGLE': 'AUDIOTOGGLE',
    'VIDEOTOGGLE': 'VIDEOTOGGLE',
    'RECEIVEMSG': 'RECEIVEMSG',
    'CHATHISTORY': 'CHATHISTORY',
    'NEWUSER': 'NEWUSER',
    'EXISTINGPARTICIPANT': 'EXISTINGPARTICIPANT',
    'REMOVEUSER': 'REMOVEUSER',
    'ALLUSERS': 'ALLUSERS',
    'USERJOINED': 'USERJOINED',
    'ENDMEETINGFORALL': 'ENDMEETINGFORALL',
    'FORCEDREMOVEUSER': 'FORCEDREMOVEUSER',
    'FORCEDMUTEUSER': 'FORCEDMUTEUSER',
}

export const handleCopy = (d) => {
    navigator.clipboard.writeText(d)
    toast('Meeting details copied.',
        {
            icon: '👍', position: "bottom-left",
            style: {
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
            },
        }
    );
}

export const countries = [
    { "name": "Afghanistan", "code": "AF" },
    { "name": "land Islands", "code": "AX" },
    { "name": "Albania", "code": "AL" },
    { "name": "Algeria", "code": "DZ" },
    { "name": "American Samoa", "code": "AS" },
    { "name": "AndorrA", "code": "AD" },
    { "name": "Angola", "code": "AO" },
    { "name": "Anguilla", "code": "AI" },
    { "name": "Antarctica", "code": "AQ" },
    { "name": "Antigua and Barbuda", "code": "AG" },
    { "name": "Argentina", "code": "AR" },
    { "name": "Armenia", "code": "AM" },
    { "name": "Aruba", "code": "AW" },
    { "name": "Australia", "code": "AU" },
    { "name": "Austria", "code": "AT" },
    { "name": "Azerbaijan", "code": "AZ" },
    { "name": "Bahamas", "code": "BS" },
    { "name": "Bahrain", "code": "BH" },
    { "name": "Bangladesh", "code": "BD" },
    { "name": "Barbados", "code": "BB" },
    { "name": "Belarus", "code": "BY" },
    { "name": "Belgium", "code": "BE" },
    { "name": "Belize", "code": "BZ" },
    { "name": "Benin", "code": "BJ" },
    { "name": "Bermuda", "code": "BM" },
    { "name": "Bhutan", "code": "BT" },
    { "name": "Bolivia", "code": "BO" },
    { "name": "Bosnia and Herzegovina", "code": "BA" },
    { "name": "Botswana", "code": "BW" },
    { "name": "Bouvet Island", "code": "BV" },
    { "name": "Brazil", "code": "BR" },
    { "name": "British Indian Ocean Territory", "code": "IO" },
    { "name": "Brunei Darussalam", "code": "BN" },
    { "name": "Bulgaria", "code": "BG" },
    { "name": "Burkina Faso", "code": "BF" },
    { "name": "Burundi", "code": "BI" },
    { "name": "Cambodia", "code": "KH" },
    { "name": "Cameroon", "code": "CM" },
    { "name": "Canada", "code": "CA" },
    { "name": "Cape Verde", "code": "CV" },
    { "name": "Cayman Islands", "code": "KY" },
    { "name": "Central African Republic", "code": "CF" },
    { "name": "Chad", "code": "TD" },
    { "name": "Chile", "code": "CL" },
    { "name": "China", "code": "CN" },
    { "name": "Christmas Island", "code": "CX" },
    { "name": "Cocos (Keeling) Islands", "code": "CC" },
    { "name": "Colombia", "code": "CO" },
    { "name": "Comoros", "code": "KM" },
    { "name": "Congo", "code": "CG" },
    { "name": "Congo, The Democratic Republic of the", "code": "CD" },
    { "name": "Cook Islands", "code": "CK" },
    { "name": "Costa Rica", "code": "CR" },
    { "name": "Cote D'Ivoire", "code": "CI" },
    { "name": "Croatia", "code": "HR" },
    { "name": "Cuba", "code": "CU" },
    { "name": "Cyprus", "code": "CY" },
    { "name": "Czech Republic", "code": "CZ" },
    { "name": "Denmark", "code": "DK" },
    { "name": "Djibouti", "code": "DJ" },
    { "name": "Dominica", "code": "DM" },
    { "name": "Dominican Republic", "code": "DO" },
    { "name": "Ecuador", "code": "EC" },
    { "name": "Egypt", "code": "EG" },
    { "name": "El Salvador", "code": "SV" },
    { "name": "Equatorial Guinea", "code": "GQ" },
    { "name": "Eritrea", "code": "ER" },
    { "name": "Estonia", "code": "EE" },
    { "name": "Ethiopia", "code": "ET" },
    { "name": "Falkland Islands (Malvinas)", "code": "FK" },
    { "name": "Faroe Islands", "code": "FO" },
    { "name": "Fiji", "code": "FJ" },
    { "name": "Finland", "code": "FI" },
    { "name": "France", "code": "FR" },
    { "name": "French Guiana", "code": "GF" },
    { "name": "French Polynesia", "code": "PF" },
    { "name": "French Southern Territories", "code": "TF" },
    { "name": "Gabon", "code": "GA" },
    { "name": "Gambia", "code": "GM" },
    { "name": "Georgia", "code": "GE" },
    { "name": "Germany", "code": "DE" },
    { "name": "Ghana", "code": "GH" },
    { "name": "Gibraltar", "code": "GI" },
    { "name": "Greece", "code": "GR" },
    { "name": "Greenland", "code": "GL" },
    { "name": "Grenada", "code": "GD" },
    { "name": "Guadeloupe", "code": "GP" },
    { "name": "Guam", "code": "GU" },
    { "name": "Guatemala", "code": "GT" },
    { "name": "Guernsey", "code": "GG" },
    { "name": "Guinea", "code": "GN" },
    { "name": "Guinea-Bissau", "code": "GW" },
    { "name": "Guyana", "code": "GY" },
    { "name": "Haiti", "code": "HT" },
    { "name": "Heard Island and Mcdonald Islands", "code": "HM" },
    { "name": "Holy See (Vatican City State)", "code": "VA" },
    { "name": "Honduras", "code": "HN" },
    { "name": "Hong Kong", "code": "HK" },
    { "name": "Hungary", "code": "HU" },
    { "name": "Iceland", "code": "IS" },
    { "name": "India", "code": "IN" },
    { "name": "Indonesia", "code": "ID" },
    { "name": "Iran, Islamic Republic Of", "code": "IR" },
    { "name": "Iraq", "code": "IQ" },
    { "name": "Ireland", "code": "IE" },
    { "name": "Isle of Man", "code": "IM" },
    { "name": "Israel", "code": "IL" },
    { "name": "Italy", "code": "IT" },
    { "name": "Jamaica", "code": "JM" },
    { "name": "Japan", "code": "JP" },
    { "name": "Jersey", "code": "JE" },
    { "name": "Jordan", "code": "JO" },
    { "name": "Kazakhstan", "code": "KZ" },
    { "name": "Kenya", "code": "KE" },
    { "name": "Kiribati", "code": "KI" },
    { "name": "Korea, Democratic People'S Republic of", "code": "KP" },
    { "name": "Korea, Republic of", "code": "KR" },
    { "name": "Kuwait", "code": "KW" },
    { "name": "Kyrgyzstan", "code": "KG" },
    { "name": "Lao People'S Democratic Republic", "code": "LA" },
    { "name": "Latvia", "code": "LV" },
    { "name": "Lebanon", "code": "LB" },
    { "name": "Lesotho", "code": "LS" },
    { "name": "Liberia", "code": "LR" },
    { "name": "Libyan Arab Jamahiriya", "code": "LY" },
    { "name": "Liechtenstein", "code": "LI" },
    { "name": "Lithuania", "code": "LT" },
    { "name": "Luxembourg", "code": "LU" },
    { "name": "Macao", "code": "MO" },
    { "name": "Macedonia, The Former Yugoslav Republic of", "code": "MK" },
    { "name": "Madagascar", "code": "MG" },
    { "name": "Malawi", "code": "MW" },
    { "name": "Malaysia", "code": "MY" },
    { "name": "Maldives", "code": "MV" },
    { "name": "Mali", "code": "ML" },
    { "name": "Malta", "code": "MT" },
    { "name": "Marshall Islands", "code": "MH" },
    { "name": "Martinique", "code": "MQ" },
    { "name": "Mauritania", "code": "MR" },
    { "name": "Mauritius", "code": "MU" },
    { "name": "Mayotte", "code": "YT" },
    { "name": "Mexico", "code": "MX" },
    { "name": "Micronesia, Federated States of", "code": "FM" },
    { "name": "Moldova, Republic of", "code": "MD" },
    { "name": "Monaco", "code": "MC" },
    { "name": "Mongolia", "code": "MN" },
    { "name": "Montenegro", "code": "ME" },
    { "name": "Montserrat", "code": "MS" },
    { "name": "Morocco", "code": "MA" },
    { "name": "Mozambique", "code": "MZ" },
    { "name": "Myanmar", "code": "MM" },
    { "name": "Namibia", "code": "NA" },
    { "name": "Nauru", "code": "NR" },
    { "name": "Nepal", "code": "NP" },
    { "name": "Netherlands", "code": "NL" },
    { "name": "Netherlands Antilles", "code": "AN" },
    { "name": "New Caledonia", "code": "NC" },
    { "name": "New Zealand", "code": "NZ" },
    { "name": "Nicaragua", "code": "NI" },
    { "name": "Niger", "code": "NE" },
    { "name": "Nigeria", "code": "NG" },
    { "name": "Niue", "code": "NU" },
    { "name": "Norfolk Island", "code": "NF" },
    { "name": "Northern Mariana Islands", "code": "MP" },
    { "name": "Norway", "code": "NO" },
    { "name": "Oman", "code": "OM" },
    { "name": "Pakistan", "code": "PK" },
    { "name": "Palau", "code": "PW" },
    { "name": "Palestinian Territory, Occupied", "code": "PS" },
    { "name": "Panama", "code": "PA" },
    { "name": "Papua New Guinea", "code": "PG" },
    { "name": "Paraguay", "code": "PY" },
    { "name": "Peru", "code": "PE" },
    { "name": "Philippines", "code": "PH" },
    { "name": "Pitcairn", "code": "PN" },
    { "name": "Poland", "code": "PL" },
    { "name": "Portugal", "code": "PT" },
    { "name": "Puerto Rico", "code": "PR" },
    { "name": "Qatar", "code": "QA" },
    { "name": "Reunion", "code": "RE" },
    { "name": "Romania", "code": "RO" },
    { "name": "Russian Federation", "code": "RU" },
    { "name": "RWANDA", "code": "RW" },
    { "name": "Saint Helena", "code": "SH" },
    { "name": "Saint Kitts and Nevis", "code": "KN" },
    { "name": "Saint Lucia", "code": "LC" },
    { "name": "Saint Pierre and Miquelon", "code": "PM" },
    { "name": "Saint Vincent and the Grenadines", "code": "VC" },
    { "name": "Samoa", "code": "WS" },
    { "name": "San Marino", "code": "SM" },
    { "name": "Sao Tome and Principe", "code": "ST" },
    { "name": "Saudi Arabia", "code": "SA" },
    { "name": "Senegal", "code": "SN" },
    { "name": "Serbia", "code": "RS" },
    { "name": "Seychelles", "code": "SC" },
    { "name": "Sierra Leone", "code": "SL" },
    { "name": "Singapore", "code": "SG" },
    { "name": "Slovakia", "code": "SK" },
    { "name": "Slovenia", "code": "SI" },
    { "name": "Solomon Islands", "code": "SB" },
    { "name": "Somalia", "code": "SO" },
    { "name": "South Africa", "code": "ZA" },
    { "name": "South Georgia and the South Sandwich Islands", "code": "GS" },
    { "name": "Spain", "code": "ES" },
    { "name": "Sri Lanka", "code": "LK" },
    { "name": "Sudan", "code": "SD" },
    { "name": "Suriname", "code": "SR" },
    { "name": "Svalbard and Jan Mayen", "code": "SJ" },
    { "name": "Swaziland", "code": "SZ" },
    { "name": "Sweden", "code": "SE" },
    { "name": "Switzerland", "code": "CH" },
    { "name": "Syrian Arab Republic", "code": "SY" },
    { "name": "Taiwan, Province of China", "code": "TW" },
    { "name": "Tajikistan", "code": "TJ" },
    { "name": "Tanzania, United Republic of", "code": "TZ" },
    { "name": "Thailand", "code": "TH" },
    { "name": "Timor-Leste", "code": "TL" },
    { "name": "Togo", "code": "TG" },
    { "name": "Tokelau", "code": "TK" },
    { "name": "Tonga", "code": "TO" },
    { "name": "Trinidad and Tobago", "code": "TT" },
    { "name": "Tunisia", "code": "TN" },
    { "name": "Turkey", "code": "TR" },
    { "name": "Turkmenistan", "code": "TM" },
    { "name": "Turks and Caicos Islands", "code": "TC" },
    { "name": "Tuvalu", "code": "TV" },
    { "name": "Uganda", "code": "UG" },
    { "name": "Ukraine", "code": "UA" },
    { "name": "United Arab Emirates", "code": "AE" },
    { "name": "United Kingdom", "code": "GB" },
    { "name": "United States", "code": "US" },
    { "name": "United States Minor Outlying Islands", "code": "UM" },
    { "name": "Uruguay", "code": "UY" },
    { "name": "Uzbekistan", "code": "UZ" },
    { "name": "Vanuatu", "code": "VU" },
    { "name": "Venezuela", "code": "VE" },
    { "name": "Viet Nam", "code": "VN" },
    { "name": "Virgin Islands, British", "code": "VG" },
    { "name": "Virgin Islands, U.S.", "code": "VI" },
    { "name": "Wallis and Futuna", "code": "WF" },
    { "name": "Western Sahara", "code": "EH" },
    { "name": "Yemen", "code": "YE" },
    { "name": "Zambia", "code": "ZM" },
    { "name": "Zimbabwe", "code": "ZW" }
]

export const countriesTimezone = [
    { "name": "Afghanistan", "code": "AF", "timezone": ["Asia/Kabul"] },
    { "name": "land Islands", "code": "AX", "timezone": ["Europe/Mariehamn"] },
    { "name": "Albania", "code": "AL", "timezone": ["Europe/Tirane"] },
    { "name": "Algeria", "code": "DZ", "timezone": ["Africa/Algiers"] },
    { "name": "American Samoa", "code": "AS", "timezone": ["Pacific/Pago_Pago"] },
    { "name": "AndorrA", "code": "AD", "timezone": ["Europe/Andorra"] },
    { "name": "Angola", "code": "AO", "timezone": ["Africa/Luanda"] },
    { "name": "Anguilla", "code": "AI", "timezone": ["America/Anguilla"] },
    { "name": "Antarctica", "code": "AQ", "timezone": ["Antarctica/Casey", "Antarctica/Davis", "Antarctica/DumontDUrville", "Antarctica/Mawson", "Antarctica/Palmer", "Antarctica/Rothera", "Antarctica/Syowa", "Antarctica/Troll", "Antarctica/Vostok"] },
    { "name": "Antigua and Barbuda", "code": "AG", "timezone": ["America/Antigua"] },
    { "name": "Argentina", "code": "AR", "timezone": ["America/Argentina/Buenos_Aires", "America/Argentina/Cordoba", "America/Argentina/Salta", "America/Argentina/Jujuy", "America/Argentina/Tucuman", "America/Argentina/Catamarca", "America/Argentina/La_Rioja", "America/Argentina/San_Juan", "America/Argentina/Mendoza", "America/Argentina/San_Luis", "America/Argentina/Rio_Gallegos", "America/Argentina/Ushuaia"] },
    { "name": "Armenia", "code": "AM", "timezone": ["Asia/Yerevan"] },
    { "name": "Aruba", "code": "AW", "timezone": ["America/Aruba"] },
    { "name": "Australia", "code": "AU", "timezone": ["Australia/Lord_Howe", "Antarctica/Macquarie", "Australia/Hobart", "Australia/Currie", "Australia/Melbourne", "Australia/Sydney", "Australia/Broken_Hill", "Australia/Brisbane", "Australia/Lindeman", "Australia/Adelaide", "Australia/Darwin", "Australia/Perth"] },
    { "name": "Austria", "code": "AT", "timezone": ["Europe/Vienna"] },
    { "name": "Azerbaijan", "code": "AZ", "timezone": ["Asia/Baku"] },
    { "name": "Bahamas", "code": "BS", "timezone": ["America/Nassau"] },
    { "name": "Bahrain", "code": "BH", "timezone": ["Asia/Bahrain"] },
    { "name": "Bangladesh", "code": "BD", "timezone": ["Asia/Dhaka"] },
    { "name": "Barbados", "code": "BB", "timezone": ["America/Barbados"] },
    { "name": "Belarus", "code": "BY", "timezone": ["Europe/Minsk"] },
    { "name": "Belgium", "code": "BE", "timezone": ["Europe/Brussels"] },
    { "name": "Belize", "code": "BZ", "timezone": ["America/Belize"] },
    { "name": "Benin", "code": "BJ", "timezone": ["Africa/Porto-Novo"] },
    { "name": "Bermuda", "code": "BM", "timezone": ["Atlantic/Bermuda"] },
    { "name": "Bhutan", "code": "BT", "timezone": ["Asia/Thimphu"] },
    { "name": "Bolivia", "code": "BO", "timezone": ["America/La_Paz"] },
    { "name": "Bosnia and Herzegovina", "code": "BA", "timezone": ["Europe/Sarajevo"] },
    { "name": "Botswana", "code": "BW", "timezone": ["Africa/Gaborone"] },
    { "name": "Bouvet Island", "code": "BV", "timezone": [""] },
    { "name": "Brazil", "code": "BR", "timezone": ["America/Noronha", "America/Belem", "America/Fortaleza", "America/Recife", "America/Araguaina", "America/Maceio", "America/Bahia", "America/Sao_Paulo", "America/Campo_Grande", "America/Cuiaba", "America/Santarem", "America/Porto_Velho", "America/Boa_Vista", "America/Manaus", "America/Eirunepe", "America/Rio_Branco"] },
    { "name": "British Indian Ocean Territory", "code": "IO", "timezone": ["Indian/Chagos"] },
    { "name": "Brunei Darussalam", "code": "BN", "timezone": ["Asia/Brunei"] },
    { "name": "Bulgaria", "code": "BG", "timezone": ["Europe/Sofia"] },
    { "name": "Burkina Faso", "code": "BF", "timezone": ["Africa/Ouagadougou"] },
    { "name": "Burundi", "code": "BI", "timezone": ["Africa/Bujumbura"] },
    { "name": "Cambodia", "code": "KH", "timezone": ["Asia/Phnom_Penh"] },
    { "name": "Cameroon", "code": "CM", "timezone": ["Africa/Douala"] },
    { "name": "Canada", "code": "CA", "timezone": ["America/St_Johns", "America/Halifax", "America/Glace_Bay", "America/Moncton", "America/Goose_Bay", "America/Blanc-Sablon", "America/Toronto", "America/Nipigon", "America/Thunder_Bay", "America/Iqaluit", "America/Pangnirtung", "America/Atikokan", "America/Winnipeg", "America/Rainy_River", "America/Resolute", "America/Rankin_Inlet", "America/Regina", "America/Swift_Current", "America/Edmonton", "America/Cambridge_Bay", "America/Yellowknife", "America/Inuvik", "America/Creston", "America/Dawson_Creek", "America/Fort_Nelson", "America/Vancouver", "America/Whitehorse", "America/Dawson"] },
    { "name": "Cape Verde", "code": "CV", "timezone": ["Atlantic/Cape_Verde"] },
    { "name": "Cayman Islands", "code": "KY", "timezone": ["America/Cayman"] },
    { "name": "Central African Republic", "code": "CF", "timezone": ["Africa/Bangui"] },
    { "name": "Chad", "code": "TD", "timezone": ["Africa/Ndjamena"] },
    { "name": "Chile", "code": "CL", "timezone": ["America/Santiago", "Pacific/Easter"] },
    { "name": "China", "code": "CN", "timezone": ["Asia/Shanghai", "Asia/Harbin", "Asia/Chongqing", "Asia/Urumqi", "Asia/Kashgar"] },
    { "name": "Christmas Island", "code": "CX", "timezone": ["Indian/Christmas"] },
    { "name": "Cocos (Keeling) Islands", "code": "CC", "timezone": ["Indian/Cocos"] },
    { "name": "Colombia", "code": "CO", "timezone": ["America/Bogota"] },
    { "name": "Comoros", "code": "KM", "timezone": ["Indian/Comoro"] },
    { "name": "Congo", "code": "CG", "timezone": ["Africa/Brazzaville"] },
    { "name": "Congo, The Democratic Republic of the", "code": "CD", "timezone": ["Africa/Kinshasa"] },
    { "name": "Cook Islands", "code": "CK", "timezone": ["Pacific/Rarotonga"] },
    { "name": "Costa Rica", "code": "CR", "timezone": ["America/Costa_Rica"] },
    { "name": "Cote D'Ivoire", "code": "CI", "timezone": ["Africa/Abidjan"] },
    { "name": "Croatia", "code": "HR", "timezone": ["Europe/Zagreb"] },
    { "name": "Cuba", "code": "CU", "timezone": ["America/Havana"] },
    { "name": "Cyprus", "code": "CY", "timezone": ["Asia/Nicosia", "Asia/Famagusta"] },
    { "name": "Czech Republic", "code": "CZ", "timezone": ["Europe/Prague"] },
    { "name": "Denmark", "code": "DK", "timezone": ["Europe/Copenhagen"] },
    { "name": "Djibouti", "code": "DJ", "timezone": ["Africa/Djibouti"] },
    { "name": "Dominica", "code": "DM", "timezone": ["America/Dominica"] },
    { "name": "Dominican Republic", "code": "DO", "timezone": ["America/Santo_Domingo"] },
    { "name": "Ecuador", "code": "EC", "timezone": ["America/Guayaquil", "Pacific/Galapagos"] },
    { "name": "Egypt", "code": "EG", "timezone": ["Africa/Cairo"] },
    { "name": "El Salvador", "code": "SV", "timezone": ["America/El_Salvador"] },
    { "name": "Equatorial Guinea", "code": "GQ", "timezone": ["Africa/Malabo"] },
    { "name": "Eritrea", "code": "ER", "timezone": ["Africa/Asmara"] },
    { "name": "Estonia", "code": "EE", "timezone": ["Europe/Tallinn"] },
    { "name": "Ethiopia", "code": "ET", "timezone": ["Africa/Addis_Ababa"] },
    { "name": "Falkland Islands (Malvinas)", "code": "FK", "timezone": ["Atlantic/Stanley"] },
    { "name": "Faroe Islands", "code": "FO", "timezone": ["Atlantic/Faroe"] },
    { "name": "Fiji", "code": "FJ", "timezone": ["Pacific/Fiji"] },
    { "name": "Finland", "code": "FI", "timezone": ["Europe/Helsinki"] },
    { "name": "France", "code": "FR", "timezone": ["Europe/Paris"] },
    { "name": "French Guiana", "code": "GF", "timezone": ["America/Cayenne"] },
    { "name": "French Polynesia", "code": "PF", "timezone": ["Pacific/Tahiti", "Pacific/Marquesas", "Pacific/Gambier"] },
    { "name": "French Southern Territories", "code": "TF", "timezone": [""] },
    { "name": "Gabon", "code": "GA", "timezone": ["Africa/Libreville"] },
    { "name": "Gambia", "code": "GM", "timezone": ["Africa/Banjul"] },
    { "name": "Georgia", "code": "GE", "timezone": ["Asia/Tbilisi"] },
    { "name": "Germany", "code": "DE", "timezone": ["Europe/Berlin"] },
    { "name": "Ghana", "code": "GH", "timezone": ["Africa/Accra"] },
    { "name": "Gibraltar", "code": "GI", "timezone": ["Europe/Gibraltar"] },
    { "name": "Greece", "code": "GR", "timezone": ["Europe/Athens"] },
    { "name": "Greenland", "code": "GL", "timezone": ["America/Godthab", "America/Danmarkshavn", "America/Scoresbysund", "America/Thule"] },
    { "name": "Grenada", "code": "GD", "timezone": ["America/Grenada"] },
    { "name": "Guadeloupe", "code": "GP", "timezone": ["America/Guadeloupe"] },
    { "name": "Guam", "code": "GU", "timezone": ["Pacific/Guam"] },
    { "name": "Guatemala", "code": "GT", "timezone": ["America/Guatemala"] },
    { "name": "Guernsey", "code": "GG", "timezone": ["Europe/Guernsey"] },
    { "name": "Guinea", "code": "GN", "timezone": ["Africa/Conakry"] },
    { "name": "Guinea-Bissau", "code": "GW", "timezone": ["Africa/Bissau"] },
    { "name": "Guyana", "code": "GY", "timezone": ["America/Guyana"] },
    { "name": "Haiti", "code": "HT", "timezone": ["America/Port-au-Prince"] },
    { "name": "Heard Island and Mcdonald Islands", "code": "HM", "timezone": [""] },
    { "name": "Holy See (Vatican City State)", "code": "VA", "timezone": ["Europe/Vatican"] },
    { "name": "Honduras", "code": "HN", "timezone": ["America/Tegucigalpa"] },
    { "name": "Hong Kong", "code": "HK", "timezone": ["Asia/Hong_Kong"] },
    { "name": "Hungary", "code": "HU", "timezone": ["Europe/Budapest"] },
    { "name": "Iceland", "code": "IS", "timezone": ["Atlantic/Reykjavik"] },
    { "name": "India", "code": "IN", "timezone": ["Asia/Kolkata"] },
    { "name": "Indonesia", "code": "ID", "timezone": ["Asia/Jakarta", "Asia/Pontianak", "Asia/Makassar", "Asia/Jayapura"] },
    { "name": "Iran, Islamic Republic Of", "code": "IR", "timezone": ["Asia/Tehran"] },
    { "name": "Iraq", "code": "IQ", "timezone": ["Asia/Baghdad"] },
    { "name": "Ireland", "code": "IE", "timezone": ["Europe/Dublin"] },
    { "name": "Isle of Man", "code": "IM", "timezone": ["Europe/Isle_of_Man"] },
    { "name": "Israel", "code": "IL", "timezone": ["Asia/Jerusalem"] },
    { "name": "Italy", "code": "IT", "timezone": ["Europe/Rome"] },
    { "name": "Jamaica", "code": "JM", "timezone": ["America/Jamaica"] },
    { "name": "Japan", "code": "JP", "timezone": ["Asia/Tokyo"] },
    { "name": "Jersey", "code": "JE", "timezone": ["Europe/Jersey"] },
    { "name": "Jordan", "code": "JO", "timezone": ["Asia/Amman"] },
    { "name": "Kazakhstan", "code": "KZ", "timezone": ["Asia/Almaty", "Asia/Qyzylorda", "Asia/Aqtobe", "Asia/Aqtau", "Asia/Oral"] },
    { "name": "Kenya", "code": "KE", "timezone": ["Africa/Nairobi"] },
    { "name": "Kiribati", "code": "KI", "timezone": ["Pacific/Tarawa", "Pacific/Enderbury", "Pacific/Kiritimati"] },
    { "name": "Korea, Democratic People'S Republic of", "code": "KP", "timezone": ["Asia/Pyongyang"] },
    { "name": "Korea, Republic of", "code": "KR", "timezone": ["Asia/Seoul"] },
    { "name": "Kuwait", "code": "KW", "timezone": ["Asia/Kuwait"] },
    { "name": "Kyrgyzstan", "code": "KG", "timezone": ["Asia/Bishkek"] },
    { "name": "Lao People'S Democratic Republic", "code": "LA", "timezone": ["Asia/Vientiane"] },
    { "name": "Latvia", "code": "LV", "timezone": ["Europe/Riga"] },
    { "name": "Lebanon", "code": "LB", "timezone": ["Asia/Beirut"] },
    { "name": "Lesotho", "code": "LS", "timezone": ["Africa/Maseru"] },
    { "name": "Liberia", "code": "LR", "timezone": ["Africa/Monrovia"] },
    { "name": "Libyan Arab Jamahiriya", "code": "LY", "timezone": ["Africa/Tripoli"] },
    { "name": "Liechtenstein", "code": "LI", "timezone": ["Europe/Vaduz"] },
    { "name": "Lithuania", "code": "LT", "timezone": ["Europe/Vilnius"] },
    { "name": "Luxembourg", "code": "LU", "timezone": ["Europe/Luxembourg"] },
    { "name": "Macao", "code": "MO", "timezone": ["Asia/Macau"] },
    { "name": "Macedonia, The Former Yugoslav Republic of", "code": "MK", "timezone": ["Europe/Skopje"] },
    { "name": "Madagascar", "code": "MG", "timezone": ["Indian/Antananarivo"] },
    { "name": "Malawi", "code": "MW", "timezone": ["Africa/Blantyre"] },
    { "name": "Malaysia", "code": "MY", "timezone": ["Asia/Kuala_Lumpur", "Asia/Kuching"] },
    { "name": "Maldives", "code": "MV", "timezone": ["Indian/Maldives"] },
    { "name": "Mali", "code": "ML", "timezone": ["Africa/Bamako"] },
    { "name": "Malta", "code": "MT", "timezone": ["Europe/Malta"] },
    { "name": "Marshall Islands", "code": "MH", "timezone": ["Pacific/Majuro", "Pacific/Kwajalein"] },
    { "name": "Martinique", "code": "MQ", "timezone": ["America/Martinique"] },
    { "name": "Mauritania", "code": "MR", "timezone": ["Africa/Nouakchott"] },
    { "name": "Mauritius", "code": "MU", "timezone": ["Indian/Mauritius"] },
    { "name": "Mayotte", "code": "YT", "timezone": ["Indian/Mayotte"] },
    { "name": "Mexico", "code": "MX", "timezone": ["America/Mexico_City", "America/Cancun", "America/Merida", "America/Monterrey", "America/Mazatlan", "America/Chihuahua", "America/Hermosillo", "America/Tijuana"] },
    { "name": "Micronesia, Federated States of", "code": "FM", "timezone": ["Pacific/Chuuk", "Pacific/Pohnpei", "Pacific/Kosrae"] },
    { "name": "Moldova, Republic of", "code": "MD", "timezone": ["Europe/Chisinau"] },
    { "name": "Monaco", "code": "MC", "timezone": ["Europe/Monaco"] },
    { "name": "Mongolia", "code": "MN", "timezone": ["Asia/Ulaanbaatar", "Asia/Hovd", "Asia/Choibalsan"] },
    { "name": "Montenegro", "code": "ME", "timezone": ["Europe/Podgorica"] },
    { "name": "Montserrat", "code": "MS", "timezone": ["America/Montserrat"] },
    { "name": "Morocco", "code": "MA", "timezone": ["Africa/Casablanca"] },
    { "name": "Mozambique", "code": "MZ", "timezone": ["Africa/Maputo"] },
    { "name": "Myanmar", "code": "MM", "timezone": ["Asia/Yangon"] },
    { "name": "Namibia", "code": "NA", "timezone": ["Africa/Windhoek"] },
    { "name": "Nauru", "code": "NR", "timezone": ["Pacific/Nauru"] },
    { "name": "Nepal", "code": "NP", "timezone": ["Asia/Kathmandu"] },
    { "name": "Netherlands", "code": "NL", "timezone": ["Europe/Amsterdam"] },
    { "name": "Netherlands Antilles", "code": "AN", "timezone": ["America/Curacao"] },
    { "name": "New Caledonia", "code": "NC", "timezone": ["Pacific/Noumea"] },
    { "name": "New Zealand", "code": "NZ", "timezone": ["Pacific/Auckland", "Pacific/Chatham"] },
    { "name": "Nicaragua", "code": "NI", "timezone": ["America/Managua"] },
    { "name": "Niger", "code": "NE", "timezone": ["Africa/Niamey"] },
    { "name": "Nigeria", "code": "NG", "timezone": ["Africa/Lagos"] },
    { "name": "Niue", "code": "NU", "timezone": ["Pacific/Niue"] },
    { "name": "Norfolk Island", "code": "NF", "timezone": ["Pacific/Norfolk"] },
    { "name": "Northern Mariana Islands", "code": "MP", "timezone": ["Pacific/Saipan"] },
    { "name": "Norway", "code": "NO", "timezone": ["Europe/Oslo"] },
    { "name": "Oman", "code": "OM", "timezone": ["Asia/Muscat"] },
    { "name": "Pakistan", "code": "PK", "timezone": ["Asia/Karachi"] },
    { "name": "Palau", "code": "PW", "timezone": ["Pacific/Palau"] },
    { "name": "Palestinian Territory, Occupied", "code": "PS", "timezone": ["Asia/Hebron"] },
    { "name": "Panama", "code": "PA", "timezone": ["America/Panama"] },
    { "name": "Papua New Guinea", "code": "PG", "timezone": ["Pacific/Port_Moresby", "Pacific/Bougainville"] },
    { "name": "Paraguay", "code": "PY", "timezone": ["America/Asuncion"] },
    { "name": "Peru", "code": "PE", "timezone": ["America/Lima"] },
    { "name": "Philippines", "code": "PH", "timezone": ["Asia/Manila"] },
    { "name": "Pitcairn", "code": "PN", "timezone": ["Pacific/Pitcairn"] },
    { "name": "Poland", "code": "PL", "timezone": ["Europe/Warsaw"] },
    { "name": "Portugal", "code": "PT", "timezone": ["Europe/Lisbon", "Atlantic/Madeira", "Atlantic/Azores"] },
    { "name": "Puerto Rico", "code": "PR", "timezone": ["America/Puerto_Rico"] },
    { "name": "Qatar", "code": "QA", "timezone": ["Asia/Qatar"] },
    { "name": "Reunion", "code": "RE", "timezone": ["Indian/Reunion"] },
    { "name": "Romania", "code": "RO", "timezone": ["Europe/Bucharest"] },
    { "name": "Russian Federation", "code": "RU", "timezone": ["Europe/Kaliningrad", "Europe/Moscow", "Europe/Simferopol", "Europe/Kirov", "Europe/Astrakhan", "Europe/Volgograd", "Europe/Saratov", "Europe/Ulyanovsk", "Europe/Samara", "Asia/Yekaterinburg", "Asia/Omsk", "Asia/Novosibirsk", "Asia/Barnaul", "Asia/Tomsk", "Asia/Novokuznetsk", "Asia/Krasnoyarsk", "Asia/Irkutsk", "Asia/Chita", "Asia/Yakutsk", "Asia/Khandyga", "Asia/Vladivostok", "Asia/Ust-Nera", "Asia/Magadan", "Asia/Sakhalin", "Asia/Srednekolymsk", "Asia/Kamchatka", "Asia/Anadyr"] },
    { "name": "Rwanda", "code": "RW", "timezone": ["Africa/Kigali"] },
    { "name": "Saint Barthelemy", "code": "BL", "timezone": ["America/St_Barthelemy"] },
    { "name": "Saint Helena, Ascension and Tristan Da Cunha", "code": "SH", "timezone": ["Atlantic/St_Helena"] },
    { "name": "Saint Kitts and Nevis", "code": "KN", "timezone": ["America/St_Kitts"] },
    { "name": "Saint Lucia", "code": "LC", "timezone": ["America/St_Lucia"] },
    { "name": "Saint Martin", "code": "MF", "timezone": ["America/Marigot"] },
    { "name": "Saint Pierre and Miquelon", "code": "PM", "timezone": ["America/Miquelon"] },
    { "name": "Saint Vincent and the Grenadines", "code": "VC", "timezone": ["America/St_Vincent"] },
    { "name": "Samoa", "code": "WS", "timezone": ["Pacific/Apia"] },
    { "name": "San Marino", "code": "SM", "timezone": ["Europe/San_Marino"] },
    { "name": "Sao Tome and Principe", "code": "ST", "timezone": ["Africa/Sao_Tome"] },
    { "name": "Saudi Arabia", "code": "SA", "timezone": ["Asia/Riyadh"] },
    { "name": "Senegal", "code": "SN", "timezone": ["Africa/Dakar"] },
    { "name": "Serbia", "code": "RS", "timezone": ["Europe/Belgrade"] },
    { "name": "Seychelles", "code": "SC", "timezone": ["Indian/Mahe"] },
    { "name": "Sierra Leone", "code": "SL", "timezone": ["Africa/Freetown"] },
    { "name": "Singapore", "code": "SG", "timezone": ["Asia/Singapore"] },
    { "name": "Slovakia", "code": "SK", "timezone": ["Europe/Bratislava"] },
    { "name": "Slovenia", "code": "SI", "timezone": ["Europe/Ljubljana"] },
    { "name": "Solomon Islands", "code": "SB", "timezone": ["Pacific/Guadalcanal"] },
    { "name": "Somalia", "code": "SO", "timezone": ["Africa/Mogadishu"] },
    { "name": "South Africa", "code": "ZA", "timezone": ["Africa/Johannesburg"] },
    { "name": "South Georgia and the South Sandwich Islands", "code": "GS", "timezone": [""] },
    { "name": "Spain", "code": "ES", "timezone": ["Europe/Madrid"] },
    { "name": "Sri Lanka", "code": "LK", "timezone": ["Asia/Colombo"] },
    { "name": "Sudan", "code": "SD", "timezone": ["Africa/Khartoum"] },
    { "name": "Suriname", "code": "SR", "timezone": ["America/Paramaribo"] },
    { "name": "Svalbard and Jan Mayen", "code": "SJ", "timezone": ["Arctic/Longyearbyen"] },
    { "name": "Swaziland", "code": "SZ", "timezone": ["Africa/Mbabane"] },
    { "name": "Sweden", "code": "SE", "timezone": ["Europe/Stockholm"] },
    { "name": "Switzerland", "code": "CH", "timezone": ["Europe/Zurich"] },
    { "name": "Syrian Arab Republic", "code": "SY", "timezone": ["Asia/Damascus"] },
    { "name": "Taiwan, Province of China", "code": "TW", "timezone": ["Asia/Taipei"] },
    { "name": "Tajikistan", "code": "TJ", "timezone": ["Asia/Dushanbe"] },
    { "name": "Tanzania, United Republic of", "code": "TZ", "timezone": ["Africa/Dar_es_Salaam"] },
    { "name": "Thailand", "code": "TH", "timezone": ["Asia/Bangkok"] },
    { "name": "Timor-Leste", "code": "TL", "timezone": ["Asia/Dili"] },
    { "name": "Togo", "code": "TG", "timezone": ["Africa/Lome"] },
    { "name": "Tokelau", "code": "TK", "timezone": ["Pacific/Fakaofo"] },
    { "name": "Tonga", "code": "TO", "timezone": ["Pacific/Tongatapu"] },
    { "name": "Trinidad and Tobago", "code": "TT", "timezone": ["America/Port_of_Spain"] },
    { "name": "Tunisia", "code": "TN", "timezone": ["Africa/Tunis"] },
    { "name": "Turkey", "code": "TR", "timezone": ["Europe/Istanbul"] },
    { "name": "Turkmenistan", "code": "TM", "timezone": ["Asia/Ashgabat"] },
    { "name": "Turks and Caicos Islands", "code": "TC", "timezone": ["America/Grand_Turk"] },
    { "name": "Tuvalu", "code": "TV", "timezone": ["Pacific/Funafuti"] },
    { "name": "Uganda", "code": "UG", "timezone": ["Africa/Kampala"] },
    { "name": "Ukraine", "code": "UA", "timezone": ["Europe/Kiev", "Europe/Uzhgorod", "Europe/Zaporozhye"] },
    { "name": "United Arab Emirates", "code": "AE", "timezone": ["Asia/Dubai"] },
    { "name": "United Kingdom", "code": "GB", "timezone": ["Europe/London"] },
    { "name": "United States", "code": "US", "timezone": ["America/New_York", "America/Detroit", "America/Kentucky/Louisville", "America/Kentucky/Monticello", "America/Indiana/Indianapolis", "America/Indiana/Vincennes", "America/Indiana/Winamac", "America/Indiana/Marengo", "America/Indiana/Petersburg", "America/Indiana/Vevay", "America/Chicago", "America/Indiana/Tell_City", "America/Indiana/Knox", "America/Menominee", "America/North_Dakota/Center", "America/North_Dakota/New_Salem", "America/North_Dakota/Beulah", "America/Denver", "America/Boise", "America/Phoenix", "America/Los_Angeles", "America/Anchorage", "America/Juneau", "America/Sitka", "America/Metlakatla", "America/Yakutat", "America/Nome", "America/Adak", "Pacific/Honolulu"] },
    { "name": "United States Minor Outlying Islands", "code": "UM", "timezone": [""] },
    { "name": "Uruguay", "code": "UY", "timezone": ["America/Montevideo"] },
    { "name": "Uzbekistan", "code": "UZ", "timezone": ["Asia/Samarkand", "Asia/Tashkent"] },
    { "name": "Vanuatu", "code": "VU", "timezone": ["Pacific/Efate"] },
    { "name": "Venezuela, Bolivarian Republic of", "code": "VE", "timezone": ["America/Caracas"] },
    { "name": "Viet Nam", "code": "VN", "timezone": ["Asia/Ho_Chi_Minh"] },
    { "name": "Virgin Islands, British", "code": "VG", "timezone": ["America/Tortola"] },
    { "name": "Virgin Islands, U.S.", "code": "VI", "timezone": ["America/St_Thomas"] },
    { "name": "Wallis and Futuna", "code": "WF", "timezone": ["Pacific/Wallis"] },
    { "name": "Western Sahara", "code": "EH", "timezone": ["Africa/El_Aaiun"] },
    { "name": "Yemen", "code": "YE", "timezone": ["Asia/Aden"] },
    { "name": "Zambia", "code": "ZM", "timezone": ["Africa/Lusaka"] },
    { "name": "Zimbabwe", "code": "ZW", "timezone": ["Africa/Harare"] }
]