
export const Path = {
    SIGNUP: '/sign-up',
    LOGIN: '/login',
    REGISTER: '/create-an-account',
    FORGOTPASSWORD: '/forgot-password',
    HOME: '/',
    USERS: '/users',
    MEETING: '/meeting',
    SCHEDULE: '/schedule',
    SETTINGS: '/settings',
}