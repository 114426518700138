import React from 'react'
import logo from '../../Images/logo.png'
import { flagUrl } from './../../Export';
const Topbar = () => {
    let user = JSON.parse(localStorage.getItem('officiel-user'))
    return (
        <div className="bg_dark color_white main_topbar text-start">
            {/* Officiel<span className='color_blue'>Meet</span>
            <span className='logo-beta color_red'>(BETA)</span> */}
            <div className="myrow justify_space_between">
                <img src={logo} className='logo' alt="" />
                {user &&
                    <div>
                        <img src={`${flagUrl}/${user.country}/flat/64.png`} style={{ width: "30px", height: '30px' }} alt="" />
                    </div>
                }
            </div>
        </div>
    )
}

export default Topbar