import React, { useEffect, useState } from 'react'
import './MeetingCard.css'
import { Avatar, AvatarGroup } from '@mui/material'
import moment from 'moment'
import { Icon } from '@iconify/react'
import { handleCopy, countriesTimezone } from '../../../Export'
import { Toaster } from 'react-hot-toast'
import { Link } from 'react-router-dom'
import { Path } from '../../../Routes/Path'
import 'moment-timezone';
import { useMeetingStore } from '../../../Store/MeetingStore/MeetingStore'
import { useToasts } from 'react-toast-notifications'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Loading from '../../../Loading'

const MeetingCard = (props) => {
    const { addToast } = useToasts()
    let token = JSON.parse(localStorage.getItem('officiel-user'))
    const { data, subUsers, previous } = props
    const { deleteMeeting, fetchScheduleMeeting, fetchPreviousMeeting, setScheduleMeetings, setPreviousMeetings, updateMeeting } = useMeetingStore()
    const [fuser, setFuser] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const findUser = () => {
        let d = subUsers.find((f) => {
            return f.id === data.host_id
        })
        if (d) {
            setFuser(d)
        }
    }
    useEffect(() => {
        findUser()
    }, [data, subUsers])

    function convertToLocalTime(datetimeString) {
        const parsedDateTime = moment(datetimeString, 'YYYY-MM-DD HH:mm:ssZ');
        const date = new Date(parsedDateTime);
        const offset = date.getTimezoneOffset();
        date.setMinutes(date.getMinutes() + offset);
        const tzString = date.toLocaleString();
        const formattedIstDateTime = moment(tzString).format('LLL');
        return formattedIstDateTime;
    }
    function convertToLocalTimeRecurring(datetimeString) {
        const parsedDateTime = moment(datetimeString, 'YYYY-MM-DD HH:mm:ssZ');
        const date = new Date(parsedDateTime);
        const offset = date.getTimezoneOffset();
        date.setMinutes(date.getMinutes() + offset);
        const tzString = date.toLocaleString();
        const formattedIstDateTime = moment(tzString).format('LT');
        return formattedIstDateTime;
    }

    function convertToLocalTime2(datetimeString) {
        const parsedDateTime = moment(datetimeString, 'YYYY-MM-DD HH:mm:ssZ');
        const date = new Date(parsedDateTime);
        const offset = date.getTimezoneOffset();
        date.setMinutes(date.getMinutes() + offset);
        const tzString = date.toLocaleString();
        const formattedIstDateTime = moment(tzString).format('YYYY-MM-DDTHH:mm');
        return formattedIstDateTime;
    }

    let c = `Joining Meeting Details :\n\nDear sir/ma'am,\n\nPlease find below meeting details and link for Joining:\n\nAgenda: ${data.agenda}\n\n${data.desc}\n\nHost: ${fuser ? fuser.name : ''}\n\nMeeting Code: ${data.unique_code}\n\nPassword: ${data.password}\n\nClick below link to direct join\n\nhttps://officielmeet.com/meeting/${data.unique_code}\n\nTime: ${convertToLocalTime(data.schedule_time)}\n\n\nRegards,\n${fuser ? fuser.name : 'Team Officiel'}`

    const handleDelete = () => {
        setIsLoading(true)
        deleteMeeting(data.unique_code)
            .then(res => {
                document.getElementById('userDeleteModalDismiss' + data.id).click()
                addToast('Success...!', {
                    appearance: 'success',
                    autoDismiss: true,
                })
                if (previous) {
                    fetchPreviousMeeting()
                        .then(res => {
                            setIsLoading(false)
                            setPreviousMeetings(res.data)
                        })
                        .catch(err => {
                            setIsLoading(false)
                            console.log(err);
                        })
                } else {
                    fetchScheduleMeeting()
                        .then(res => {
                            setIsLoading(false)
                            setScheduleMeetings(res.data)
                        })
                        .catch(err => {
                            setIsLoading(false)
                            console.log(err);
                        })
                }
            })
            .catch(err => {
                setIsLoading(false)
                err.response.status === 400 ?
                    addToast(err.response.data, {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                    :
                    addToast('Oops ! Something went wrong.', {
                        appearance: 'error',
                        autoDismiss: true,
                    })
            })
    }

    // -----------------Edit Work-----------------------------
    const [usePassowrd, setUsePassowrd] = useState(false)

    const [payload, setPayload] = useState({
        agenda: data.agenda, password: data.password, snooze: data.snooze, screen_sharing: data.screen_sharing, recurring: data.recurring, host: { value: Number(token.id), label: token.email }, desc: data.desc, schedule_time: convertToLocalTime2(data.schedule_time), duration: "15", participants: []
    })

    const [participantOptions, setParticipantOptions] = useState([])
    const [hostOption, setHostOption] = useState([])

    useEffect(() => {
        let l = []
        for (let i = 0; i < subUsers.length; i++) {
            const e = subUsers[i];
            l.push({
                'value': e.email, 'label': e.email
            })
        }
        setParticipantOptions(l)
    }, [subUsers])

    useEffect(() => {
        let l = []
        for (let i = 0; i < subUsers.length; i++) {
            const e = subUsers[i];
            l.push({
                'value': e.id, 'label': e.email
            })
        }
        setHostOption(l)
    }, [subUsers])

    useEffect(() => {
        if (hostOption.length > 0) {
            let d = hostOption.find((f) => f.value === data.host_id)
            if (d) {
                setPayload({ ...payload, host: d })
            }
        }
    }, [hostOption])

    const handleSchedule = () => {
        if (payload.agenda.length > 0 && payload.desc.length > 0 && payload.schedule_time.length > 0 && payload.duration.length > 0 && payload.host) {
            setIsLoading(true)
            updateMeeting(payload, data.unique_code, data.id)
                .then(res => {
                    setIsLoading(false)
                    document.getElementById("scheduleDismiss" + data.id).click()
                    addToast('Success !! Scheduled meeting updated.', {
                        appearance: 'success',
                        autoDismiss: true,
                    })
                    setPayload({
                        ...payload, agenda: payload.agenda, password: payload.password, host: payload.host, desc: payload.desc, schedule_time: payload.schedule_time, duration: payload.duration, snooze: payload.snooze, recurring: payload.recurring, screen_sharing: payload.screen_sharing, participants: []
                    })
                    if (previous) {
                        fetchPreviousMeeting()
                            .then(res => {
                                setIsLoading(false)
                                setPreviousMeetings(res.data)
                            })
                            .catch(err => {
                                setIsLoading(false)
                                console.log(err);
                            })
                    } else {
                        fetchScheduleMeeting()
                            .then(res => {
                                setIsLoading(false)
                                setScheduleMeetings(res.data)
                            })
                            .catch(err => {
                                setIsLoading(false)
                                console.log(err);
                            })
                    }
                })
                .catch(err => {
                    setIsLoading(false)
                    console.log(err);
                    addToast('Error !! Something went wrong...', {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                })
        } else {
            addToast('Error !! All fields required...', {
                appearance: 'error',
                autoDismiss: true,
            })
        }
    }

    useEffect(() => {
        if (data.password.length > 0) {
            setUsePassowrd(true)
        }
    }, [])


    if (fuser) {
        return (
            <>
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />
                <div className="meeting_card myrow align_center justify_space_between">
                    <div className="meetingTitle color_dark px-2 w-100" style={{ fontSize: '13px' }}>
                        <Icon icon={'uil:schedule'} fontSize={16} style={{ marginTop: '-2px' }} /> &nbsp;{!data.recurring ? convertToLocalTime(data.schedule_time) : 'Everyday at ' + convertToLocalTimeRecurring(data.schedule_time)}
                    </div>

                    <div className='my-2 w-50'>
                        <div className="meetingInfo color_dark p-2" style={{ fontSize: '13px', fontFamily: 'inter bold' }}>
                            {data.agenda}
                        </div>
                        <div className="meetingInfo color_secondary px-2">
                            {data.desc}
                        </div>
                    </div>

                    <div className="myrow">
                        <div className='me-2 mb-2'>
                            <Link to={Path.MEETING + '/' + data.unique_code} target='_blank'>
                                <div className="my_button bg_blue color_white" style={{ width: '48%' }}>
                                    Join
                                </div>
                            </Link>
                        </div>
                        <div className='me-2 mb-2'>
                            <div className="my_button bg_meeting color_white" data-bs-toggle="modal" data-bs-target={"#createUserModal" + data.id} style={{ width: '48%' }}>
                                Edit
                            </div>
                        </div>
                        <div className='me-2 mb-2'>
                            <div className="my_button bg_red color_white" data-bs-toggle="modal" data-bs-target={"#deleteUserModal" + data.id} style={{ width: '48%' }}>
                                Delete
                            </div>
                        </div>
                        <div>
                            <div onClick={() => handleCopy(c)} className="my_button bg_dark color_white" style={{ width: '48%' }}>
                                Copy
                            </div>
                        </div>
                    </div>

                </div>

                {isLoading && <Loading />}

                {/* ////////////////////User Update Modal////////////////////////// */}

                <div className="modal fade" id={"createUserModal" + data.id} tabIndex="-1" aria-labelledby="createUserModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content schedule_modal">
                            <div className="schedule_modal_header myrow justify_space_between">
                                <span className="color_blue">Edit Scheduled meeting</span>
                                <span className="color_blue" type="button" data-bs-dismiss="modal"><Icon icon={"ep:close-bold"} fontSize={25} /></span>
                            </div>
                            <div className={"schedule_modal_body"}>

                                <div className="modal_input_wrapper">
                                    <div className="modal_input_left_block">
                                        Meeting Agenda <span className="text-danger">*</span>
                                    </div>
                                    <div className="modal_input_right_block">
                                        <input value={payload.agenda} onChange={(e) => setPayload({ ...payload, agenda: e.target.value })} type="text" className='schedule_modal_input' />
                                    </div>
                                </div>
                                <div className="modal_input_wrapper ">
                                    <div className="modal_input_left_block">
                                    </div>
                                    <div className='text-start myrow align_center' style={{ flexGrow: 0.77 }}>
                                        <input checked={usePassowrd} onChange={(e) => setUsePassowrd(e.target.checked)} className="" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                                        <label className="login_label " type={'button'} htmlFor="flexSwitchCheckDefault">&nbsp; Use Password</label>
                                    </div>
                                </div>
                                {usePassowrd &&
                                    <div className="modal_input_wrapper ">
                                        <div className="modal_input_left_block">
                                            Password <span className="text-danger">*</span>
                                        </div>
                                        <div className="modal_input_right_block">
                                            <input type="text" value={payload.password} onChange={(e) => setPayload({ ...payload, password: e.target.value })} className='schedule_modal_input' />
                                        </div>
                                    </div>
                                }
                                <div className="modal_input_wrapper ">
                                    <div className="modal_input_left_block">
                                        Host <span className="text-danger">*</span>
                                    </div>
                                    <div className="modal_input_right_block">
                                        <Select className='react_select_input' value={payload.host} onChange={(e) => setPayload({ ...payload, host: e })} options={hostOption} />
                                    </div>
                                </div>
                                <div className="modal_input_wrapper ">
                                    <div className="modal_input_left_block">
                                        Add Participant <span className="text-danger"></span>
                                    </div>
                                    <div className="modal_input_right_block">
                                        <CreatableSelect value={payload.participants} closeMenuOnSelect={false} onChange={(e) => setPayload({ ...payload, participants: e })} className='react_select_input' isMulti options={participantOptions} />
                                    </div>
                                </div>
                                <div className="modal_input_wrapper ">
                                    <div className="modal_input_left_block">
                                        Description <span className="text-danger">*</span>
                                    </div>
                                    <div className="modal_input_right_block">
                                        <textarea type="text" value={payload.desc} onChange={(e) => setPayload({ ...payload, desc: e.target.value })} rows={4} cols={22} maxLength="200" className='schedule_modal_input'></textarea>
                                    </div>
                                </div>

                                <hr />

                                <div className="modal_input_wrapper ">
                                    <div className="modal_input_left_block">
                                        Date & Time <span className="text-danger">*</span>
                                    </div>
                                    <div className="modal_input_right_block">
                                        <input type="datetime-local" value={payload.schedule_time} onChange={(e) => setPayload({ ...payload, schedule_time: e.target.value })} className='schedule_modal_input' />
                                    </div>
                                </div>
                                <div className="modal_input_wrapper ">
                                    <div className="modal_input_left_block">
                                        Duration <span className="text-danger">*</span>
                                    </div>
                                    <div className="modal_input_right_block">
                                        <select className='schedule_modal_input' value={payload.duration} onChange={(e) => setPayload({ ...payload, duration: e.target.value })}>
                                            <option value="15">15 minutes</option>
                                            <option value="30">30 minutes</option>
                                            <option value="45">45 minutes</option>
                                            <option value="60">60 minutes</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="modal_input_wrapper ">
                                    <div className="modal_input_left_block">
                                    </div>
                                    <div className='text-start myrow align_center' style={{ flexGrow: 0.70 }}>
                                        <div className="myrow align_center me-3">
                                            <input checked={payload.screen_sharing} onChange={(e) => setPayload({ ...payload, screen_sharing: e.target.checked })} className="" type="checkbox" role="switch" id="flexSwitchCheckDefaultRecurring" />
                                            <label className="login_label " type={'button'} htmlFor="flexSwitchCheckDefaultRecurring">&nbsp;Enable Screen Sharing</label>
                                        </div>
                                        <div className="myrow align_center me-3">
                                            <input checked={payload.recurring} onChange={(e) => setPayload({ ...payload, recurring: e.target.checked })} className="" type="checkbox" role="switch" id="flexSwitchCheckDefaultRecurring" />
                                            <label className="login_label " type={'button'} htmlFor="flexSwitchCheckDefaultRecurring">&nbsp;Recurring meeting</label>
                                        </div>
                                        <div className="myrow align_center">
                                            <input checked={payload.snooze} onChange={(e) => setPayload({ ...payload, snooze: e.target.checked })} className="" type="checkbox" role="switch" id="flexSwitchCheckDefaultSnooze" />
                                            <label className="login_label " type={'button'} htmlFor="flexSwitchCheckDefaultSnooze">&nbsp;Snooze</label>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal_input_wrapper " style={{ justifyContent: 'end' }}>
                                    <button className="my_button bg_dark color_white mx-1" id={'scheduleDismiss' + data.id} data-bs-dismiss="modal">Cancel</button>
                                    <button className="my_button bg_blue color_white mx-1" onClick={handleSchedule}>Save</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* ////////////////////Meeting Delete Modal////////////////////////// */}

                <div className="modal fade" id={"deleteUserModal" + data.id} tabIndex="-1" aria-labelledby="deleteUserModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content schedule_modal">
                            <div className="schedule_modal_header myrow justify_space_between">
                                <span className="color_blue">Are you sure?</span>
                                <span className="color_blue" type="button" data-bs-dismiss="modal"><Icon icon={"ep:close-bold"} fontSize={22} /></span>
                            </div>
                            <div className="schedule_modal_body">
                                <div className='pb-3' style={{ fontSize: '15px' }}>Do you really want to delete this meeting ? You would'nt be able to retrieve this.</div>

                                <div className="modal_input_wrapper align_center" style={{ justifyContent: 'end' }}>
                                    <span className="my_button bg_dark color_white mx-1" id={'userDeleteModalDismiss' + data.id} data-bs-dismiss="modal">Cancel</span>
                                    <button className="my_button bg_red color_white mx-1" onClick={handleDelete}>{isLoading ?
                                        <>
                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                            Please wait...
                                        </> : " Delete "}
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </>
        )
    } else {
        return null
    }
}

export default MeetingCard