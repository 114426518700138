import React, { useEffect, useState } from 'react'
import { useMeetingStore } from '../../Store/MeetingStore/MeetingStore'
import MeetingCard from '../Dashboard/MeetingCard/MeetingCard'
import Loading from './../../Loading';

const Previous = (props) => {
    const { subUsers } = props
    const { previous_meetings, setPreviousMeetings, fetchPreviousMeeting } = useMeetingStore()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)
        fetchPreviousMeeting()
            .then(res => {
                setIsLoading(false)
                setPreviousMeetings(res.data)
            })
            .catch(err => {
                setIsLoading(false)
                console.log(err);
            })
    }, [])
    return (
        <>
            {previous_meetings.length > 0 ?
                previous_meetings.map((m) => {
                    return <MeetingCard data={m} key={m.id} previous={true} subUsers={subUsers} />
                })
                : null}

                {isLoading && <Loading />}
        </>
    )
}

export default Previous