import { Icon } from '@iconify/react'
import React from 'react'
import ScheduleMeeting from '../../Components/ScheduleMeeting/ScheduleMeeting'

const Schedule = () => {
    return (
        <>
            <div>
                <div className="modal-content schedule_modal my-3 bg_secondary" style={{ borderRadius: '16px', overflow: 'hidden' }}>
                    <div className="schedule_modal_header myrow justify_space_between bg_secondary">
                        <span className="color_blue">Schedule Meeting</span>
                    </div>
                    <ScheduleMeeting bg={true} />
                </div>
            </div>
        </>
    )
}

export default Schedule