import React from 'react'
import { Link } from 'react-router-dom'
import { Path } from '../../../Routes/Path'

const NoMeeting = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', alignItems: 'center', justifyContent: 'center' }}>
            <div className="otp_block text-center">
                <h2 className='login_title text-center'>Officiel<span className='color_blue'>Meet</span></h2>
                <h5>Error</h5>
                <p className='login_label'>No meeting room found for this meeting code. Click below to return to home.</p>
                <Link to={Path.HOME} className='color_blue'><u>Return to home</u></Link>
            </div>
        </div>
    )
}

export default NoMeeting