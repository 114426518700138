import React, { useEffect, useState } from 'react'
import './User.css'
import { Icon } from '@iconify/react'
import { useRegisterStore } from './../../Store/AccountStore/RegisterStore/RegisterStore';
import { useUserStore } from '../../Store/AccountStore/UserStore/UserStore';
import { useToasts } from 'react-toast-notifications';
import Loading from './../../Loading';
import UserList from './UserList';
import ReactPaginate from 'react-paginate';

const User = () => {
  const { addToast } = useToasts()
  const [isLoading, setIsLoading] = useState(false)
  const { validateUsers } = useRegisterStore()
  const { subUsers, fetchSubUsers, createSubUsers, setSubUsers, currentUser, license } = useUserStore()
  const [payload, setPayload] = useState({
    name: '', email: '', phone: ''
  })
  const [error, setError] = useState({
    name: '', email: '', phone: ''
  })

  const fetchData = () => {
    setIsLoading(true)
    fetchSubUsers()
      .then(res => {
        setSubUsers(res.data)
        setIsLoading(false)
      })
      .catch(err => {
        setIsLoading(false)
        console.log(err.response.data);
      })
  }

  const handleValidation = () => {
    let formIsValid = true;
    let errors = { ...error }
    if (payload.email.length > 0) {
      if (!payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        formIsValid = false;
        errors.email = 'Please Enter valid Email ID...!'
      } else {
        errors.email = ''
      }
    } else {
      formIsValid = false;
      errors.email = "Email field can't be empty."
    }

    if (payload.name.length > 0) {
      errors.name = ''
    }
    else {
      formIsValid = false;
      errors.name = "Name field can't be empty."
    }

    if (payload.phone.length > 0) {
      errors.phone = ''
    }
    else {
      formIsValid = false;
      errors.phone = "Contact field can't be empty."
    }
    setError(errors)
    return formIsValid
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (handleValidation()) {
      let d = { name: payload.name, contact: payload.phone, email: payload.email }
      setIsLoading(true)
      validateUsers(d)
        .then(res => {
          createSubUsers(d)
            .then(res => {
              document.getElementById('userCreateModalDismiss').click()
              addToast('Success...!', {
                appearance: 'success',
                autoDismiss: true,
              })
              fetchData()
            })
            .catch(err => {
              setIsLoading(false)
              err.response.status === 400 ?
                addToast(err.response.data, {
                  appearance: 'error',
                  autoDismiss: true,
                })
                :
                addToast('Oops ! Something went wrong.', {
                  appearance: 'error',
                  autoDismiss: true,
                })
            })
        })
        .catch(err => {
          setIsLoading(false)
          err.response.status === 400 ?
            addToast(err.response.data, {
              appearance: 'error',
              autoDismiss: true,
            })
            :
            addToast('Oops ! Something went wrong.', {
              appearance: 'error',
              autoDismiss: true,
            })
        })
    }
  }

  useEffect(() => {
    if (subUsers.length === 0) {
      fetchData()
    } else {
      fetchSubUsers()
        .then(res => {
          setSubUsers(res.data)
        })
        .catch(err => {
          console.log(err.response.data);
        })
    }
  }, [])

  // --------------------Pagination Works------------------------------

  // Pagination Work Start

  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [pageCount, setPageCount] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [itemEndSet, setItemEndSet] = useState(0)
  const [itemLength, setitemLength] = useState(0)

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset + 1} to ${endOffset}`);
    setCurrentItems(subUsers.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(subUsers.length / itemsPerPage));
    setItemEndSet(endOffset)
    setitemLength(subUsers.length)

  }, [itemOffset, itemsPerPage, subUsers]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % subUsers.length;
    setItemOffset(newOffset);
  };


  return (
    <>
      <div className="users_wrapper">
        <div className="myrow justify_space_between">
          <div className='dashboard_meetings_title color_dark '>Users</div>
          <div>
            {currentUser.user_type === "0" ?
              license ? license.license === "0" ? null
                : subUsers.length > 99 ? null : <button data-bs-toggle="modal" data-bs-target="#createUserModal" className="my_button bg_blue color_white">Create new</button>
                : null
              : null
            }
          </div>
        </div>

        <div>
          {currentUser.user_type === "0" ?
            license ? license.license === "0" ? <div className="text-danger pt-3 " style={{ fontSize: '13px' }}>You are on free plan you can not create users.</div>
              : license.license === "1" ? subUsers.length > 99 ? <div className="text-danger pt-3 " style={{ fontSize: '12px' }}>You can only create 100 users.</div>
                : null
                : null
              : null
            : null
          }
        </div>

        <div className="table-responsive my-3">
          <table className="table table-nowrap align-middle">
            <thead style={{ backgroundColor: "#F2F6F7" }}>
              <tr>
                <th scope="col" style={{ width: '50px' }}>
                  S.No.
                </th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Phone Number</th>
                <th scope="col">User Type</th>
                {currentUser.user_type === "0" &&
                  <th scope="col">Action</th>
                }
              </tr>
            </thead>
            <tbody>
              {subUsers.length > 0 ?
                currentItems.map((d, c) => {
                  return <UserList data={d} key={d.id} currentUser={currentUser} count={itemOffset + c + 1} isLoading={isLoading} setIsLoading={setIsLoading} fetchData={fetchData} />
                })
                :
                null}
            </tbody>
          </table>
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="pagination-active"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>

      {isLoading && <Loading />}
      {/* ////////////////////Schedule Modal////////////////////////// */}

      <div className="modal fade" id="createUserModal" tabIndex="-1" aria-labelledby="createUserModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content schedule_modal">
            <div className="schedule_modal_header myrow justify_space_between">
              <span className="color_blue">Create User</span>
              <span className="color_blue" type="button" data-bs-dismiss="modal"><Icon icon={"ep:close-bold"} fontSize={22} /></span>
            </div>
            <form autoComplete="off" onSubmit={handleSubmit} className="schedule_modal_body">

              <div className="modal_input_wrapper align_center">
                <div className="modal_input_left_block">
                  Name <span className="text-danger">*</span>
                </div>
                <div className="modal_input_right_block">
                  <input type="text" value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })} className='schedule_modal_input' />
                  {error.name.length > 0 && <div className="text-danger pt-1 " style={{ fontSize: '12px' }}>{error.name}</div>}
                </div>
              </div>

              <div className="modal_input_wrapper align_center">
                <div className="modal_input_left_block">
                  Email <span className="text-danger">*</span>
                </div>
                <div className="modal_input_right_block">
                  <input type="text" value={payload.email} onChange={(e) => setPayload({ ...payload, email: e.target.value })} className='schedule_modal_input' />
                  {error.email.length > 0 && <div className="text-danger pt-1 " style={{ fontSize: '12px' }}>{error.email}</div>}
                </div>
              </div>
              <div className="modal_input_wrapper align_center">
                <div className="modal_input_left_block">
                  Phone Number <span className="text-danger">*</span>
                </div>
                <div className="modal_input_right_block">
                  <input type="text" value={payload.phone} onChange={(e) => setPayload({ ...payload, phone: e.target.value })} className='schedule_modal_input' />
                  {error.phone.length > 0 && <div className="text-danger pt-1 " style={{ fontSize: '12px' }}>{error.phone}</div>}
                </div>
              </div>

              <div className="modal_input_wrapper align_center" style={{ justifyContent: 'end' }}>
                <span className="my_button bg_dark color_white mx-1" id='userCreateModalDismiss' data-bs-dismiss="modal">Cancel</span>
                <button className="my_button bg_blue color_white mx-1" onClick={handleSubmit}>{isLoading ?
                  <>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                    Please wait...
                  </> : " Create "}
                </button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default User