import { Icon } from '@iconify/react'
import { Avatar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { useMeetingStore } from './../../../Store/MeetingStore/MeetingStore';
import { useSocketStore } from '../../../Store/SocketStore/Socket';
import { MsgType } from '../../../Export';

const SmallScreenList = (props) => {
    let user = JSON.parse(localStorage.getItem('officiel-user'))
    const { current_meeting } = useMeetingStore()
    const { state } = useSocketStore()
    const { d, remoteFeeds, remoteAudio, remoteStreams } = props
    const [audioEnabled, setAudioEnabled] = useState(false);
    const [videoEnabled, setVideoEnabled] = useState(false);
    const [myAudioTrack, setMyAudioTrack] = useState()
    const [myVideoTrack, setMyVideoTrack] = useState()
    const [remoteUser, setRemoteUser] = useState(d.display)

    const [isSpeaking, setIsSpeaking] = useState(false);
    const [analyser, setAnalyser] = useState(null);

    useEffect(() => {
        setMyAudioTrack(remoteAudio.find((f) => f.id === d.id))
        setMyVideoTrack(remoteStreams.find((f) => f.id === d.id))
    }, [remoteAudio, remoteFeeds, remoteStreams])

    useEffect(() => {
        if (myVideoTrack) {            
            if (myVideoTrack.videoTrack.muted || !myVideoTrack) {
                setMyVideoTrack()
                setVideoEnabled(false)
            } else {
                setVideoEnabled(true)
            }
        } else {
            setVideoEnabled(false)
        }
    }, [myVideoTrack])

    useEffect(() => {
        if (myAudioTrack) {
            if (myAudioTrack.audioTrack.muted || !myAudioTrack) {
                setAudioEnabled(false)
            } else {
                setAudioEnabled(true)
            }
        } else {
            setAudioEnabled(false)
        }
    }, [myAudioTrack])

    useEffect(() => {
        const audioContext = new AudioContext();

        if (myAudioTrack) {
            const trackAnalyser = audioContext.createAnalyser();
            const bufferLength = trackAnalyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);

            trackAnalyser.fftSize = 256;

            myAudioTrack.audioTrack.onended = () => {
                setIsSpeaking(false);
                trackAnalyser.disconnect();
            };

            const source = audioContext.createMediaStreamSource(myAudioTrack ? new MediaStream([myAudioTrack.audioTrack]) : '');
            source.connect(trackAnalyser);

            const interval = setInterval(() => {
                trackAnalyser.getByteFrequencyData(dataArray);
                const volume = dataArray.reduce((acc, val) => acc + val, 0) / bufferLength;
                setIsSpeaking(volume > 5); // Adjust the threshold as needed
            }, 100); // Check every 100ms

            setAnalyser(trackAnalyser);

            // Cleanup
            return () => {
                clearInterval(interval);
                trackAnalyser.disconnect();
                source.disconnect();
            };
        }

        // Cleanup audio context when component unmounts
        return () => {
            audioContext.close();
        };
    }, [myAudioTrack]);

    const [host, setHost] = useState(false)

    useEffect(() => {
        if (user) {
            if (current_meeting.host_id === user.id) {
                setHost(true)
            } else {
                setHost(false)
            }
        }
    }, [user, current_meeting])

    const handleRemoveUser = () => {
        if (state) {
            state.send(JSON.stringify({ feed: d.id, type: MsgType.FORCEDREMOVEUSER }))
        }
    }
    const handleMuteUser = () => {
        if (state) {
            state.send(JSON.stringify({ feed: d.id, type: MsgType.FORCEDMUTEUSER }))
        }
    }

    return (
        <>
            <div className="smallFrame bg_navy" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', boxShadow: audioEnabled && myAudioTrack ? isSpeaking ? "0 0 0 3px #A4FBA6" : 'none' : 'none', transition: '0.1s' }}>
                {host &&
                    <div className={"color_white"} style={{ top: 0, position: 'absolute', right: 0, padding: '10px' }} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <Icon icon="pepicons-pop:dots-x" fontSize={20} />
                        <ul class="dropdown-menu" style={{ minWidth: '9rem' }}>
                            <div onClick={handleMuteUser} class="dropdown_item myrow align_center"><Icon icon={audioEnabled ? "mage:microphone-mute" : "mage:microphone"} fontSize={15} />&nbsp;&nbsp;&nbsp; <span>{audioEnabled ? 'Mute' : "Unmute"}</span></div>
                            <div onClick={handleRemoveUser} class="dropdown_item myrow align_center"><Icon icon="ep:remove" fontSize={15} />&nbsp;&nbsp;&nbsp; <span>Remove</span></div>
                        </ul>
                    </div>
                }
                {!videoEnabled || !myVideoTrack ?
                    <Avatar style={{ margin: 'auto', backgroundColor: '#029AFF', fontSize: '30px' }} sx={{ width: 60, height: 60 }}>{remoteUser ? remoteUser.name[0] : ''}</Avatar>
                    : <>
                        {myVideoTrack.videoTrack.muted ?
                            <Avatar style={{ margin: 'auto', backgroundColor: '#029AFF', fontSize: '30px' }} sx={{ width: 60, height: 60 }}>{remoteUser ? remoteUser.name[0] : ''}</Avatar>
                            :
                            <ReactPlayer playing={true} width={'100%'} height={"100%"}
                                muted={false} url={new MediaStream([myVideoTrack.videoTrack])} />
                        }
                    </>
                }

                {myAudioTrack && audioEnabled &&
                    <ReactPlayer width={0} playing={true} playsinline={true}
                        muted={false} url={new MediaStream([myAudioTrack.audioTrack])} height={0} />
                }
                <div className="screenName text-center" style={{ fontSize: '11px' }}>{remoteUser ? remoteUser.name : 'Guest'}</div>
                <div className={myAudioTrack ? audioEnabled ? "screenIcon bg_blue" : "screenIcon bg_red" : "screenIcon bg_red"}>
                    {isSpeaking && audioEnabled && myAudioTrack ?
                        <div className="boxAudioContainer">
                            <div className="boxAudio box1"></div>
                            <div className="boxAudio box2"></div>
                            <div className="boxAudio box3"></div>
                            <div className="boxAudio box4"></div>
                            <div className="boxAudio box5"></div>
                        </div>
                        :
                        <Icon icon={myAudioTrack ? audioEnabled ? 'ph:microphone' : "ph:microphone-slash" : "ph:microphone-slash"} fontSize={16} />
                    }
                </div>
            </div>
        </>
    )
}

export default SmallScreenList