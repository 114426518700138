import { Icon } from '@iconify/react'
import React from 'react'

const Loading = () => {
    
  return (
    <div className="overlay">
        <Icon fontSize={50} style={{color:'#029AFF'}} icon="eos-icons:bubble-loading" />
    </div>
  )
}

export default Loading