import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../../Export';

export const useRegisterStore = create((set) => ({
    users: [],
    fetchUsers: async () => {
        let token = await JSON.parse(localStorage.getItem('officiel-user'))
        const response = await axios.get(url + 'auth/view-users/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ users: data });
    },
    createUsers: async (payload) => {
        const response = await axios.post(url + 'auth/create-user/', payload);
        return response
    },
    validateUsers: async (payload) => {
        const response = await axios.post(url + 'auth/validate-user/', payload);
        return response
    },
    sendEmailOtp: async (id) => {
        const response = await axios.get(url + 'auth/email-otp-generate/'+id+'/');
        return response
    },
    sendPhoneOtp: async (id) => {
        const response = await axios.get(url + 'auth/phone-otp-generate/'+id+'/');
        return response
    },
    
}));