import React, { useState, useEffect } from 'react'
import registerBanner from "../../Images/register-banner.png"
import { Icon } from '@iconify/react'
import { Link } from 'react-router-dom'
import { Path } from '../../Routes/Path'
import { useRegisterStore } from '../../Store/AccountStore/RegisterStore/RegisterStore'
import RegisterOtp from './RegisterOtp'
import './Register.css'
import Loading from '../../Loading'
import { CLIENT_ID, countries } from '../../Export'
import { useToasts } from 'react-toast-notifications'
import { useLoginStore } from '../../Store/AccountStore/LoginStore/LoginStore'
import GoogleLoginComponent from '../Login/GoogleLogin'
import logo from '../../Images/logo2.png'

const Register = () => {
  const { addToast } = useToasts()
  const { googleLogin } = useLoginStore()
  const { validateUsers } = useRegisterStore()
  const [isLoading, setIsLoading] = useState(false)
  const [payload, setPayload] = useState({
    email: '', password: '', name: '', contact: '', phone_otp: '', email_otp: '', country: 'IN'
  })
  const [error, setError] = useState({
    email: '', password: '', name: '', contact: '', phone_otp: '', email_otp: ''
  })
  const [rememberCheck, setRememberCheck] = useState(false)

  const handleValidation = () => {
    let formIsValid = true;
    let errors = { ...error }
    if (payload.email.length > 0) {
      if (!payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        formIsValid = false;
        errors.email = 'Please Enter valid Email ID...!'
      } else {
        errors.email = ''
      }
    } else {
      formIsValid = false;
      errors.email = "Email field can't be empty."
    }
    if (payload.password.length > 0) {
      if (!payload.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,20}$/)) {
        formIsValid = false;
        errors.password = 'Password must contain at least one number and one uppercase and lowercase letter and one special character, and at least 6 or more characters'
      } else {
        errors.password = ''
      }
    }
    else {
      formIsValid = false;
      errors.password = "Password field can't be empty."
    }

    if (payload.name.length > 0) {
      errors.name = ''
    }
    else {
      formIsValid = false;
      errors.name = "Name field can't be empty."
    }

    if (payload.contact.length > 0) {
      errors.contact = ''
    }
    else {
      formIsValid = false;
      errors.contact = "Contact field can't be empty."
    }
    setError(errors)
    return formIsValid
  }

  // ----------------------------------Otp Work-------------------------------------

  const [secondStep, setSecondStep] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (handleValidation()) {
      setIsLoading(true)
      validateUsers(payload)
        .then(res => {
          setIsLoading(false)
          setSecondStep(true)
        })
        .catch(err => {
          setIsLoading(false)
          console.log(err);
        })
    }
  }


  return (<>
    {!secondStep ?
      <div className="login_block">
        <div className="login_left_block main_mobile_hide">
          <img src={registerBanner} style={{ height: '100vh', width: '100%', objectFit: 'cover', objectPosition: 'left' }} alt="" />
        </div>
        <div className="login_right_block">
          <div className="login_wrapper ">
            <h2 className=''><img src={logo} className='logo' alt="" /></h2>
            <p className='login_desc m-0'>Create an account!</p>

            <form className="input_login_wrapper" autoComplete="off" onSubmit={handleSubmit}>
              <label htmlFor="" className="login_label">Name</label>
              <input value={payload.name} onChange={(e) => setPayload({ ...payload, name: e.target.value })} type="text" placeholder='Enter name' className="login_input" />
              {error.name.length > 0 && <div className="text-danger text-start" style={{ fontSize: '12px', marginTop: '-5px' }}>{error.name}</div>}

              <label htmlFor="" className="login_label">Email</label>
              <input value={payload.email} autoComplete='off' onChange={(e) => setPayload({ ...payload, email: e.target.value })} type="email" placeholder='Enter email' className="login_input" />
              {error.email.length > 0 && <div className="text-danger text-start" style={{ fontSize: '12px', marginTop: '-5px' }}>{error.email}</div>}

              <label htmlFor="" className="login_label">Phone</label>
              <input value={payload.contact} autoComplete='off' onChange={(e) => setPayload({ ...payload, contact: e.target.value })} type="text" placeholder='Enter contact' className="login_input" />
              {error.contact.length > 0 && <div className="text-danger text-start" style={{ fontSize: '12px', marginTop: '-5px' }}>{error.contact}</div>}

              <label htmlFor="" className="login_label">Password</label>
              <input value={payload.password} autoComplete="new-password" onChange={(e) => setPayload({ ...payload, password: e.target.value })} type={rememberCheck ? "text" : "password"} placeholder='Enter password' className="login_input" />
              {error.password.length > 0 && <div className="text-danger text-start" style={{ fontSize: '12px', marginTop: '-5px' }}>{error.password}</div>}

              <label htmlFor="" className="login_label">Country</label>
              <select value={payload.country} onChange={(e) => setPayload({ ...payload, country: e.target.value })} className="login_input">
                {
                  countries.map((data) => {
                    return <option value={data.code}>{data.name}</option>
                  })
                }
              </select>

              <div className="myrow justify_space_between align_center">
                <div className='form-switch d-flex align_center'>
                  <input value={rememberCheck} autoComplete='off' onChange={(e) => setRememberCheck(e.target.checked)} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                  <label className="login_label " type={'button'} htmlFor="flexSwitchCheckDefault">&nbsp; Show password</label>
                </div>

              </div>

              <div className="my_button bg_blue color_white mt-1" onClick={handleSubmit}>{isLoading ?
                <>
                  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                  Please wait...
                </> : " Create an account"} </div>
              <hr className='my-3' />
              <div className="" style={{ backgroundColor: 'rgb(32, 33, 36)', borderRadius: '4px', paddingBottom: '5px' }}>
                <GoogleLoginComponent />
              </div>
              {/* <div className="my_button bg_dark color_white mt-1"><Icon icon="flat-color-icons:google" fontSize={20} />&nbsp; Continue with Google </div> */}

              {/* <div className="my_button color_blue mt-3" style={{ backgroundColor: 'rgb(242,242,242)' }}>Continue with Officiel Mail </div> */}

              <div className="text-center mt-2">
                <div className="login_label">Already have an account? &nbsp; <label type={'button'} className="color_blue"><Link to={Path.LOGIN}>Login now</Link></label></div>
              </div>
            </form>

          </div>
        </div>
      </div>
      :
      <RegisterOtp payload={payload} isLoading={isLoading} setIsLoading={setIsLoading} setPayload={setPayload} />
    }

    {isLoading && <Loading />}
  </>)
}

export default Register