import React, { useState, useEffect } from 'react'
import './Login.css'
import loginBanner from "../../Images/login-banner.png"
import { Icon } from '@iconify/react'
import { Link } from 'react-router-dom'
import { Path } from '../../Routes/Path'
import { useLoginStore } from '../../Store/AccountStore/LoginStore/LoginStore'
import { useToasts } from 'react-toast-notifications'
import Loading from './../../Loading';
import GoogleLoginComponent from './GoogleLogin'
import logo from '../../Images/logo2.png'

const Login = () => {
  const { addToast } = useToasts()
  const { loginUser, googleLogin } = useLoginStore()
  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [rememberCheck, setRememberCheck] = useState(false)

  const [payload, setPayload] = useState({
    email: localStorage.getItem("officiel_meet-email") || "", password: localStorage.getItem("officiel_meet-password") || ""
  })

  useEffect(() => {
    if (localStorage.getItem("officiel_meet-email") && localStorage.getItem("officiel_meet-password")) {
      setRememberCheck(true)
    }
  }, [])

  const [error, setError] = useState({
    email: '', password: ''
  })

  const handleValidation = () => {
    let formIsValid = true;
    let errors = { ...error }
    if (payload.email.length > 0) {
      if (!payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        formIsValid = false;
        errors.email = 'Please Enter valid Email ID...!'
      } else {
        errors.email = ''
      }
    } else {
      formIsValid = false;
      errors.email = "Email field can't be empty."
    }
    if (payload.password.length > 0) {
      errors.password = ''
    }
    else {
      formIsValid = false;
      errors.password = "Password field can't be empty."
    }
    setError(errors)
    return formIsValid
  }

  const handleLogin = (e) => {
    e.preventDefault()
    if (handleValidation()) {
      setIsLoading(true)
      loginUser(payload)
        .then(res => {
          setIsLoading(false)
          if (rememberCheck) {
            localStorage.setItem("officiel_meet-email", payload.email); localStorage.setItem("officiel_meet-password", payload.password)
          }
          addToast('Success !! Login...', {
            appearance: 'success',
            autoDismiss: true,
          })
          localStorage.setItem('officiel-user', JSON.stringify(res.data))
          document.location.href = "/";
        })
        .catch(err => {
          setIsLoading(false)
          err.response.status === 400 || err.response.status === 401 ?
            addToast(err.response.status == 401 ? err.response.data.detail : err.response.data, {
              appearance: 'error',
              autoDismiss: true,
            })
            :
            addToast('Oops ! Something went wrong.', {
              appearance: 'error',
              autoDismiss: true,
            })
        })
    }
  }

  // ***********************Google Login******************************
  // const handleCallbackResponse = (res) => {
  //   console.log('Encoded JWT ID Token: ' + res.credential)
  //   handleSuccess(res.credential)
  // }
  // useEffect(() => {
  //   /* gloabl google */
  //   window.google.accounts.id.initialize({
  //     client_id: CLIENT_ID, callback: handleCallbackResponse
  //   })
  //   window.google.accounts.id.renderButton(
  //     document.getElementById("signInDiv"),
  //     { theme: 'filled_black', size: 'large', width: '350px', logo_alignment: 'center' }
  //   );
  // }, [])


  // const handleError = (err) => {
  //   addToast('Error !! Something went wrong...', {
  //     appearance: 'error',
  //     autoDismiss: true,
  //   })
  // }

  // const handleSuccess = (data) => {
  //   setIsLoading(true)
  //   googleLogin({ auth_token: data })
  //     .then(res => {
  //       setIsLoading(false)
  //       addToast('Success !! Login...', {
  //         appearance: 'success',
  //         autoDismiss: true,
  //       })
  //       localStorage.setItem('officiel-user', JSON.stringify(res.data))
  //       document.location.href = "/";
  //     })
  //     .catch(err => {
  //       console.log(err);
  //       setIsLoading(false)
  //       addToast('Error !! Something went wrong...', {
  //         appearance: 'error',
  //         autoDismiss: true,
  //       })
  //     })
  // }


  return (<>

    <div className="login_block">
      <div className="login_left_block main_mobile_hide">
        <img src={loginBanner} style={{ height: '100vh', width: '100%', objectFit: 'cover', objectPosition: 'right' }} alt="" />
      </div>
      <div className="login_right_block">
        <div className="login_wrapper ">
          <h2 className=''><img src={logo} className='logo' alt="" /></h2>
          <p className='login_desc mt-3'>Nice to see you again!</p>

          <form className="input_login_wrapper">
            <label htmlFor="" className="login_label">Login</label>
            <input value={payload.email} onChange={(e) => setPayload({ ...payload, email: e.target.value })} type="text" placeholder='Email or phone number' className="login_input" />
            {error.email.length > 0 && <div className="text-danger text-start" style={{ fontSize: '12px' }}>{error.email}</div>}

            <label htmlFor="" className="login_label">Password</label>
            <input value={payload.password} onChange={(e) => setPayload({ ...payload, password: e.target.value })} type={showPassword ? "text" : "password"} placeholder='Enter password' className="login_input" />
            <div style={{ position: 'relative' }}><Icon icon="ooui:eye" color={showPassword ? "#029AFF" : "#444"} onClick={() => setShowPassword(!showPassword)} className='pass_eye' /></div>

            {error.password.length > 0 && <div className="text-danger text-start" style={{ fontSize: '12px' }}>{error.password}</div>}

            <div className="myrow justify_space_between align_center">
              <div className='form-switch d-flex align_center'>
                <input value={rememberCheck} onChange={(e) => setRememberCheck(e.target.checked)} className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                <label className="login_label " type={'button'} htmlFor="flexSwitchCheckDefault">&nbsp; Remember me</label>
              </div>
              <Link to={Path.FORGOTPASSWORD} type={'button'} className='login_label color_blue'>Forgot password?</Link>
            </div>

            <div className="my_button bg_blue color_white mt-2" onClick={handleLogin}> {isLoading ?
              <>
                <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                Please wait...
              </>
              :
              "Sign in"} </div>
            <hr className='my-3 mt-4' />

            <div className="" style={{ backgroundColor: 'rgb(32, 33, 36)', borderRadius: '4px',paddingBottom:'5px' }}>
              <GoogleLoginComponent />
            </div>
            

            <div className="text-center mt-3">
              <div className="login_label">Don't have an account? &nbsp; <label type={'button'} className="color_blue"><Link to={Path.REGISTER}>Sign up now</Link></label></div>
            </div>
          </form>

        </div>
      </div>
    </div>

    {isLoading && <Loading />}
  </>)
}

export default Login