import React, { useEffect, useState } from 'react';
import './Main.css'
import { useParams } from 'react-router-dom';
import JoinePage from './JoinPage/JoinePage';
import Meeting from './Meeting/Meeting';
import { useMeetingStore } from '../../Store/MeetingStore/MeetingStore';
import Loading from './../../Loading';
import NoMeeting from './Meeting/NoMeeting';
import { useToasts } from 'react-toast-notifications';

const Main = () => {
    const { addToast } = useToasts()
    const { unique_code } = useParams()
    const { fetchMeetingDetails, setMeetingDetails, current_meeting } = useMeetingStore()
    const [loading, setLoading] = useState(true)
    const [noMeeting, setNoMeeting] = useState(true)

    useEffect(() => {
        setLoading(true)
        fetchMeetingDetails(unique_code)
            .then(res => {
                setMeetingDetails(res.data)
                setLoading(false)
                setNoMeeting(false)
            })
            .catch(err => {
                setLoading(false)
                setNoMeeting(true)
                addToast('No meeting room available with this code...!', {
                    appearance: 'error',
                    autoDismiss: true,
                })
            })
    }, [unique_code])

    if (loading) {
        return <Loading />
    } else if (noMeeting) {
        return (
            <NoMeeting />
        )
    } else {
        return <JoinePage meetingDetails={current_meeting} />
    }
}

export default Main