import { create } from 'zustand';
import axios from 'axios';
import { socketUrl } from '../../Export';
import { MsgType } from '../../Export';

export const  useSocketStore = create((set) => ({
    state: null, newUserJoined: null, newMsg: null, exist_participant:[],remove_participant:null ,oldMsg: [], videotoggle: null, audiotoggle: null, socketClosed: false,endMeeting:false,
    forcedRemoveUser:null,forcedMuteUser:null,
    connectSocket: (room, guser,feedId) => {
        let id = JSON.parse(localStorage.getItem('officiel-user'))
        var ws = new WebSocket(`${socketUrl}${room}/${guser}/${feedId}/?token=${id ? id.token : ''}`);
        // Websocket onopen event listener
        ws.onopen = () => {
            console.log("connected websocket main component");
            set({ state: ws });
        };

        // websocket onclose event listener
        ws.onclose = e => {
            console.log(
                `Socket is closed. Reconnect will be attempted in ${Math.min(
                    10000 / 1000,

                )} second.`,
                e.reason
            );
            set({ state: ws, socketClosed: true });
        };

        ws.onmessage = e => {
            if (e) {
                let data = JSON.parse(e.data)
                if (data.type === MsgType.NEWUSER) {
                    set({ newUserJoined: data });
                } 
                else if (data.type === MsgType.EXISTINGPARTICIPANT) {
                    set({ exist_participant: data.data });
                }
                else if (data.type === MsgType.REMOVEUSER) {
                    set({ remove_participant: data });
                }
                else if (data.type === MsgType.RECEIVEMSG) {
                    set({ newMsg: data.data });
                }
                else if (data.type === MsgType.CHATHISTORY) {
                    set({ oldMsg: data.data });
                }
                else if (data.type === MsgType.VIDEOTOGGLE) {
                    set({ videotoggle: data.data });
                }
                else if (data.type === MsgType.AUDIOTOGGLE) {
                    set({ audiotoggle: data.data });
                }
                else if (data.type === MsgType.FORCEDREMOVEUSER) {
                    set({ forcedRemoveUser: data.data });
                }
                else if (data.type === MsgType.FORCEDMUTEUSER) {
                    set({ forcedMuteUser: data.data });
                }
                else if (data.type === MsgType.ENDMEETINGFORALL) {
                    console.log(data);
                    set({ endMeeting: data.data });
                }
            }

        }

        // websocket onerror event listener
        ws.onerror = err => {
            console.error(
                "Socket encountered error: ",
                err.message,
                "Closing socket"
            );
            ws.close();
        };
    },
    clearNewUserJoined: () => {
        set({ newUserJoined: null })
    },
    clearVideoToggle: () => {
        set({ videotoggle: null })
    },
    clearAudioToggle: () => {
        set({ audiotoggle: null })
    },
    clearForcedRemoveUser: () => {
        set({ forcedRemoveUser: null })
    },
    clearForcedMuteUser: () => {
        set({ forcedMuteUser: null })
    },
    clearExistParticipant: () => {
        set({ exist_participant: [] })
    },
}));